import React, { useState } from "react";
import MoveAddressActionSection from "./MoveAddressActionSection";
import SelectSourceMandant from "./steps/SelectSourceMandant";
import SelectTargetMandant from "../../utils/SelectTargetMandant";
import SelectAddress from "./steps/SelectAddress";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingSpinner from "../../utils/LoadingSpinner";

function MasterMoveAddress(props) {
  const [loadedMandants, setLoadedMandants] = useState([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [sourceSelectedMandant, setSourceSelectedMandant] = useState(null);
  const [targetSelectedMandants, setTargetSelectedMandants] = useState(null);
  const [loadedAddresses, setLoadedAddresses] = useState([]);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("");
  const [loadedMandantsWithoutSource, setLoadedMandantsWithoutSource] = useState([]);

  function displaySnackbar(snackbarType, snackbarMessage) {
    setSnackbarType(snackbarType);
    setSnackbarMessage(snackbarMessage);
    setShowSnackbar(true);
  }
  return (
    <>
      {loading && <LoadingSpinner />}
      <LinearProgress variant="determinate" value={(step * 100) / 3} />
      {step === 1 ? (
        <SelectSourceMandant
          loadedMandants={loadedMandants}
          setLoadedMandants={setLoadedMandants}
          setLoadedMandantsWithoutSource={setLoadedMandantsWithoutSource}
          sourceSelectedMandant={sourceSelectedMandant}
          setSourceSelectedMandant={setSourceSelectedMandant}
          props={props}
        />
      ) : (
        " "
      )}
      {step === 2 ? (
        <SelectAddress
          loadedAddresses={loadedAddresses}
          setLoadedAddresses={setLoadedAddresses}
          selectedAddresses={selectedAddresses}
          setSelectedAddresses={setSelectedAddresses}
          sourceMandant={sourceSelectedMandant}
          props={props}
        />
      ) : (
        " "
      )}
      {step === 3 ? (
        <SelectTargetMandant
          loadedMandantsWithoutSource={loadedMandantsWithoutSource}
          setLoadedMandantsWithoutSource={setLoadedMandantsWithoutSource}
          sourceSelectedMandant={sourceSelectedMandant}
          targetSelectedMandants={targetSelectedMandants}
          setTargetSelectedMandants={setTargetSelectedMandants}
          multiselection="single"
        />
      ) : (
        " "
      )}
      <MoveAddressActionSection
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        selectedAddresses={selectedAddresses}
        setSelectedAddresses={setSelectedAddresses}
        loadedMandants={loadedMandants}
        setLoadedAddresses={setLoadedAddresses}
        setLoadedMandants={setLoadedMandants}
        sourceSelectedMandant={sourceSelectedMandant}
        setSourceSelectedMandant={setSourceSelectedMandant}
        targetSelectedMandants={targetSelectedMandants}
        setTargetSelectedMandants={setTargetSelectedMandants}
        snackbarType={snackbarType}
        setSnackbarType={setSnackbarType}
        snackbarMessage={snackbarMessage}
        setSnackbarMessage={setSnackbarMessage}
        setShowSnackbar={setShowSnackbar}
        showSnackbar={showSnackbar}
        displaySnackbar={displaySnackbar}
        setLoading={setLoading}
        loading={loading}
        step={step}
        setStep={setStep}
        props={props}
      />
    </>
  );
}
export default MasterMoveAddress;
