import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { ArrowBackRounded, ArrowUpward } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ActionBar from '../utils/ActionsBar';
import { SecondaryButton } from '../utils/CFButtons';
import { navigateBack } from '../utils/common';
import { postDataToSmartDesignResponse } from "../utils/api-client";
import { Typography } from '@material-ui/core'

const VerifyProfilesJson = (props) => {
  const [jsonData, setJsonData] = useState("");
  const [responseText, setResponseText] = useState("");

  
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("Please select a file.");
      return;
    }
    if (!file.name.endsWith('.json')) {
      console.error("Please select a JSON file.");
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      const fileContent = e.target.result;
      const sanitizedJSON = escapeSpecialCharacters(fileContent);
      setJsonData(sanitizedJSON);
    };
  };
  const escapeSpecialCharacters = (jsonString) => {
    return jsonString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/'/g, "&#x27;")
      .replace(/\//g, "&#x2F;");
  };
  const handleDataUpload = async () => {
    const response = await postDataToSmartDesignResponse("validate/profiles", {
      jsonDataVar: jsonData
    });
    let responseBody = undefined;
    responseBody = await response.text();
    if (response.status == 200) {
      dislpayText(responseBody, 'green');
    } else if (response.status == 226) {
      dislpayText(responseBody, 'orange');
    } else if (response.status == 410) {
      dislpayText(responseBody, 'red');
    } else {
      dislpayText(responseBody, 'black');
    }
  };

  const clearData = () => {
    setJsonData("");
    const fileInput = document.getElementById("json-file");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const clearDisplayedData = () => {
    setResponseText("");
  };
  
  const dislpayText = (textVar, color) => {
    const responseText = textVar;
    setResponseText("");
    const lines = responseText.split(/\r?\n/);
    const formattedText = lines.map((line, index) => (
      <span key={index} style={{ margin: 0, padding: 0 }}>
        {line}
        {index !== lines.length - 1 && <br />}
      </span>
    ));

    setResponseText({
      text: formattedText,
      color: color,
      fontSize: '1.2rem',
    });
  }
  return (
    <>
      <div>
        <Typography
          variant="body1"
          style={{
            marginLeft: 20,
            marginTop: 10,
            color: 'black',
            fontSize: '1.2rem',
          }}
        >
          Make sure that Licences or any other array are listed as<br /> Licence: [1,2,3]<br />and not like<br /> Licence: [<br />
          
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2,<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
        </Typography>

        <input
          accept=".json"
          id="json-file"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <label htmlFor="json-file">
          <Button style={{ marginLeft: 20, marginTop: 10 }} component="span">
            <ArrowUpward />
            Select a JSON file
          </Button>
        </label>
        {jsonData && (
          <Button style={{ marginLeft: 20, marginTop: 10 }} onClick={handleDataUpload}>
            <ArrowUpward />
            Upload Data
          </Button>
        )}
        {jsonData && (
          <Button style={{ marginLeft: 20, marginTop: 10 }} onClick={clearData}>
            <ArrowUpward />
            Clear Uploaded Data (in browser cache)
          </Button>
        )}
        {responseText && (
          <Button style={{ marginLeft: 20, marginTop: 10 }} onClick={clearDisplayedData}>
            <ArrowUpward />
            Clear Displayed Data
          </Button>
        )}
      </div>

      {responseText && (
        <Typography
          variant="body1"
          style={{
            marginLeft: 20,
            marginTop: 10,
            color: responseText.color,
            fontSize: responseText.fontSize
          }}
        >
          {responseText.text}
        </Typography>
      )}

      <ActionBar>
        <SecondaryButton
          style={{ marginLeft: 20, marginTop: 10 }}
          component={Link}
          to={navigateBack(navigateBack(props.location.pathname))}
          onClick={clearData}
        >
          <ArrowBackRounded />
          Back
        </SecondaryButton>
      </ActionBar>
    </>
  );
};

export default VerifyProfilesJson;