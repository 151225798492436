import { getDataFromSmartDesign } from "../../utils/api-client";
async function GetQueryLists(setStatisticsQueryLists) {
    return Promise.all(
        (async () => {
            var result = [];
            const queries = await getDataFromSmartDesign("/type/statisticsquerylist/full");
            queries.forEach(async (statisticsQueryList) => {
                result.push(statisticsQueryList.fields);
            });
            setStatisticsQueryLists(result);
        })()
    );
}
export default GetQueryLists;
