import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import TimeTrackingTableTable from "./TimeTrackingTableTable";
import TimeTrackingTableInput from "./TimeTrackingTableInput";
import moment from "moment";
import { getDataFromSmartDesign, postDataToSmartDesign } from "../utils/api-client";
import * as Yup from "yup";
import { Formik } from "formik";
import LoadingSpinner from "../utils/LoadingSpinner";
import { Card } from '@material-ui/core';
import { Button as CFButton } from '../utils/CFButtons';

function TimeTrackingTableMainView(props) {
  const [mandant, setMandant] = useState("");
  const [loadedTimeTrackings, setLoadedTimeTrackings] = useState([]);
  const [selectedDraftIdx, setSelectedDraftIdx] = useState([]);
  const [users, setUsersValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupsJSON, setGroupsJSON] = useState([]);
  const [user, setUser] = useState("");

  const [timeAlreadyWorked, setTimeAlreadyWorked] = useState("");
  const [timeToBePerformed, setTimeToBePerformed] = useState("");
  const [balance, setBalance] = useState("");
  const [printTable, setPrintTable] = useState("");

  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDateTT") || new Date()
  );
  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDateTT") || new Date()
  );
  const [formikInitialState] = useState({
    startDate: startDate,
    endDate: endDate,
    user: user,
  });

  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') 
      window.location.reload();
});

  moment.locale("de");
   useEffect(() => {
        (async () => {
            try {
                const response = await getDataFromSmartDesign("/admin/licences/get");
                setMandant(response.mandant);
            } catch (error) {
                alert("something went wrong, please contact to the CareCM 2.0 team");
                return;
            }
        })();
    }, [mandant, setMandant]);

    const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  

  useEffect(() => {
    (async () => {

    })();
  }, [user, groupsJSON, props.location, users]);

  async function handlePrint(){
    var currentDateVar = moment(new Date()).format("DD_MM_YYYY");

    try{
      var filename = 'Übersicht_Zeiterfassung_' + currentDateVar + '_' + user + '.pdf'
      await postDataToSmartDesign("/print/timetrackingtable", {
          objects: printTable,
          user: user,
          currentDate: currentDateVar,
       }).then(response => {
         var url = undefined
         var tablePDFLink = undefined
         var firefoxAgent = navigator.userAgent.indexOf('Firefox') > -1
         if (firefoxAgent) {
           var tableXLSX = new File([response], filename, {
             type: 'application/pdf',
           })
           url = window.URL.createObjectURL(tableXLSX)
           tablePDFLink = document.createElement('a')
           tablePDFLink.href = url
           window.open(tablePDFLink)
         } else {
           url = window.URL.createObjectURL(response)
           tablePDFLink = document.createElement('a')
           document.body.appendChild(tablePDFLink)
           tablePDFLink.href = url
           tablePDFLink.download = filename
           tablePDFLink.click()
         }
       });
    } catch (error){
      console.error("Error while fetching print time tracking table");
    }
  }
  const timeTrackingTableSchema = Yup.object().shape({
    startDate: Yup.date().required("Dies ist ein Pflichtfeld").nullable(),
    endDate: Yup.date()
      .required("Dies ist ein Pflichtfeld")
      .nullable()
      .min(
        Yup.ref("startDate"),
        "Das Enddatum sollte größer als das Startdatum sein"
      ),
    user: Yup.string().nullable().required("Dies ist ein Pflichtfeld"),
  });
  return (
    <div>
      {loading && <LoadingSpinner />}
      <Formik
        validationSchema={timeTrackingTableSchema}
        initialValues={formikInitialState}
        enableReinitialize
        onSubmit={(values, actions) => {
          setTimeAlreadyWorked("00:00");
          setTimeToBePerformed("00:00");
          setBalance("00:00");
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <>
            <Helmet title={"Zeiterfassungstabelle"} />
            <TimeTrackingTableInput
              users={users}
              keepUser={ (
                props.location !== undefined &&
                props.location.state !== undefined) ? props.location.state.keepUser : false }
              values={values}
              setFieldValue={setFieldValue}
              setUser={setUser}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              loading={loading}
              mandant={mandant}
              user={user}
              setBalance={setBalance}
              setTimeAlreadyWorked={setTimeAlreadyWorked}
              timeAlreadyWorked={timeAlreadyWorked}
              setTimeToBePerformed={setTimeToBePerformed}
              timeToBePerformed={timeToBePerformed}
              setLoadedTimeTrackings={setLoadedTimeTrackings}
              setPrintTable={setPrintTable}
              keepCheckbox={(
                props.location !== undefined &&
                props.location.state !== undefined) ? props.location.state.keepCheckbox : true}
            />           
            <TimeTrackingTableTable
              loadedTimeTrackings={loadedTimeTrackings}
              setSelectedDraftIdx={setSelectedDraftIdx}
              setShowDiscardPopup={setShowDiscardPopup}
              loading={loading}
            />
          {!loading && (  
          <div>            
            <div id='container' className='divButton'>
              <Card style={{ padding: 15 }}>
                <CFButton
                  variant='contained'
                  style={{ marginTop: 5, minHeight: 55 }}
                  onClick={handlePrint}
                >
                  Drucken
                </CFButton>
              </Card>
            </div>                     
            <div>
              <p style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "30px" }}>
              Saldo für den ausgewählten Zeitraum:
              </p>
              <div>
                <table width="100%">
                  <tr>
                    <td align="justify">Bereits gearbeitete Zeit: {timeAlreadyWorked}</td>
                    <td align="justify">Soll-Arbeitszeit in gewähltem Zeitraum: {timeToBePerformed}</td>
                    <td align="justify">Zeitsaldo: {balance}</td>
                  </tr>
                </table>
              </div>

            </div>
          </div>
          )}  
          </>
        )}
      </Formik>
    </div>
  );
}
export default TimeTrackingTableMainView;
