import React, { Component } from "react";
import { Card, Typography } from "@material-ui/core";
import ICDCodeWidget from "../../widgets/ICD10/ICDCodeWidget";

class ICD10View extends Component {
    render() {
        return (
            <Card style={{ padding: 25 }}>
                <Typography component="div" variant="h5">
                    Diagnosen
                </Typography>
                <div style={{ marginTop: '20px'}}>
                    <ICDCodeWidget
                        name="mainDiagnoses"
                        label="Hauptdiagnose"
                    />
                </div>
                <div style={{ marginTop: '40px'}}>
                    <ICDCodeWidget
                        name="secondaryDiagnoses"
                        label="Nebendiagnosen"
                    />
                </div>
            </Card>
        );
    }
}
export default ICD10View;
