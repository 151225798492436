import React, {useEffect} from "react";
import ViewTable from "../../../utils/ViewTable";
import FetchAddressOfMandant from "../FetchAddressOfMandant";

function SelectAddress({
  loadedAddresses,
  setLoadedAddresses,
  selectedAddresses,
  setSelectedAddresses,
  sourceMandant,
  props
}) {
  useEffect(() => {
    (async () => {
      try {
        if(      
          loadedAddresses.length === 0 
          ){
            setLoadedAddresses(
              await FetchAddressOfMandant(sourceMandant)
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    })();
  }, [loadedAddresses.length,setLoadedAddresses,sourceMandant, props.location]);
  const optionsAddress = {
    selectableRows: "multiple",
    search: true,
    textLabels: {
      body: {
        noMatch: "Leider wurden keine passenden Datensätze gefunden",
        toolTip: "Sortieren",
      },
      pagination: {
        rowsPerPage: "Einträge pro Seite",
        displayRows: "von",
      },
      selectedRows: {
        text: "Addresses markiert",
      },
    },

    onRowSelectionChange:  (currentRowsSelected,allRowsSelected) => {
      let auxAddress=[];
      try{
        if(allRowsSelected.length>0){ 
          setSelectedAddresses([]);            
          allRowsSelected.forEach(element => {
            auxAddress.push(loadedAddresses[element.dataIndex]);
          });
        }else{
          setSelectedAddresses([]);
        }
      } catch(error){
        console.log(error);
        setSelectedAddresses([]);
      }
      setSelectedAddresses(auxAddress);
    },
  }
  const columnsAddress = [ 
    {   
      name: 'NAME',
      selector: "Name",
      sortable: true,
    },
    {
      name: 'CHRISTIANNAME',
      selector: "Surname",
      sortable: true,
    },
    {
      name: 'BIRTHDAY',
      selector: "Birthday",
      sortable: true,
    },
    {
      name: 'CLIENTNUMBER',
      selector: "Client number",
      sortable: true,
    },
  ]
  return (
    <>
      <h3>Step 2: select an address</h3>
     <ViewTable
          title={"Addresses"}
          data={loadedAddresses}
          columns={columnsAddress}
          options={optionsAddress}
        />     
    </>
  );
}
export default SelectAddress;