import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import React from 'react'
const tableTheme = () =>
  createTheme({
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: 'transparent',
        },
      },
      MUIDataTableToolbarSelect: {
        root: {
          backgroundColor: 'transparent',
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: 'transparent',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent',
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: `0px solid transparent`,
        },
      },
      MUIDataTable: {
        responsiveScrollFullHeight: {
          overflowX: 'auto',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: '#DC001A',
          },
        },
      },
    },
  })
const options = {
  elevation: 1,
  filterType: 'dropdown',
  responsive: 'simple',
  displayRowCheckbox: false,
  filter: false,
  print: false,
  download: false,
  resizableColumns: false,
  viewColumns: false,
  selectableRows: 'none',
  search: false,
  disabled: false,
  textLabels: {
    body: {
      noMatch: 'Leider wurden keine passenden Datensätze gefunden',
      toolTip: 'Sortieren',
    },
    pagination: {
      rowsPerPage: 'Einträge pro Seite',
      displayRows: 'von',
    },
  },
  tableLayout: { backgroundColor: 'green' },
}

export default function ViewTable(props) {
  return (
    <div>
      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable
          {...props}
          options={
            props.options && typeof props.options === 'object'
              ? { ...options, ...props.options }
              : options
          }
        />
      </MuiThemeProvider>
    </div>
  )
}
