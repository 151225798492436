import React, { useEffect } from "react";
import ViewTable from "./ViewTable";
import FetchAllMandant from "./FetchAllMandant";

function SelectTargetMandant({
  loadedMandantsWithoutSource,
  targetSelectedMandants,
  setTargetSelectedMandants,
  setLoadedMandantsWithoutSource,
  multiselection,
  props,
}) {
  useEffect(() => {
    (async () => {
      try {
        if (loadedMandantsWithoutSource.length === 0) {
          setLoadedMandantsWithoutSource(await FetchAllMandant());
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    })();
  }, [
    loadedMandantsWithoutSource.length,
    setLoadedMandantsWithoutSource,
  ]);
  const optionsMandant = {
    selectableRows: multiselection,
    search: true,
    textLabels: {
      body: {
        noMatch: "Leider wurden keine passenden Datensätze gefunden",
        toolTip: "Sortieren",
      },
      pagination: {
        rowsPerPage: "Einträge pro Seite",
        displayRows: "von",
      },
      selectedRows: {
        text: "Mandants markiert",
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      if (allRowsSelected.length > 0) {
        setTargetSelectedMandants(
          allRowsSelected.map(i => loadedMandantsWithoutSource[i.dataIndex].clientName)
        );
      } else {
        setTargetSelectedMandants(null);
      }
    },
  };
  const columnsMandant = [
    {
      name: "clientName",
      selector: "clientName",
      sortable: true,
    },
  ];
  return (
    <>
      <h3>Step 3: select the target mandant/s</h3>
      <ViewTable
        title={"Mandants"}
        data={loadedMandantsWithoutSource}
        columns={columnsMandant}
        options={optionsMandant}
      />
    </>
  );
}
export default SelectTargetMandant;
