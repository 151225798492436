const missingCoreProps = [
  {
    key: 'smtp.user',
    value: 'smtp.user',
  },
  {
    key: 'smtp.password',
    value: 'smtp.password',
  },
  {
    key: 'smtp.host',
    value: 'smtp.host',
  },
  {
    key: 'smtp.port',
    value: 'smtp.port',
  },
  {
    key: 'smtp.useAuthentication',
    value: 'smtp.useAuthentication',
  },
  {
    key: 'smtp.useSSL',
    value: 'smtp.useSSL',
  },
  {
    key: 'smtp.useTLS',
    value: 'smtp.useTLS',
  },
  {
    key: 'hoster.email.senderMailAddress',
    value: 'hoster.email.senderMailAddress',
  },
  {
    key: 'hoster.email.replyTo',
    value: 'hoster.email.replyTo',
  },
  {
    key: 'user.group',
    value: 'user.group',
  },
  {
    key: 'user.group.guid',
    value: 'user.group.guid',
  },
  {
    key: 'user.group.displayName',
    value: 'user.group.displayName',
  },
  {
    key: 'server.url',
    value: 'server.url',
  },
  {
    key: 'admin.user',
    value: 'admin.user',
  },
  {
    key: 'admin.pwd',
    value: 'admin.pwd',
  },
  {
    key: 'admin.productKey',
    value: 'admin.productKey',
  },
  {
    key: 'global.user',
    value: 'global.user',
  },
  {
    key: 'global.pwd',
    value: 'global.pwd',
  },
  {
    key: 'encode_password',
    value: 'encode_password',
  },
  {
    key: 'statistics.threads',
    value: 'statistics.threads',
  },
  {
    key: 'statistics.check.email',
    value: 'statistics.check.email',
  },
]

export default missingCoreProps
