import { Card, IconButton, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Cached from "@material-ui/icons/Cached";
import Send from "@material-ui/icons/Send";
import moment from "moment";
import React, { Component } from "react";
import "../../caseform/broadcast/broadcastButton.css";
import { getDataFromSmartDesign, postDataToSmartDesign } from "../../utils/api-client";
import { CaseformButton } from "../../utils/CFButtons";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import Table from "../../utils/Table";
import AddressBroadcastOptionList from "./AddressBroadcastOptionList";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

const OK_MESSAGE = "Rundruf wurde erfolgreich erstellt.";
const ERROR_MESSAGE = "Rundruf konnte nicht erstellt werden.";
const OK_MESSAGE_BROADCASTTABLE = "Rundrufe wurden erfolgreich aktualisiert.";
const ALREADY_UPDATED_BROADCASTTABLE =
    "Es liegen keine neuen Informationen zu Ihren Caseform Rundrufen vor";
const ERROR_MESSAGE_BROADCASTTABLE = "Rundrufe konnten nicht aktualisiert werden.";
const TIMESTAMP_FORMAT = "DD.MM.YYYY HH:mm";
const TIMESTAMP_FORMAT_SOURCE = "yyyy-MM-dd'T'HH:mm:ss";
const PLANNEDSTARTDATE_FORMAT = "DD.MM.YYYY";
const defaultAPIUrl = window.config.REACT_APP_SERVER_URL_REST;

class AddressBroadcastView extends Component {
    constructor() {
        super();

        this.state = {
            providedICDInput: "",
            snackbarMessage: "",
            snackbarType: "",
            showSnackbar: false,
            open: false,
            isLoading: false,
            hasCreds: false,
            data: [],
            options: {
                search: true,
                textLabels: {
                    body: {
                        noMatch: "Leider wurden keine passenden Datensätze gefunden",
                    },
                    pagination: {
                        rowsPerPage: "Einträge pro Seite",
                        displayRows: "von",
                    },
                },
                sortOrder: {
                    name: "insertimestamp",
                    direction: "desc",
                },
                customSearch: (searchQuery, currentRow, columns) => {
                    let isFound = false;
                    if (currentRow[2].toString().indexOf(searchQuery.toUpperCase()) >= 0)
                        isFound = true;
                    return isFound;
                },
                onRowClick: (value) => {
                    const selected = this.state.data.filter(
                        ({ humanReadableId }) => value[3] === humanReadableId
                    );
                    window.open(selected[0]["externalLink"]);
                },
            },
            columns: [
                {
                    name: "keyword",
                    label: "Stichwort",
                },
                {
                    name: "plannedStartDate",
                    label: "Geplanter Start Nachversorgung",
                    options: {
                        customBodyRender: (value) =>
                            moment(value, TIMESTAMP_FORMAT_SOURCE).isValid() ? (
                                <div style={{ width: "50%", textAlign: "right" }}>
                                    {moment(value).format(PLANNEDSTARTDATE_FORMAT)}
                                </div>
                            ) : (
                                <Typography style={{ width: "50%", textAlign: "center" }}>
                                    {value}
                                </Typography>
                            ),
                    },
                },
                {
                    name: "broadcastStatus",
                    label: "Caseform Status",
                },
                {
                    name: "humanReadableId",
                    label: "Rundruf ID",
                },
                {
                    name: "insertimestamp",
                    label: "Erzeugt am",
                    options: {
                        customBodyRender: (value) =>
                            moment(value, TIMESTAMP_FORMAT_SOURCE).isValid() ? (
                                moment(value).format(TIMESTAMP_FORMAT)
                            ) : (
                                <Typography style={{ width: "50%", textAlign: "center" }}>
                                    {value}
                                </Typography>
                            ),
                    },
                },
                {
                    name: "responseTime",
                    label: "Antwortfrist",
                    options: {
                        customBodyRender: (value) =>
                            moment(value, TIMESTAMP_FORMAT_SOURCE).isValid() ? (
                                moment(value).format(TIMESTAMP_FORMAT)
                            ) : (
                                <Typography style={{ width: "50%", textAlign: "center" }}>
                                    {value}
                                </Typography>
                            ),
                    },
                },
                {
                    name: "changedOn",
                    label: "Geändert am",
                    options: {
                        customBodyRender: (value) =>
                            moment(value, TIMESTAMP_FORMAT_SOURCE).isValid() ? (
                                moment(value).format(TIMESTAMP_FORMAT)
                            ) : (
                                <Typography style={{ width: "50%", margin: "auto" }}>
                                    {value}
                                </Typography>
                            ),
                    },
                },
                {
                    name: "gguid",
                    label: "Rundruf öffnen",
                    options: {
                        customBodyRender: (value) => {
                            return (
                                <div style={{ width: "50%", margin: "auto" }}>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(
                                                defaultAPIUrl + "CareCM/#!type/document/0x" + value
                                            );
                                        }}
                                    >
                                        <InsertDriveFileIcon />
                                    </IconButton>
                                </div>
                            );
                        },
                    },
                },
            ],
        };
    }

    async refreshBroadcasts() {
        const url_string = window.location.href;
        const url = new URL(url_string);
        this.setState({ data: [] });
        try {
            const gguid = url.searchParams.get("GGUID");
            const endpointUrl = "/caseform/getBroadcasts?gguid=" + gguid;
            const response = await getDataFromSmartDesign(endpointUrl);
            if (response.status === 200) {
                this.setState({ data: [...response.listBroadcastDTO], hasCreds: true });
            }
            if (response.status === 401) {
                let object = { ...this.state };
                object.options.textLabels.body.noMatch =
                    "Ihre Organisation ist in Caseform nicht freigeschaltet. Bitte wenden Sie sich an kundenservice@nubedian.de, um diese Funktion zu nutzen";
                object.hasCreds = false;
                this.setState(object);
            }
        } catch (e) {}
    }
    componentDidMount() {
        this.refreshBroadcasts();
    }
    onSubmit = () => {
        this.setState({ open: true });
    };

    displaySnackbar = (snackbarType, snackbarMessage) => {
        this.setState({
            snackbarType: snackbarType,
            snackbarMessage: snackbarMessage,
            showSnackbar: true,
        });
    };

    handleClose = (selectedBroadcastOption) => {
        this.setState({ open: false });

        if (selectedBroadcastOption) {
            const url_string = window.location.href;
            const url = new URL(url_string);

            (async () => {
                try {
                    const gguid = url.searchParams.get("GGUID");
                    const endpointUrl =
                        "/caseform/postBroadcast?gguid=" +
                        gguid +
                        "&selectedBroadcastOption=" +
                        selectedBroadcastOption;
                    const response = await postDataToSmartDesign(endpointUrl);
                    if (response.status !== 200) {
                        this.displaySnackbar("success", OK_MESSAGE);
                    }
                    this.refreshBroadcasts();
                } catch (e) {
                    this.displaySnackbar("error", ERROR_MESSAGE);
                }
            })();
        }
    };

    onUpdateCaseformBroadcastsSubmit = () => {
        const url_string = window.location.href;
        const url = new URL(url_string);

        (async () => {
            try {
                const gguid = url.searchParams.get("GGUID");
                const endpointUrl = "/caseform/updateBroadcasts?gguid=" + gguid;
                this.setState({ isLoading: true });
                const status = await new Response(await postDataToSmartDesign(endpointUrl)).text();
                if (status === "200") {
                    this.displaySnackbar("success", OK_MESSAGE_BROADCASTTABLE);
                }
                if (status === "208") {
                    console.log("entering");
                    this.displaySnackbar("info", ALREADY_UPDATED_BROADCASTTABLE);
                }
            } catch (e) {
                this.displaySnackbar("error", ERROR_MESSAGE_BROADCASTTABLE);
            }
            this.refreshBroadcasts();
            this.setState({ isLoading: false });
        })();
    };

    render() {
        return (
            <>
                <div id="container" className="divButton">
                    <Card style={{ padding: 25 }}>
                        <Typography
                            component="div"
                            variant="h5"
                            style={{ marginBottom: 25, marginTop: 10 }}
                        >
                            Caseform Rundrufe - passende Nachversorger finden
                        </Typography>
                        <Table
                            columns={this.state.columns}
                            options={this.state.options}
                            data={this.state.data}
                            isLoading={this.state.isLoading}
                        />
                        {!this.state.open && (
                            <div style={{ display: "flex" }}>
                                <CaseformButton
                                    variant="contained"
                                    disabled={!this.state.hasCreds}
                                    style={{ marginTop: 30, marginRight: 20 }}
                                    onClick={this.onUpdateCaseformBroadcastsSubmit}
                                >
                                    <Cached />
                                    Status der Rundrufe aktualisieren
                                </CaseformButton>
                                <CaseformButton
                                    variant="contained"
                                    style={{ marginTop: 30, marginLeft: "auto" }}
                                    onClick={this.onSubmit}
                                    disabled={!this.state.hasCreds}
                                >
                                    <Send />
                                    Einen neuen Rundruf in Caseform erstellen
                                </CaseformButton>
                            </div>
                        )}
                        {this.state.open && (
                            <div>
                                <AddressBroadcastOptionList onClose={this.handleClose} />
                            </div>
                        )}
                    </Card>

                    <Snackbar
                        open={this.state.showSnackbar}
                        autoHideDuration={10000}
                        onClose={() => this.setState({ showSnackbar: false })}
                    >
                        <CFSnackbarContent
                            onClose={() => this.setState({ showSnackbar: false })}
                            variant={this.state.snackbarType}
                            message={this.state.snackbarMessage}
                        />
                    </Snackbar>
                </div>
            </>
        );
    }
}

export default AddressBroadcastView;
