import React, { useState } from "react";
import { deleteObject } from "../../utils/api-client";
import CriteriaListTableComponent from "../criterias/CriteriaListTableComponent";
import DeleteCriteriaListActionsSection from "./DeleteCriteriaListActionSection";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { navigateBack } from "../../utils/common";

function DeleteSingleStatisticQueryList(props) {
    const [loadedCriteriaLists, setLoadedCriteriaLists] = useState([]);
    const [selectedCriteriaLists, setSelectedCriteriaLists] = useState([]);
    const [snackbarType, setSnackbarType] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loadCriteriaLists, setLoadCriteriaLists] = useState(true);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedDeleteCriterias, setSelectedDeleteCriterias] = useState([]);

    function displaySnackbar(snackbarType, snackbarMessage) {
        setSnackbarType(snackbarType);
        setSnackbarMessage(snackbarMessage);
        setShowSnackbar(true);
    }
    function validateAndSend() {
        setSelectedDeleteCriterias(
            selectedCriteriaLists.map((criteria) => loadedCriteriaLists[criteria])
        );
        if (selectedCriteriaLists.length > 0) {
            setShowDeletePopup(true);
        }
    }
    async function deleteCriteriaList() {
        try {
            setLoading(true);
            setShowDeletePopup(false);

            await Promise.all(
                selectedDeleteCriterias.map(async (item): Promise<T> => {
                    await deleteObject("/admin/delete/criteriaList/single?gguid=" + item.GGUID);
                })
            );

            displaySnackbar("success", "Die Kriterienliste wurden erfolgreich gelöscht");
            setLoadCriteriaLists(true);
        } catch (error) {
            displaySnackbar(
                "error",
                "Beim Löschen der Kriterienliste ist ein Problem aufgetreten. Bitte versuchen Sie es erneut."
            );
        } finally {
            setShowDeletePopup(false);
            setLoading(false);
        }
    }
const goBack = pathname => {
  return navigateBack(navigateBack(navigateBack(navigateBack(pathname))))
}
    return (
        <>
            {loading && <LoadingSpinner />}
            <h2>Select the Critera Lists to REMOVE</h2>
            <CriteriaListTableComponent
                props={props}
                setSelectedCriteriaLists={setSelectedCriteriaLists}
                setLoadedCriteriaLists={setLoadedCriteriaLists}
                loadedCriteriaLists={loadedCriteriaLists}
                loadCriteriaLists={loadCriteriaLists}
                setLoadCriteriaLists={setLoadCriteriaLists}
                multiselection={false}
            />

            <DeleteCriteriaListActionsSection
                goBack={goBack}
                showDeletePopup={showDeletePopup}
                setShowDeletePopup={setShowDeletePopup}
                deleteCriteriaList={deleteCriteriaList}
                showSnackbar={showSnackbar}
                setShowSnackbar={setShowSnackbar}
                snackbarType={snackbarType}
                snackbarMessage={snackbarMessage}
                validateAndSend={validateAndSend}
                props={props}
            />
        </>
    );
}
export default DeleteSingleStatisticQueryList;
