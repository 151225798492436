import React, { useState } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import { ArrowBackRounded, ArrowUpward } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ActionBar from '../utils/ActionsBar';
import { SecondaryButton } from '../utils/CFButtons';
import { navigateBack } from '../utils/common';
import { postDataToSmartDesignResponse } from "../utils/api-client";
import Papa from 'papaparse';

const ImportData = (props) => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState('');
  const [responseText, setResponseText] = useState("");



  const dislpayText = (textVar, color) => {
    const responseText = textVar;
    setResponseText("");
    const lines = responseText.split(/\r?\n/);
    const formattedText = lines.map((line, index) => (
      <span key={index} style={{ margin: 0, padding: 0 }}>
        {line}
        {index !== lines.length - 1 && <br />}
      </span>
    ));

    setResponseText({
      text: formattedText,
      color: color,
      fontSize: '1.2rem',
    });
  }

  const importCsv = async (data) => {
    let responseBody = undefined;
    try {
      console.log("Sending request")
      const response = await postDataToSmartDesignResponse("import/organization", {
        csvData: data
      });
      responseBody = await response.text();
      console.log("body =" + responseBody)
      if (response.status == 200) {
        dislpayText(responseBody, 'green');
      } else if (response.status == 400) {
        dislpayText(responseBody, 'orange');
      } else if (response.status == 410) {
        dislpayText(responseBody, 'red');
      } else if (response.status == 500) {
        dislpayText(responseBody, 'black');
      } else {
        dislpayText(responseBody, 'black');
      }
    } catch (e) {
      if (responseBody != null || responseText == undefined) {
        dislpayText(responseBody, 'black');
      } else {
        dislpayText("Error when sending request", 'black');
      }

    }
  };

  const handleFileUpload = (event) => {

    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      const lines = content.split('\n');
      if (lines.length < 2) {
        dislpayText("CSV file has an invalid structure, should have at least two lines, but has " + lines.length, 'red');
        setCsvData('');
        return;
      }

      const expectedHeaders = [
        'Identifikationsnummer',
        'Anbietername',
        'Bereich/Institutionstyp',
        'Straße',
        'PLZ',
        'Ort',
        'Bezirk',
        'Bundesland',
        'Telefon 1',
        'Telefon 2',
        'Mobiltelefon',
        'E-Mail (Öffentlich)',
        'E-Mail (Intern)',
        'Homepage',
        'Fax',
        'Weitere Informationen',
        'Ansprechpartner'
      ];
      var headersMatch = true;
      const parsedData = Papa.parse(content, {
        delimiter: ';',
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        quoteChar: '"',
        transform: (value, header) =>
          header === 'Anbietername' || header === 'Bereich/Institutionstyp'
            ? value.replace(/\r\n/g, ' ')
            : value,
        transformHeader: (header) => {
          const normalizedExpectedHeaders = expectedHeaders.map(expectedHeader => expectedHeader.toLowerCase());
          const headerIndex = normalizedExpectedHeaders.indexOf(header.trim().toLowerCase());
          if (headerIndex===-1) {
            headersMatch=false;
            return header;
          }
          return expectedHeaders[headerIndex];
        }
      });
      if (parsedData.errors.length > 0) {
        console.error('Error parsing CSV:', parsedData.errors);
      } else {
        const headers = parsedData.meta.fields;
        if (!headersMatch) {
          dislpayText("Headers do not match the expected headers or order \r\n Expected headers:\r\n" + expectedHeaders + "\r\n Actual headers: \r\n" + headers)
          return;
        }
        const rows = parsedData.data;
        setCsvData(rows);
      }
    };
    reader.readAsText(file, 'UTF-8');
  };

  const handleDataUpload = async () => {
    if (!csvData) {
      return;
    }

    try {
      setLoading(true);
      await importCsv(csvData);
    } finally {
      setLoading(false);
    }
  };

  const clearData = () => {
    setCsvData("");
    const fileInput = document.getElementById("csv-file");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const clearDisplayedData = () => {
    setResponseText("");
  };

  return (
    <>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress style={{ width: '20rem', height: '20rem' }} />
        </div>
      )}
      <div>
        <Typography
          variant="body1"
          style={{
            marginLeft: 20,
            marginTop: 10,
            color: 'black',
            fontSize: '1.2rem',
            whiteSpace: 'pre-line'
          }}
        >
        </Typography>
        <input
          accept=".csv"
          id="csv-file"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <label htmlFor="csv-file">
          <Button style={{ marginLeft: 20, marginTop: 10 }} component="span">
            <ArrowUpward />
            Select a CSV file
          </Button>
        </label>
        {csvData && (
          <Button style={{ marginLeft: 20, marginTop: 10 }} onClick={handleDataUpload}>
            <ArrowUpward />
            Upload Data
          </Button>
        )}
        {csvData && !loading && (
          <Button style={{ marginLeft: 20, marginTop: 10 }} onClick={clearData}>
            <ArrowUpward />
            Clear Uploaded Data (in browser cache)
          </Button>
        )}
        {responseText && !loading && (
          <Button style={{ marginLeft: 20, marginTop: 10 }} onClick={clearDisplayedData}>
            <ArrowUpward />
            Clear Displayed Data
          </Button>
        )}
      </div>

      {responseText && !loading && (
        <Typography
          variant="body1"
          style={{
            marginLeft: 20,
            marginTop: 10,
            color: responseText.color,
            fontSize: responseText.fontSize
          }}
        >
          {responseText.text}
        </Typography>
      )}

      <ActionBar>
        <SecondaryButton
          style={{ marginLeft: 20, marginTop: 10 }}
          component={Link}
          to={navigateBack(navigateBack(props.location.pathname))}
          onClick={clearData}
        >
          <ArrowBackRounded />
          Back
        </SecondaryButton>
      </ActionBar>
    </>
  );
}

export default ImportData;
