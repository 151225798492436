import { getDataFromSmartDesign } from "../../utils/api-client";

async function FetchAddressOfMandant(sourceMandant) {
  var result = [];
  var queries = await getDataFromSmartDesign("/admin/" + sourceMandant + "/addresses/get");
  queries.clients.forEach(async (element) => {
    result.push(element);
  });
  return result;
}

export default FetchAddressOfMandant;