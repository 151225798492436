import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { checkConnectionTo, getDataFromSmartDesign } from "../../utils/api-client";
import Autocomplete from "../../utils/Autocomplete";
import { CaseformButton, SecondaryCaseformButton } from "../../utils/CFButtons";
import { broadcastOptions } from "./broadcastOptions";
const NO_CONNECTION_TO_CASEFORM_MESSAGE =
    "Es besteht keine Verbindung zu Caseform.\nBitte wenden Sie sich an kundenservice@nubedian.de";

export default function AddressBroadcastOptionList(props) {
    const useStyles = makeStyles((theme) => ({
        textField: {
            width: "100%",
            marginBottom: theme.spacing(0.25),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                width: "55vw",
                marginBottom: theme.spacing(1),
            },
        },
        errorMargin: {
            marginRight: 75,
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
                marginRight: 0,
            },
        },
    }));

    const classes = useStyles();
    const { onClose } = props;
    const [typeOfSupply, setTypeOfSupply] = React.useState("");
    const [options, setOptions] = React.useState();

    useEffect(() => {
        (async () => {
            try {
                const response = await checkConnectionTo("/caseform/check");
                if (response.status === 500) {
                    console.log(response.status);
                    alert(NO_CONNECTION_TO_CASEFORM_MESSAGE);
                    onClose();
                    return;
                }
            } catch (error) {
                onClose();
                return;
            }
            const aux = await getDataFromSmartDesign("/caseform/getBroadcastTypes");
            const availableOps = broadcastOptions.filter((item) => {
                return aux.includes(item.value);
            });
            setOptions(availableOps);
        })();
    }, [onClose]);

    const handleChange = (_, value) => {
        setTypeOfSupply(broadcastOptions.find(({ label }) => label === value)?.value ?? "");
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(typeOfSupply);
    };

    return (
        <>
            <div className="anotherDiv">
                {options && (
                    <>
                        <Autocomplete
                            name="typeOfSupply"
                            className={classes.textField}
                            style={{ marginRight: 20, marginTop: 10 }}
                            label="Art der Versorgung"
                            state={options[0].label}
                            options={options.map((item) =>
                                [{ label: item.label, value: item.label }].pop()
                            )}
                            errorClassName={classes.errorMargin}
                            onInputChange={handleChange}
                        />
                        <CaseformButton
                            variant="contained"
                            onClick={handleOk}
                            style={{
                                maxHeight: 58,
                                marginLeft: "auto",
                                marginRight: 20,
                                marginTop: 25,
                            }}
                        >
                            Übernehmen
                        </CaseformButton>
                    </>
                )}
                <SecondaryCaseformButton
                    variant="contained"
                    onClick={handleCancel}
                    style={{ maxHeight: 58, marginTop: 25 }}
                >
                    Abbrechen
                </SecondaryCaseformButton>
            </div>
        </>
    );
}
