import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import StatisticsTable from "./StatisticsTable";
import StatisticsInput from "./StatisticsInput";
import StatisticActionsSection from "./StatisticActionsSection";
import moment from "moment";
import { getDataFromSmartDesign } from "../utils/api-client";

import FetchLinkedCriterias from "./FetchLinkedCriterias";
import * as Yup from "yup";
import { Formik } from "formik";
import LoadingSpinner from "../utils/LoadingSpinner";
const GROUP_FIELD_TO_SKIP="GID";
const GROUP_VALUE_TO_SKIP_ALL_USERS="-8";
const GROUP_VALUE_TO_SKIP_ORG_USERS="-20";

function StatisticsMainView(props) {
  const [mandant, setMandant] = useState("");
  const [loadedCriterias, setLoadedCriterias] = useState([]);
  const [selectedDraftIdx, setSelectedDraftIdx] = useState([]);
  const [users, setUsersValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupsJSON, setGroupsJSON] = useState([]);
  const [user, setUser] = useState("");

  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") || null
  );
  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") || null
  );
  const [statisticsQueryList, setStatisticsQueryList] = useState();
  const [statisticsQueryListName, setStatisticsQueryListName] = useState();
  const [formikInitialState] = useState({
    startDate: startDate,
    endDate: endDate,
    user: user,
  });
  

  moment.locale("de");

  async function discardCriterias() {
    try {
      selectedDraftIdx
        .sort((a, b) => b - a)
        .forEach((draftIdx) => {
          loadedCriterias.splice(draftIdx, 1);
        });

      displaySnackbar("success", "Die Kriterien wurden erfolgreich gelöscht");
    } catch (error) {
      displaySnackbar(
        "error",
        "Beim Löschen der Kriterien ist ein Problem aufgetreten. Bitte versuchen Sie es erneut."
      );
    } finally {
      setShowDiscardPopup(false);
      setSelectedDraftIdx([]);
    }
  }

   useEffect(() => {
        (async () => {
            try {
                const response = await getDataFromSmartDesign("/admin/licences/get");
                setMandant(response.mandant);
            } catch (error) {
                alert("something went wrong, please contact to the CareCM 2.0 team");
                return;
            }
        })();
    }, [mandant, setMandant]);

  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("");
  useEffect(() => {
    (async () => {
      try {
        await fetchCriterias();
        if (users.length === 0) {
          await getUser();
        }
        if (groupsJSON.length !== 0) {
          await getGroups();
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    })();
  }, [fetchCriterias, user, getUser, getGroups, groupsJSON, props.location, users]);

    async function getUser() {
    localStorage.setItem("user","");
    return Promise.all(
      (async () => {
        var result = [];
        const {
          directSuperGroups: elements,
          fields: user,
        } = await getDataFromSmartDesign("/user/self");
        result.push(user);
        setGroupsJSON(elements);
        setUsersValue(result);
      })()
    );
  }
  
  async function getGroups() {
    return Promise.all(
      (async () => {
        var result = [];
        await groupsJSON.forEach(async (groupID) => {
          const { fields } = await getDataFromSmartDesign("/group/" + groupID);
          if(fields[GROUP_FIELD_TO_SKIP]!==GROUP_VALUE_TO_SKIP_ALL_USERS && fields[GROUP_FIELD_TO_SKIP]!==GROUP_VALUE_TO_SKIP_ORG_USERS ){
            result.push(fields);
            setUsersValue(users.concat(result));
          }
        });
        setGroupsJSON([]);
      })()
    );
  }
  
  async function fetchCriterias() {
    if (
      props.location !== undefined &&
      props.location.state !== undefined &&
      loadedCriterias.length === 0
    ) {
      setStatisticsQueryListName(props.location.state.selectedListName)
      setStatisticsQueryList(props.location.state.selectedListGGUID);
      setLoadedCriterias(await FetchLinkedCriterias(statisticsQueryList));
    }
  }
  function displaySnackbar(snackbarType, snackbarMessage) {
    setSnackbarType(snackbarType);
    setSnackbarMessage(snackbarMessage);
    setShowSnackbar(true);
  }
  const statisticsSchema = Yup.object().shape({
    startDate: Yup.date().required("Dies ist ein Pflichtfeld").nullable(),
    endDate: Yup.date()
      .required("Dies ist ein Pflichtfeld")
      .nullable()
      .min(
        Yup.ref("startDate"),
        "Das Enddatum sollte größer als das Startdatum sein"
      ),
    user: Yup.string().nullable().required("Dies ist ein Pflichtfeld"),
  });
  return (
    <div>
      {loading && <LoadingSpinner />}
      <Formik
        validationSchema={statisticsSchema}
        initialValues={formikInitialState}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <>
            <Helmet title={"Statistics"} />
            <StatisticsInput
              users={users}
              keepUser={ (
                props.location !== undefined &&
                props.location.state !== undefined) ? props.location.state.keepUser : false }
              values={values}
              setFieldValue={setFieldValue}
              setUser={setUser}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              loading={loading}
              mandant={mandant}
              keepCheckbox={(
                props.location !== undefined &&
                props.location.state !== undefined) ? props.location.state.keepCheckbox : true}
            />
            <StatisticsTable
              loadedCriterias={loadedCriterias}
              setSelectedDraftIdx={setSelectedDraftIdx}
              setShowDiscardPopup={setShowDiscardPopup}
              loading={loading}
            />
            <StatisticActionsSection
              showDiscardPopup={showDiscardPopup}
              setShowDiscardPopup={setShowDiscardPopup}
              discardCriterias={discardCriterias}
              showSnackbar={showSnackbar}
              setShowSnackbar={setShowSnackbar}
              snackbarType={snackbarType}
              snackbarMessage={snackbarMessage}
              props={props}
              handleSubmit={handleSubmit}
              values={values}
              users={users}
              statisticsQueryListName={statisticsQueryListName}
              loadedCriterias={loadedCriterias}
              setSnackbarType={setSnackbarType}
              setSnackbarMessage={setSnackbarMessage}
              statisticsQueryList={statisticsQueryList}
              setLoading={setLoading}
              loading={loading}
              mandant={mandant}
            />
          </>
        )}
      </Formik>
    </div>
  );
}
export default StatisticsMainView;
