const translations = [
  {
    label: "Monatlich",
    value: "A46C6239CEF14680948335FCAAA39FE1",
    group: "PERIODICITY",
  },
  {
    label: "Quartärlich",
    value: "A46C6239CEF14680948335FCAAA39FE2",
    group: "PERIODICITY",
  },
  {
    label: "Jährlich",
    value: "A46C6239CEF14680948335FCAAA39FE3",
    group: "PERIODICITY",
  },
  {
    label: "Netzwerk",
    value: "NETWORK",
  },
  {
    label: "Adresse",
    value: "ADDRESS",
  },
  {
    label: "Vorgang",
    value: "VORGANG",
  },
  {
    label: "Schnellerfassung",
    value: "QUICKDOCUMENTATION",
  },
  {
    label: "Information",
    value: "INFORMATION",
  },
  {
    label: "Fall",
    value: "FALL",
  },
];

export { translations };
