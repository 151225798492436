import React, { useState, useEffect } from "react";
import { makeStyles, createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import HelpIcon from "@material-ui/icons/Help";
import MenuItem from "@material-ui/core/MenuItem";
import { dataObjectTypes } from "../../../resources/dataObjectTypes";
import FormControl from "@material-ui/core/FormControl";
import ViewTable from "../../utils/ViewTable";
import { getDataFromSmartDesign, postDataToSmartDesign } from "../../utils/api-client";
import { Button as CFButton } from "../../utils/CFButtons";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import ActionsBar from "../../utils/ActionsBar";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Tooltip from "@material-ui/core/Tooltip";
import { userFieldValues } from "../../../resources/userFieldValues";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import { useHistory, Link } from "react-router-dom";
import { SecondaryButton } from "../../utils/CFButtons";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { navigateBack } from "../../utils/common";
import { withRouter } from "react-router";
import FetchLinkedCriterias from "../../statistics/FetchLinkedCriterias";

const DAUER_IN_RELEVANT_DOS = "DATETIMEDURATION";
const NOFIELD = "NOFIELD";
const FIELDZIP3 = "ZIP3";
const NODATEFIELD = { fieldName: "", displayname: "Kein Datum" };
const VALID = 0;
const NOOBJECTTYPEISSELECTED = 1;
const NOCRITERIALISTISSELECTED = 2;
const NODATEFIELDISSELECTED = 3;
const NOUSERFIELDISSELECTED = 4;
const NOVIEWISSELECTED = 5;
const NOCRITERIASELECTED = 6;
const VALIDATE_ERROR_MESSAGE = [
    "Bitte wählen Sie einen Datensatztyp.",
    "Bitte wählen Sie eine Kriterienliste.",
    "Bitte wählen Sie einen Wert im Datumsfeld.",
    "Bitte wählen Sie einen Wert im Benutzerfeld.",
    "Bitte eine Ansicht wählen",
    "Please, select criteria before this criteria would be put in the list",
];

function MainQueryView(props) {
    const [rowsSelected, setRowsSelected] = useState([]);
    const [userFields, setUserFields] = useState([]);
    const [selectedView, setSelectedView] = useState([]);
    const [availableViews, setAvailableViews] = useState([]);
    const [possibleFields, setPossibleFields] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState("");
    const [dataObjectType, setDataObjectType] = useState("");
    const [selectedField, setSelectedField] = useState();
    const [isBooleanTrueSelected, setIsBooleanTrueSelected] = useState(false);
    const [isBooleanFalseSelected, setIsBooleanFalseSelected] = useState(false);
    const [isHardcodedCriteria, setIsHardcodedCriteria] = useState(false);
    const [isBawueCriteria, setIsBawueCriteria] = useState(false);
    const [isBerlinCriteria, setIsBerlinCriteria] = useState(false);
    const [statisticsTextFieldValue, setStatisticsTextFieldValue] = useState();
    const [checkAllSelectedValues, setCheckAllSelectedValues] = useState(true);
    const [selectedSVLValues, setSelectedSVLValues] = useState([]);
    const [selectedChecks, setSelectedChecks] = useState([]);
    const [dateFields, setDateFields] = useState([]);
    const [statisticsQueryList, setStatisticsQueryList] = useState("");
    const [statisticsQueryLists, setStatisticsQueryLists] = useState([]);
    const [selectedDateField, setSelectedDateField] = useState("Erzeugt am");
    const [selectedUserField, setSelectedUserField] = useState();
    const [isSelectionDisabled, setIsSelectionDisabled] = useState(false);
    const [criteriaList, setCriteriaList] = useState([]);
    const [selectedCriteriaBeforeNewOne, setSelectedCriteriaBeforeNewOne] = useState("");
    const [disableSendButton, setDisableSendButton] = useState(false);
    const [isCumulatedDuration, setIsCumulatedDuration] = useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                paddingLeft: theme.spacing(0),
            },
        },
        textField: {
            marginRight: 75,
            width: 275,
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
                marginRight: 0,
            },
        },
        labelRoot: {
            fontSize: 13,
            paddingRight: "20px",
            marginTop: "-5px",
        },
        formControlRow: {
            margin: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        formControlFull: {
            margin: theme.spacing(2),
            width: "100%",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        wideControl: {
            width: 350,
            [theme.breakpoints.down(400 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
            },
        },
        formControl: {
            margin: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
    }));
    const columns = [
        {
            name: "VIEWNAME",
            label: "Name der Ansicht",
        },
    ];
    const tableTheme = () =>
        createTheme({
            overrides: {
                MuiCheckbox: {
                    colorPrimary: {
                        "&$checked": {
                            color: "#DC001A",
                        },
                    },
                },
            },
        });
    const classes = useStyles();
    useEffect(() => {
        if (props.location.state !== null && props.location.state !== undefined) {
            setStatisticsQueryList(props.location.state.selectedStatisticsQueryList.GGUID);
            setIsSelectionDisabled(true);
        }
        (async () => {
            try {
                if (statisticsQueryLists.length === 0) {
                    await getQueryLists();
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [statisticsQueryLists.length, props]);
    const history = useHistory();
    async function getQueryLists() {
        return Promise.all(
            (async () => {
                var result = [];
                const queries = await getDataFromSmartDesign("/type/statisticsquerylist/full");
                queries.forEach(async (statisticsQueryList) => {
                    result.push(statisticsQueryList.fields);
                });
                setStatisticsQueryLists(result);
            })()
        );
    }
    async function navigateToPreviousPage() {
        if (props.location.state === null || props.location.state === undefined) {
            props.history.push(navigateBack(navigateBack(props.location.pathname)));
        } else {
            props.history.goBack();
        }
    }
    const handleSwitchChange = (name) => (_, checked) => {
        if ("isBooleanTrueSelected" === name) {
            setIsBooleanTrueSelected(checked);
        } else {
            setIsBooleanFalseSelected(checked);
        }
    };
    const options = {
        selectableRows: "single",
        search: true,
        textLabels: {
            body: {
                noMatch: "Leider wurden keine passenden Datensätze gefunden",
                toolTip: "Sortieren",
            },
            pagination: {
                rowsPerPage: "Einträge pro Seite",
                displayRows: "von",
            },
            selectedRows: {
                text: "Zeile(n) markiert",
                delete: "Alle markierten Kriterien löschen",
                deleteAria: "Alle markierten Kriterien löschen",
            },
        },
        rowsSelected: rowsSelected,
        onRowSelectionChange: (currentRowsSelected) => {
            if (currentRowsSelected !== undefined) {
                setRowsSelected([currentRowsSelected[0].dataIndex]);
                setSelectedView(availableViews[currentRowsSelected[0].dataIndex].VIEWID);
                setRowsSelected([currentRowsSelected[0].dataIndex]);
            }
        },
    };
    function displaySnackbar(snackbarType, snackbarMessage) {
        setSnackbarType(snackbarType);
        setSnackbarMessage(snackbarMessage);
        setShowSnackbar(true);
    }
    function setSVLValue(value, checked) {
        value = value.concat(", ");
        const index = selectedSVLValues.indexOf(value);
        if (checked) {
            if (index === -1) {
                selectedSVLValues.push(value);
            }
        } else {
            if (index > -1) {
                selectedSVLValues.splice(index, 1);
            }
        }
    }
    function handleFieldChange(value) {
        setSelectedField(value);
        setSelectedSVLValues([]);
        setCheckAllSelectedValues(true);
        setSelectedChecks(
            value
                ? value.hasOwnProperty("possibleValues")
                    ? () => {
                        const SVLArray = [];
                        value.possibleValues.forEach((value) =>
                            SVLArray.push(value.GGUID.concat(", "))
                        );
                        setSelectedSVLValues(SVLArray);
                        return Array.from({ length: value.possibleValues.length }, () => true);
                    }
                    : []
                : []
        );
        setIsBooleanTrueSelected();
        setIsBooleanFalseSelected();
        setStatisticsTextFieldValue("");
    }
    const handleChange = (checked, index) => {
        const updatedSelectedChecks = [...selectedChecks];
        updatedSelectedChecks[index] = checked;
        setSelectedChecks(updatedSelectedChecks);
    };

    const selectAllSVLs = (checked) => {
        selectedSVLValues.splice(0, selectedSVLValues.length);
        if (checked) {
            setCheckAllSelectedValues(true);
            selectedField.possibleValues.forEach((value) =>
                selectedSVLValues.push(value.GGUID.concat(", "))
            );
            setSelectedChecks(selectedChecks.map(() => true));
        } else {
            setCheckAllSelectedValues(false);
            setSelectedChecks(selectedChecks.map(() => false));
        }
    };
    async function updateTable(dataObject) {
        try {
            handleFieldChange();
            if ("VORGANG" !== dataObject) {
                setUserFields(userFieldValues.filter((item) => item.value !== "RESPONSIBLEUSER"));
            } else {
                setUserFields(userFieldValues);
            }
            setSelectedView([]);
            setRowsSelected([]);
            setDateFields(dateFields.splice(0, dateFields.length));
            setSelectedDateField("Erzeugt am");
            setSelectedUserField("");
            setDataObjectType(dataObject);
            const viewsResponse = await getDataFromSmartDesign("/admin/views/" + dataObject);
            var views = viewsResponse.map(({ fields }) => {
                return fields;
            });
            setAvailableViews(views);
            const {
                properties: { fields },
            } = await getDataFromSmartDesign("/type/" + dataObject + "/schema");
            var result = [];
            for (var fieldName in fields) {
                var fieldObject = fields[fieldName];
                if (
                    fieldObject !== undefined &&
                    fieldObject.type !== undefined &&
                    fieldObject.hidden === false
                ) {
                    fieldObject.fieldName = fieldName;
                    if (
                        fieldObject.systemField === false &&
                        (fieldObject.type === "BOOLEAN" ||
                            fieldObject.type === "INT" ||
                            fieldObject.type === "STRING" ||
                            fieldObject.type === "SELECTIONVALUELIST")
                    ) {
                        result.push(fieldObject);
                    } else if (fieldObject.type === "DATETIME") {
                        dateFields.push(fieldObject);
                    }
                }
            }
            dateFields.push(NODATEFIELD);
            setDateFields(dateFields);
            setPossibleFields(result);
            setAvailableViews(views);
        } catch (error) {
            alert(error);
        }
    }
    function validate() {
        if (dataObjectType === undefined || dataObjectType === "") {
            return NOOBJECTTYPEISSELECTED;
        } else if (statisticsQueryList === undefined || statisticsQueryList === "") {
            return NOCRITERIALISTISSELECTED;
        } else if (dateFields === undefined) {
            return NODATEFIELDISSELECTED;
        } else if (selectedUserField === undefined || selectedUserField === "") {
            return NOUSERFIELDISSELECTED;
        } else if (selectedView === undefined || selectedView.length <= 0) {
            return NOVIEWISSELECTED;
        } else if (selectedCriteriaBeforeNewOne === "" && criteriaList.length !== 0) {
            return NOCRITERIASELECTED;
        } else {
            return VALID;
        }
    }
    function getValidateError() {
        if (validate() !== VALID) {
            return VALIDATE_ERROR_MESSAGE[validate() - 1];
        } else {
            return "";
        }
    }
    const onCriteriaChange = (e) => {
        setSelectedCriteriaBeforeNewOne(e.target.value);
    };
    async function sendProvidedCriteria() {
        setDisableSendButton(true);
        try {
            if (validate() === VALID) {
                var group = dataObjectTypes.filter((value) => dataObjectType === value.value);
                var value =
                    selectedField !== undefined ? formatSelectedField(selectedField) : NOFIELD;
                var selectedDateFieldDisplayName = dateFields.filter(
                    (object) => selectedDateField === object.displayname
                );
                if (
                    value === undefined ||
                    value === null ||
                    value === "" ||
                    value === "false, false"
                ) {
                    displaySnackbar("error", "Bitte geben Sie einen Wert ein");
                    return;
                }
                var fieldType = "",
                    fieldName = "",
                    fieldValue = "";
                if (isCumulatedDuration){
                    fieldName = dataObjectType + "." + DAUER_IN_RELEVANT_DOS;
                    fieldType = "STRING";
                    fieldValue = "Anzahl";
                }  else if (value === FIELDZIP3) {
                    fieldName = dataObjectType + "." + FIELDZIP3;
                    fieldType = "STRING";                           
                } else if (value !== NOFIELD) {
                    fieldType = selectedField.type;
                    fieldName = dataObjectType + "." + selectedField.fieldName;
                    fieldValue = value;
                }
                await postDataToSmartDesign("/admin/create/criterias", {
                    selectedStatisticQueryList: statisticsQueryList,
                    selectedViews: [selectedView],
                    selectedGroup: group[0].value,
                    statisticsFieldFieldType: fieldType,
                    statisticsFieldFieldName: fieldName,
                    statisticsFieldValue: fieldValue,
                    statisticsObjectDateField: selectedDateFieldDisplayName[0].fieldName,
                    statisticsObjectUserField: selectedUserField,
                    isBawueCriteria: isBawueCriteria,
                    isBerlinCriteria: isBerlinCriteria,
                    isHardcodedCriteria: isHardcodedCriteria,
                    isCumulatedDuration: isCumulatedDuration,
                    selectedCriteriaBeforeNewOne,
                });
                const fetchedCriteriaList = await FetchLinkedCriterias(statisticsQueryList);
                setCriteriaList(fetchedCriteriaList);
                displaySnackbar(
                    "success",
                    "Die ausgewählte Kriterie würde erfolgreich gespeichert"
                );
            } else {
                displaySnackbar("error", getValidateError());
            }
        } catch (error) {
            displaySnackbar("error", "Die ausgewählte Kriterie konnte nicht gespeichert werden");
        }
        setDisableSendButton(false);
    }
    function formatSelectedField(selectedField) {
        if (selectedField.type === "INT" || selectedField.type === "STRING") {
            if (selectedField.fieldName === FIELDZIP3) return FIELDZIP3;
            else return statisticsTextFieldValue;
        } else if (selectedField.type === "SELECTIONVALUELIST") {
            var result = "";
            selectedSVLValues.forEach(
                (selectedSVLValue) => (result = result.concat(selectedSVLValue))
            );
            return result.slice(0, -2);
        } else if (selectedField.type === "BOOLEAN") {
            var resultBoolean = "";
            resultBoolean = resultBoolean
                .concat(isBooleanTrueSelected !== undefined ? isBooleanTrueSelected : "false")
                .concat(", ")
                .concat(isBooleanFalseSelected !== undefined ? isBooleanFalseSelected : "false");
            return resultBoolean;
        }
        return null;
    }
    function dataObjectTypeFilter(objectType) {
        var result;
        switch (objectType.key) {
            case "INFORMATION":
                result = props.location.mandant === "RLP" || props.location.mandant === "BERLIN";
                break;
            case "QUICKDOCUMENTATION":
                result = props.location.mandant !== "RLP";
                break;
            case "FALL":
                result = props.location.wohnberatung === "true";
                break;
            default:
                result = true;
                break;
        }
        return result;
    }
    return (
        <div>
            {" "}
            <FormControl component="fieldset" className={classes.formControlRow}>
                <FormGroup row>
                    <TextField
                        select
                        name="dataObjectTypes"
                        label="Datensatztyp"
                        className={classes.textField}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                            IconComponent: KeyboardArrowDownRoundedIcon,
                        }}
                        margin="normal"
                        variant="outlined"
                        errorClassName={classes.errorMargin}
                        onChange={(value) => {
                            if (value.target.value==="ADDRESS") setIsCumulatedDuration(false);
                            updateTable(value.target.value);
                        }}
                    >
                        {dataObjectTypes.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        )).filter(dataObjectTypeFilter)}
                    </TextField>
                    <TextField
                        select
                        name="statisticsQueryList"
                        value={statisticsQueryList}
                        label="Kriterienliste"
                        className={classes.textField}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                            IconComponent: KeyboardArrowDownRoundedIcon,
                        }}
                        margin="normal"
                        disabled={isSelectionDisabled}
                        variant="outlined"
                        errorClassName={classes.errorMargin}
                        onChange={async (value) => {
                            setSelectedCriteriaBeforeNewOne("");
                            setStatisticsQueryList(value.target.value);
                            const fetchedCriteriaList = await FetchLinkedCriterias(
                                value.target.value
                            );
                            setCriteriaList(fetchedCriteriaList);
                        }}
                    >
                        {statisticsQueryLists.map(({ NAME, GGUID }) => (
                            <MenuItem key={NAME} value={GGUID}>
                                {NAME}
                            </MenuItem>
                        ))}
                    </TextField>
                    {criteriaList.length !== 0 && (
                        <>
                            <TextField
                                select
                                name="statisticsQuery"
                                value={selectedCriteriaBeforeNewOne}
                                label="Reihenfolge der bereits enthaltenen Kriterien"
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                    },
                                }}
                                className={classes.textField}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                    IconComponent: KeyboardArrowDownRoundedIcon,
                                }}
                                margin="normal"
                                disabled={isSelectionDisabled}
                                variant="outlined"
                                errorClassName={classes.errorMargin}
                                onChange={onCriteriaChange}
                            >
                                {criteriaList.map(({ NAME, GGUID }, idx) => (
                                    <MenuItem key={idx} value={GGUID}>
                                        {NAME}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Tooltip
                                style={{ paddingTop: "28" }}
                                title={
                                    "Das ausgewählte Kriterium gibt die Position des zu ergänzenden Kriteriums vor, indem das neue Kriterium vor dem ausgewählte Kriterium eingefügt wird. Wird kein Kriterium ausgewählt, wird das neue Kriterium am Ende der Kriterienliste eingefügt."
                                }
                            >
                                <HelpIcon />
                            </Tooltip>
                        </>
                    )}
                    <div component="fieldset" className={classes.formControlRow}>
                        <Tooltip
                            title={"Klicken Sie hier um neue Kriterienliste zu erstellen."}
                            arrow
                        >
                            <AddCircleRoundedIcon
                                onClick={() => {
                                    history.push({
                                        pathname: "/admin/criterias/list/create",
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                </FormGroup>
            </FormControl>
            <FormControl component="fieldset" className={classes.formControlRow}>
                <FormGroup row>
                    {props.location.mandant === "RLP" && (
                        <FormControlLabel
                            className={classes.wideControl}
                            checked={isHardcodedCriteria}
                            onChange={(_, checked) => {
                                setIsHardcodedCriteria(checked);
                            }}
                            label="Anwendung von Ansichten mit verknüpften Daten"
                            control={<Checkbox color="primary" />}
                        />
                    )}

                    {props.location.mandant !== "RLP" && (
                    <FormControlLabel
                        className={classes.wideControl}
                        checked={isBawueCriteria}
                        onChange={(_, checked) => {
                            setIsBawueCriteria(checked);
                        }}
                        label="Kumulierte Auswertung von Vorgängen und Schnellerfassungen"
                        control={<Checkbox color="primary" />}
                    />
                    )}
                    {props.location.mandant === "BERLIN" && ["VORGANG", "QUICKDOCUMENTATION", "INFORMATION", "NETWORK"].includes(dataObjectType) && (
                        <FormControlLabel
                            className={classes.wideControl}
                            checked={isCumulatedDuration}
                            onChange={(_, checked) => {
                                setIsCumulatedDuration(checked);
                            }}
                            label="Kumulierte Dauer"
                            control={<Checkbox color="primary" />}
                        />
                    )}
                    {props.location.mandant === "BERLIN" && (
                    <FormControlLabel
                        className={classes.wideControl}
                        checked={isBerlinCriteria}
                        onChange={(_, checked) => {
                            setIsBerlinCriteria(checked);
                        }}
                        label="Kumulierte Auswertung von Vorgängen und Schnellerfassungen und Informationen"
                        control={<Checkbox color="primary" />}
                    />
                    )}
                </FormGroup>
            </FormControl>
            {dataObjectType !== `` && (
                <FormControl component="fieldset" className={classes.formControlRow}>
                    {" "}
                    <FormGroup row>
                        <TextField
                            select
                            label="Bitte Datumsfeld auswählen"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                                IconComponent: KeyboardArrowDownRoundedIcon,
                            }}
                            margin="normal"
                            variant="outlined"
                            errorClassName={classes.errorMargin}
                            onChange={(value) => {
                                var dateValueObject = dateFields.filter(
                                    (object) => value.target.value === object.displayname
                                );
                                var dateValueDisplayName =
                                    dateValueObject.length > 0
                                        ? dateValueObject[0].displayname
                                        : null;
                                setSelectedDateField(dateValueDisplayName);
                            }}
                            value={selectedDateField}
                        >
                            {dateFields
                                .slice()
                                .sort(
                                    (a, b) =>
                                        b.displayname.toUpperCase() - a.displayname.toUpperCase()
                                )
                                .map(({ displayname }) => (
                                    <MenuItem key={displayname} value={displayname}>
                                        {displayname}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <TextField
                            select
                            name="selectedUserField"
                            label="Bitte Benutzerfelder auswählen"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                                IconComponent: KeyboardArrowDownRoundedIcon,
                            }}
                            margin="normal"
                            variant="outlined"
                            errorClassName={classes.errorMargin}
                            onChange={(value) => {
                                setSelectedUserField(value.target.value);
                            }}
                            value={selectedUserField}
                        >
                            {userFields.map(({ label, value }) => (
                                <MenuItem key={label} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormGroup>
                    <FormGroup row>
                        { !isCumulatedDuration && (
                        <TextField
                            select
                            name="possibleFields"
                            label="Feld"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                                IconComponent: KeyboardArrowDownRoundedIcon,
                            }}
                            margin="normal"
                            variant="outlined"
                            errorClassName={classes.errorMargin}
                            onChange={(value) => {
                                var providedValue = possibleFields.filter(
                                    (object) => value.target.value === object.displayname
                                );
                                handleFieldChange(providedValue[0]);
                            }}
                        >
                            {possibleFields
                                .slice()
                                .sort(
                                    (a, b) =>
                                        b.displayname.toUpperCase() - a.displayname.toUpperCase()
                                )
                                .map(({ displayname }, idx) => (
                                    <MenuItem key={idx} value={displayname}>
                                        {displayname}
                                    </MenuItem>
                                ))}
                        </TextField>
                        )}
                        {selectedField !== undefined && selectedField.fieldName === FIELDZIP3 && (
                            <div component="fieldset" className={classes.formControlRow}>
                                <Tooltip
                                    title={
                                        "In Ihrer Statistik wird jede Postleitzahl in einer neuen Zeile dargestellt, sodass Sie eine vollständige Liste aller dokumentierten Postleitzahlen erhalten."
                                    }
                                    arrow
                                >
                                    <HelpIcon />
                                </Tooltip>
                            </div>
                        )}
                    </FormGroup>
                </FormControl>
            )}
            {selectedField !== undefined && selectedField.type === "BOOLEAN" && (
                <FormControl component="fieldset" className={classes.formControlFull}>
                    <FormGroup row>
                        <FormControlLabel
                            className={classes.wideControl}
                            checked={isBooleanTrueSelected}
                            onChange={handleSwitchChange("isBooleanTrueSelected")}
                            label="wahr"
                            control={<Checkbox color="primary" />}
                        />

                        <FormControlLabel
                            className={classes.wideControl}
                            checked={isBooleanFalseSelected}
                            onChange={handleSwitchChange("isBooleanFalseSelected")}
                            label="falsch"
                            control={<Checkbox color="primary" />}
                        />
                    </FormGroup>
                </FormControl>
            )}
            {selectedField !== undefined && !isCumulatedDuration &&
                selectedField.fieldName !== FIELDZIP3 &&
                (selectedField.type === "STRING" || selectedField.type === "INT") && (
                    <FormControl component="fieldset" className={classes.formControlFull}>
                        <Tooltip
                            title={
                                selectedField.type === "STRING"
                                    ? "Bitte den Wert eigeben"
                                    : "Bitte geben Sie die Intervalle in folgendem Format ein: 1:19/20:29/30:99 wenn Sie beispielsweise wissen wollen, wie alt die Rat- und Hilfesuchenden durchschnittlich sind. Die einzelnen Intervalle m\u00FCssen durch Kommas getrennt werden."
                            }
                            arrow
                        >
                            <TextField
                                label={selectedField.type === "STRING" ? "ist gleich" : "Intervall"}
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                errorClassName={classes.errorMargin}
                                onChange={(value) => {
                                    setStatisticsTextFieldValue(value.target.value);
                                }}
                            />
                        </Tooltip>
                    </FormControl>
                )}
            {selectedField !== undefined && selectedField.type === "SELECTIONVALUELIST" && (
                <div>
                    <FormGroup className={classes.formControl}>
                        <FormControlLabel
                            className={classes.wideControl}
                            control={
                                <Checkbox
                                    checked={checkAllSelectedValues}
                                    color="primary"
                                    onChange={(_, checked) => {
                                        selectAllSVLs(checked);
                                    }}
                                />
                            }
                            label={"Alle Feldwerte auswählen"}
                        />
                    </FormGroup>
                    {selectedField.possibleValues.map(
                        ({ displayName: label, GGUID: value }, index) => (
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup row>
                                    <FormControlLabel
                                        key={value}
                                        className={classes.wideControl}
                                        control={
                                            <Checkbox
                                                checked={selectedChecks[index]}
                                                color="primary"
                                                onChange={(_, checked) => {
                                                    setSVLValue(value, checked);
                                                    handleChange(checked, index);
                                                }}
                                                value={value}
                                            />
                                        }
                                        label={label}
                                    />
                                </FormGroup>
                            </FormControl>
                        )
                    )}
                </div>
            )}
            <MuiThemeProvider theme={tableTheme}>
                <ViewTable
                    title={"Ausgewählte Ansicht"}
                    data={availableViews}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
            <ActionsBar>
                <div>
                    <SecondaryButton component={Link} onClick={() => navigateToPreviousPage()}>
                        <ArrowBackRoundedIcon />
                        Zurück
                    </SecondaryButton>
                </div>
                <div>
                    <CFButton
                        variant="contained"
                        onClick={() => sendProvidedCriteria()}
                        disabled={disableSendButton}
                    >
                        <SendRoundedIcon />
                        Speichern
                    </CFButton>
                </div>
            </ActionsBar>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={() => setShowSnackbar(false)}
            >
                <CFSnackbarContent
                    onClose={() => setShowSnackbar(false)}
                    variant={snackbarType}
                    message={snackbarMessage}
                />
            </Snackbar>
        </div>
    );
}
export default withRouter(MainQueryView);
