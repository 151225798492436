import { Snackbar, SnackbarContent } from '@material-ui/core'
import React, { Component} from 'react'
import '../caseform/broadcast/broadcastButton.css'
import {
  getDataFromSmartDesign,
  postDataToSmartDesignResponse
} from '../utils/api-client'

class TimePolicyWarning extends Component {
  constructor() {
    super()

    this.state = {
      snackbarMessage: '',
      snackbarType: '',
      showSnackbar: false,
      open: false,
    }
  }

  onSubmit = () => {
    this.setState({ open: true })
  }

  displaySnackbar = (snackbarType, snackbarMessage) => {
    this.setState({
      snackbarType: snackbarType,
      snackbarMessage: snackbarMessage,
      showSnackbar: true,
    })
  }
  async componentDidMount() {
    await this.postData();
  }

  getAutoHideDuration = (snackbarType) => {
    switch (snackbarType) {
      case 'error':
        return 50000;
      case 'warning':
        return 10000;
      default:
        return 6000;
    }
  };

  async postData() {
    const { fields: user2 } = await getDataFromSmartDesign("/user/self");
    let userGGUID = user2.GGUIDOFADDRESSRECORD;
    let displayName= user2.DISPLAYNAME
    var url_string = window.location.href
    var url = new URL(url_string)
    var years = url.searchParams.get('years')
    const endpointUrl = 'timetracking/vacation';
      const queryParams = [
          `userGuid=${userGGUID}`,
          `userName=${displayName}`,
          `years=${years}`
      ];
      
      const finalUrl = endpointUrl + '?' + queryParams.join('&');
      var statusVar;
    await postDataToSmartDesignResponse(finalUrl).then(response => {
      statusVar = response.status;
      if (statusVar === 204)
        return null;
      return response.json();
    }).then (data => {
      if (data !== null) {
      const errorMessages = [];
      const warningMessages = [];
      if (statusVar === 200){
      if (Array.isArray(data) && data.length > 0) {
        data.forEach(dataObj => {
          if (dataObj.isWithinBounds === 'false') {
            errorMessages.push(
              `Für das Jahr ${dataObj.year}: Derzeit haben Sie ${dataObj.currentVacationCount} Urlaubstage beantragt, während das Maximum ${dataObj.allowedVacationCount} beträgt.`
            );
          } else if (dataObj.isWithinBounds === 'true' && (dataObj.currentVacationCount === dataObj.allowedVacationCount)) {
            warningMessages.push(`Für das Jahr ${dataObj.year}: Sie haben bereits alle Ihre freien Tage genutzt.`);
          }
        });
        if (errorMessages.length > 0) {
          const errorMessage = errorMessages.join(' ');
          this.displaySnackbar('error', errorMessage);
        } else if (warningMessages.length > 0) {
          const warningMessage = warningMessages.join(' ');
          this.displaySnackbar('warning', warningMessage);
        }
      } else {
        console.log("No valid data found in the array");
      }
    }
    }
    })
  }
  
render() {
  const { showSnackbar, snackbarType, snackbarMessage } = this.state;
  const duration = this.getAutoHideDuration(snackbarType);
  return (
    <div id='container' className='divButton'>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        autoHideDuration= {duration}
        onClose={() => this.setState({ showSnackbar: false })}
      >
        <SnackbarContent
          style={{
            fontSize: '1.2rem',
            backgroundColor: snackbarType === 'error' ? 'red' : 'orange',
          }}
          message={snackbarMessage}
        />
      </Snackbar>
    </div>
  );
}
}
export default TimePolicyWarning
