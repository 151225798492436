import React, { Suspense } from "react";
import Router from "./components/Router";
import { Navbar } from "react-bootstrap";
import "./frontendApp.css";
import Helmet from "react-helmet";
import { theme } from "./themes/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "../src/i18n/index.json";
i18n.use(initReactI18next).init({
  resources: {
    de: {
      translation: translations,
    },
  },
  ns: ["translation"],
  lng: "de",
  interpolation: {
    escapeValue: false,
  },
});
function App() {
  return (
    <Suspense fallback={null}>
      <Helmet titleTemplate="%s – CareCM" defaultTitle="CareCM">
        <html lang="de" />
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <Navbar />
        <Router />
      </MuiThemeProvider>
    </Suspense>
  );
}
export default App;
