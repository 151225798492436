import React from "react";
import Autocomplete from "../utils/Autocomplete";
import DateFieldWithValidation from "../utils/DateFieldWithValidation";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { DatePicker } from "formik-material-ui-pickers";
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";

export default function StatisticsInput({
  users,
  setFieldValue,
  values,
  loading,
  keepUser,
  keepCheckbox,
  mandant
}) {
  moment.locale("de");
  const useStyles = makeStyles((theme) => ({
    formControlRow: {
      margin: theme.spacing(2),
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      [theme.breakpoints.down(50 + theme.spacing(3) * 2)]: {
        margin: theme.spacing(1),
      },
      marginBottom: 150,
    },
    valueTypography: {
      fontWeight: 700,
      fontSize: 16,
      margin: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        margin: theme.spacing(1),
        fontSize: 16,
      },
    },
    formControl: {
      margin: theme.spacing(2),
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        margin: theme.spacing(1),
      },
    },
    textField: {
      width: 500,
      marginBottom: theme.spacing(0.25),
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        width: "55vw",
        marginRight: 0,
        marginBottom: theme.spacing(1),
      },
    },
    errorMargin: {
      marginRight: 75,
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        width: "82.5vw",
        marginRight: 0,
      },
    },
    actionButton: {
      fontWeight: 700,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      boxShadow: "none",
      textTransform: "none",
      borderRadius: 3,
      fontSize: 14,
      width: 125,
      lineHeight: 1.2,
      color: theme.palette.primary.main,
      padding: "2px 15px",
      border: "2px solid",
      backgroundColor: "transparent",
      borderColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "#FFFFFF",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
      },
    },
    root: {
      backgroundColor: "transparent",
      minWidth: 0,
      maxWidth: "100%",
      padding: "0rem 1rem",
      boxShadow: "none",
      margin: "-.27rem 0",
    },
    message: {
      color: theme.palette.error.main,
      display: "flex",
      alignItems: "center",
      padding: "2px 0",
      textAlign: "left",
      fontSize: ".765rem",
      lineHeight: "1.35rem",
    },
  }));
  const classes = useStyles();
  const handleOnInputChange = (_, value) => {
    setFieldValue("user", value);
    localStorage.setItem("user", value);
  };
  const validateUser = () => {
    return !users.map((item) => item.DISPLAYNAME).includes(values.user);
  };
  
  const [isChecked, setIsChecked] = React.useState(false);
  const [firstTime, setFirstTime] = React.useState(true);
  
  const handleChangeSwitch = (event) => {
    if (keepCheckbox){
      setFirstTime(false);
    }
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    
    setIsChecked(value);
    setFieldValue("showMonthColumns", value);
    localStorage.setItem("showMonthColumns", value);
  };

  return (
    <FormControl component="div" className={classes.formControlRow}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="de" moment={moment}>
        <div>
          <div>
            <Autocomplete
              error={validateUser()}
              name="user"
              state={(keepUser) ? localStorage.getItem("user"):null}
              className={classes.textField}
              label="Benutzer/Gruppen"
              options={users.map((item) =>
                [{ label: item.DISPLAYNAME, value: item.DISPLAYNAME }].pop()
              )}
              errorClassName={classes.errorMargin}
              onInputChange={handleOnInputChange}
              disabled={loading}
            />
            {validateUser() ? (
              <SnackbarContent
                classes={{
                  root: classes.root,
                  message: classes.message,
                }}
                {...{ className: classes.errorMargin }}
                message={
                  <span className={classes.message}>
                    {"Dies ist ein Pflichtfeld"}
                  </span>
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={classes.formControl}>
          <DateFieldWithValidation
            component={DatePicker}
            name="startDate"
            label="von"
            className={classes.textField}
            inputVariant="outlined"
            cancelLabel="Abbrechen"
            format={"DD.MM.YYYY"}
            disabled={loading}
            onChange={(eventOrValue) => {
              if (eventOrValue !== undefined) {
                if (eventOrValue.target === undefined) {
                  setFieldValue("startDate", eventOrValue);
                  localStorage.setItem("startDate", eventOrValue);
                } else {
                  setFieldValue("startDate", eventOrValue.target.value);
                  localStorage.setItem("startDate", eventOrValue.target.value);
                }
              }
            }}
          />
        </div>{" "}
        <div className={classes.formControl}>
          <DateFieldWithValidation
            component={DatePicker}
            name="endDate"
            label="bis"
            className={classes.textField}
            inputVariant="outlined"
            cancelLabel="Abbrechen"
            format={"DD.MM.YYYY"}
            disabled={loading}
            onChange={(eventOrValue) => {
              if (eventOrValue !== undefined) {
                if (eventOrValue.target === undefined) {
                  setFieldValue("endDate", eventOrValue);
                  localStorage.setItem("endDate", eventOrValue);
                } else {
                  setFieldValue("endDate", eventOrValue.target.value);
                  localStorage.setItem("endDate", eventOrValue.target.value);
                }
              }
            }}
          />
        </div>{" "}
        <div>
          { ( mandant === "BAYERN" || mandant === "BAWUE" || mandant === "NDS" ) && (
            <FormControlLabel
              label="Monatsspalten anzeigen" 
              name="showMonthColumns"
              checked={(firstTime === true)? ((localStorage.getItem("showMonthColumns")==='true')?true:false ) : isChecked}
              onChange={handleChangeSwitch}
              control={ <Checkbox  
                color="primary"
              /> 
              } 
            />
          )}     
        </div>
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}
