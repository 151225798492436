import React, { useState, useEffect, useCallback } from 'react'
import ViewTable from '../utils/ViewTable'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import ActionsBar from '../utils/ActionsBar'
import InfoOverlay from '../utils/InfoOverlay'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import { Button as CFButton, SecondaryButton } from '../utils/CFButtons'
import { navigateBack } from '../utils/common'
import { Link } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import CFSnackbarContent from '../utils/CFSnackbarContent'
import Button from '@material-ui/core/Button'
import { getDataFromSmartDesign } from '../utils/api-client'
import FetchLinkedCriterias from './FetchLinkedCriterias'

const tableTheme = () =>
  createTheme({
    overrides: {
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: '#DC001A',
          },
        },
      },
    },
  })
const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacer: {
    height: theme.spacing(8),
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltipPositionFix: {
    display: 'inline-block',
    margin: '0 24px 0 0 ',
  },
})

function StatisticsSelectCriteriaTable(props) {
  const [showDialog, setShowDialog] = useState(false)
  const [prefilledCriterias, setPrefilledCriterias] = useState([])
  const [selectedList, setSelectedList] = useState()
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarType, setSnackbarType] = useState('')
  const [statisticsQueryJSON, setStatisticsQueryJSON] = useState()
  const [displayedCriterialist, setDisplayedCriterialist] = useState()
  const [displayedCriterialistName, setDisplayedCriterialistName] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        if (prefilledCriterias.length === 0) {
          await getQueryList()
        }
      } catch (error) {
        console.log(error)
      } finally {
      }
    })()
  }, [getQueryList, prefilledCriterias.length, statisticsQueryJSON])
  
  const columns = [
    {
      name: 'NAME',
      label: 'Kriterienliste',
      options: {
        customBodyRender: (name, { rowIndex }) => {
          return (
            <Button
              color='primary'
              onClick={() => displayCriterias(prefilledCriterias[rowIndex])}
            >
              {name}
            </Button>
          )
        },
      },
    },
  ]
  const options = {
    selectableRows: 'single',
    search: false,
    textLabels: {
      body: {
        noMatch: 'Leider wurden keine passenden Datensätze gefunden',
        toolTip: 'Sortieren',
      },
      pagination: {
        rowsPerPage: 'Einträge pro Seite',
        displayRows: 'von',
      },
      selectedRows: {
        text: 'Zeile(n) markiert',
        delete: 'Alle markierten Kriterien löschen',
        deleteAria: 'Alle markierten Kriterien löschen',
      },
    },
    onRowsSelect: selectedRow => {
      var selectedValue = prefilledCriterias[selectedRow[0].dataIndex]
      setSelectedList(selectedValue)
      return selectedValue
    },
  }

  async function getQueryList() {
    try {
      return Promise.all(
        (async () => {
          if (statisticsQueryJSON === undefined) {
            setStatisticsQueryJSON(
              await getDataFromSmartDesign('/type/statisticsquerylist/full')
            )
          }
          if (
            statisticsQueryJSON !== undefined &&
            statisticsQueryJSON.length !== 0
          ) {
            await getQueryListJson()
          }
        })()
      )
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  async function getQueryListJson() {
    try {
      return await Promise.all(
        (async () => {
          var result = []
          await statisticsQueryJSON.forEach(async element => {
            let queryList = element.fields
            var linkedCriterias = await FetchLinkedCriterias(queryList.GGUID)
            if (linkedCriterias.length > 0) {
              result.push(queryList)
              setPrefilledCriterias(prefilledCriterias.concat(result))
            }
          })
        })()
      )
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  function displaySnackbar(snackbarType, snackbarMessage) {
    setSnackbarType(snackbarType)
    setSnackbarMessage(snackbarMessage)
    setShowSnackbar(true)
  }
  const displayCriterias = useCallback(
    async queryList => {
      setDisplayedCriterialistName(queryList.NAME)
      setDisplayedCriterialist(await FetchLinkedCriterias(queryList.GGUID))
      setShowDialog(true)
      props.history.replace({
        search: `?showCriterias=${'0x' + queryList.GGUID}`,
      })
    },
    [props.history]
  )
  return (
    <>
      <MuiThemeProvider theme={tableTheme}>
        <ViewTable
          title={'Kriterienliste'}
          data={prefilledCriterias}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <ActionsBar>
        <div>
          <SecondaryButton
            className={styles.actionButtonDanger}
            component={Link}
            to={navigateBack(props.location.pathname)}
          >
            <ArrowBackRoundedIcon />
            Abbrechen
          </SecondaryButton>
          <CFButton
            variant='contained'
            onClick={() =>
              selectedList !== undefined
                ? props.history.push({
                    pathname: navigateBack(props.location.pathname),
                    state: {
                      selectedListGGUID: selectedList.GGUID,
                      selectedListName: selectedList.NAME,
                      keepUser: true,
                      keepCheckbox: true,  
                    },
                  })
                : displaySnackbar(
                    'error',
                    'Bitte wählen Sie die Kriterienliste'
                  )
            }
          >
            <SendRoundedIcon />
            Kriterienliste laden
          </CFButton>
        </div>
      </ActionsBar>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={() => setShowSnackbar(false)}
      >
        <CFSnackbarContent
          onClose={() => setShowSnackbar(false)}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
      <InfoOverlay
        showDialog={showDialog}
        toggleDialog={() => {
          setShowDialog(false)
          props.history.replace({
            search: '',
          })
        }}
        name={displayedCriterialistName}
        data={displayedCriterialist}
      />
    </>
  )
}
export default StatisticsSelectCriteriaTable
