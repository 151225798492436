import React from "react";
import { Switch, Route } from "react-router-dom";

import Address from "./print/addressPrintButton";
import Careplan from "./print/careplanPrintButton";
import Vorgang from "./print/vorgangPrintButton";
import Statistics from "./statistics/StatisticsMainView";
import MainQueryView from "./admin/criterias/MainQueryView";
import StatisticsSelectCriteriaTable from "./statistics/StatisticsSelectCriteriaTable";
import EditCriteriaListMainView from "./admin/EditCriteriaList/EditCriteriaListMainView";
import EditSingleCriteriaListView from "./admin/EditCriteriaList/EditSingleCriteriaListView";
import CreateNewStatisticsQueryList from "./admin/criterias/CreateNewStatisticsQueryList";
import DeleteStatisticsQueryList from "./admin/DeleteCriteriaList/DeleteStatisticsQueryList";
import DeleteSingleStatisticQueryList from "./admin/DeleteCriteriaList/DeleteSingleStatisticQueryList";
import AdminMenu from "./admin/AdminMenu";
import MoveAddress from "./admin/moveAddress/MasterMoveAddress";
import MasterCopyStatistics from "./admin/CopyStatistics/MasterCopyStatistics";
import ExportStatistics from "./admin/ExportStatisticsToOtherMandants/ExportStatistics";
import VersionPage from "./versionpage";
import CheckStatistics from "./statistics/CheckStatistics";
import AddressBroadcastView from "./caseform/broadcast/AddressBroadcastView";
import ICD10View from "./caseform/icd/ICD10View";
import ImportData from "./import/ImportData";
import TimeTrackingTable from "./timetrackingtable/TimeTrackingTableMainView";
import TimePolicyWarning from "./timePolicyWarning/timetrackingTimePolicyWarning";
import AppsTimePolicyWarning from "./timePolicyWarning/appsTimePolicyWarning";
import VerifyProfilesJson from "./VerifyProfilesJson/VerifyProfilesJson";

const Router = () => {
    return (
        <Switch>
            {" "}
            {}
            <Route exact path="/print/vorgang" component={Vorgang}></Route>
            <Route exact path="/print/careplan" component={Careplan}></Route>
            <Route exact path="/print/address" component={Address}></Route>
            <Route exact path="/statistics" component={Statistics}></Route>
            <Route exact path="/timetrackingtable" component={TimeTrackingTable}></Route>
            <Route exact path="/timetracking/vacation" component={TimePolicyWarning}></Route>
            <Route exact path="/timetrackingtable/check" component={AppsTimePolicyWarning}></Route>
            <Route
                path="/statistics/criterias"
                render={(props) => <StatisticsSelectCriteriaTable {...props} />}
            ></Route>
            <Route exact path="/admin" component={AdminMenu}></Route>
            <Route
                path="/admin/criterias/create"
                render={(props) => <MainQueryView {...props} />}
            ></Route>
            <Route
                exact
                path="/admin/criterias/list/create"
                component={CreateNewStatisticsQueryList}
            ></Route>
            <Route
                exact
                path="/admin/criterias/list/delete"
                component={DeleteStatisticsQueryList}
            ></Route>
            <Route
                exact
                path="/admin/criterias/list/delete/single"
                component={DeleteSingleStatisticQueryList}
            ></Route>
            <Route
                exact
                path="/admin/criterias/delete/list"
                render={(props) => <StatisticsSelectCriteriaTable {...props} />}
            ></Route>
            <Route
                path="/admin/criterialists/edit"
                render={(props) => <EditCriteriaListMainView {...props} />}
            ></Route>
            <Route
                path="/admin/criterialists/single/edit"
                render={(props) => <EditSingleCriteriaListView {...props} />}
            ></Route>
            <Route
                path="/admin/address/move"
                render={(props) => <MoveAddress {...props} />}
            ></Route>
            <Route
                path="/admin/statisticsquerylist/move"
                render={(props) => <MasterCopyStatistics {...props} />}
            ></Route>
            <Route
                path="/admin/statisticsquerylists/export"
                render={(props) => <ExportStatistics {...props} />}
            ></Route>
            <Route path="/version" render={(props) => <VersionPage />}></Route>
            <Route
                path="/admin/statistics/check"
                render={(props) => <CheckStatistics {...props} />}
            ></Route>
            <Route
                path="/admin/import/organization"
                render={(props) => <ImportData {...props} />}
            ></Route>
            <Route
                path="/admin/VerifyProfilesJson/VerifyProfilesJson"
                render={(props) => <VerifyProfilesJson {...props} />}
            ></Route>
            <Route path="/caseform/icd" render={(props) => <ICD10View {...props} />}></Route>
            <Route
                path="/caseform/broadcast"
                render={(props) => <AddressBroadcastView {...props} />}
            ></Route>
        </Switch>
    );
};

export default Router;
