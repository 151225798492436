import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import { Button as CFButton } from "../../utils/CFButtons";
import ActionsBar from "../../utils/ActionsBar";
import moment from "moment-timezone";
import "moment/locale/de";
import { SecondaryButton } from "../../utils/CFButtons";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { navigateBack } from "../../utils/common";
import { Link } from "react-router-dom";
moment.locale("de");

export default function EditCriteriaListActionsSection({
    showSnackbar,
    setShowSnackbar,
    snackbarType,
    snackbarMessage,
    displaySnackbar,
    statisticsQueryList,
    props,
}) {
    async function navigateToSingleListView() {
        if (statisticsQueryList === undefined) {
            displaySnackbar("error", "Bitte eine Kriterienliste auswählen");
            return;
        }
        if (statisticsQueryList !== null && statisticsQueryList !== undefined) {
            props.history.push({
                pathname: "/admin/criterialists/single/edit",
                state: {
                    selectedStatisticsQueryList: statisticsQueryList,
                },
            });
        }
    }
    return (
        <>
            <ActionsBar>
                <div>
                    <SecondaryButton
                        component={Link}
                        to={navigateBack(navigateBack(props.location.pathname))}
                    >
                        <ArrowBackRoundedIcon />
                        Zurück
                    </SecondaryButton>
                    <CFButton
                        onClick={() => {
                            navigateToSingleListView();
                        }}
                    >
                        Weiter
                    </CFButton>
                </div>
            </ActionsBar>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={() => setShowSnackbar(false)}
            >
                <CFSnackbarContent
                    onClose={() => setShowSnackbar(false)}
                    variant={snackbarType}
                    message={snackbarMessage}
                />
            </Snackbar>
        </>
    );
}
