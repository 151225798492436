import React, { Component } from "react";
import "./printButton.css";
import { getDataFromSmartDesign, postDataToSmartDesign } from "../utils/api-client";
import moment from "moment-timezone";
import { Button as CFButton } from "../utils/CFButtons";
import { Card, Typography } from "@material-ui/core";
class PrintButton extends Component {
    async postData() {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var gguid = url.searchParams.get("GGUID");
        var loggedUser = url.searchParams.get("loggedUser");
        var currentDate = moment(new Date()).format("DD_MM_YYYY");
        var endpointUrl = "/print/vorgang?gguid=" + gguid + "&loggedUser=" + loggedUser + "&currentDate=" + currentDate;
        var vorgang = await getDataFromSmartDesign("/type/vorgang/" + gguid);
        var filename =
            "Vorgang_" +
            currentDate +
            "_" +
            vorgang.fields.CLIENTNAME.replace(" ", "_") +
            ".pdf";
        await postDataToSmartDesign(endpointUrl).then((response) => {
            var url = undefined;
            var vorgangPDFLink = undefined;
            var firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
            if (firefoxAgent) {
                var vorgangXLSX = new File([response], filename, {
                    type: "application/pdf",
                });
                url = window.URL.createObjectURL(vorgangXLSX);
                vorgangPDFLink = document.createElement("a");
                vorgangPDFLink.href = url;
                window.open(vorgangPDFLink);
            } else {
                url = window.URL.createObjectURL(response);
                vorgangPDFLink = document.createElement("a");
                document.body.appendChild(vorgangPDFLink);
                vorgangPDFLink.href = url;
                vorgangPDFLink.download = filename;
                vorgangPDFLink.click();
            }
        });
    }
    render() {
        return (
            <div id="container" className="divButton">
                <Card style={{ padding: 25 }}>
                    <Typography component="div" variant="h5">
                        Vorgang drucken
                    </Typography>

                    <CFButton
                        variant="contained"
                        style={{ marginTop: 20, minHeight: 55 }}
                        onClick={this.postData}
                    >
                        Drucken
                    </CFButton>
                </Card>
            </div>
        );
    }
}
export default PrintButton;
