const broadcastOptions = [
    {
        label: "Ambulanter Pflegedienst",
        value: "AMBULATORY_AFTERCARE",
    },
    {
        label: "Station\u00E4res Pflegeheim",
        value: "STATIONARY_AFTERCARE",
    },
    {
        label: "Rehaklinik",
        value: "REHABILITATION_CLINIC",
    },
    {
        label: "Akutgeriatrie",
        value: "ACUTE_GERIATRICS",
    },
    {
        label: "Hilfsmittelversorger",
        value: "MEDICAL_AIDS_PROVIDER",
    },
    {
        label: "Tagespflege",
        value: "DAY_CARE",
    },
];

export { broadcastOptions };
