import React, { useState, useEffect } from "react";
import EditCriteriaListActionsSection from "./EditCriteriaListActionsSection";
import moment from "moment";
import ViewTable from "../../utils/ViewTable";
import translate from "../../utils/Translate";
import GetQueryLists from "../criterias/GetQueryLists";
function EditCriteriaListMainView(props) {
    const [loadedCriteriaLists, setLoadedCriteriaLists] = useState([]);
    const [selectedStatisticsQueryList, setSelectedStatisticsQueryList] = useState();
    moment.locale("de");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState("");
    useEffect(() => {
        (async () => {
            try {
                if (loadedCriteriaLists.length === 0) {
                    await GetQueryLists(setLoadedCriteriaLists);
                }
            } catch (error) {
                console.log(error);
            } finally {
            }
        })();
    }, [loadedCriteriaLists.length]);

    function displaySnackbar(snackbarType, snackbarMessage) {
        setSnackbarType(snackbarType);
        setSnackbarMessage(snackbarMessage);
        setShowSnackbar(true);
    }
    const columns = [
        {
            name: "NAME",
            label: "Kriterienliste Name",
        },
        {
            name: "PERIODICITY",
            label: "Periodicity",
            options: {
                customBodyRender: (value) => translate(value),
            },
        },
    ];
    const options = {
        selectableRows: "single",
        search: false,
        textLabels: {
            body: {
                noMatch: "Leider wurden keine passenden Datensätze gefunden",
                toolTip: "Sortieren",
            },
            pagination: {
                rowsPerPage: "Einträge pro Seite",
                displayRows: "von",
            },
            selectedRows: {
                text: "Zeile(n) markiert",
            },
        },
        
        customToolbarSelect: () =>{},
        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            if (allRowsSelected.length > 0) {
                setSelectedStatisticsQueryList(loadedCriteriaLists[allRowsSelected[0].dataIndex]);
            } else {
                setSelectedStatisticsQueryList(null);
            }
        },
    };
    return (
        <>
            <ViewTable
                title={"Bitte wählen Sie eine Kriterienliste"}
                data={loadedCriteriaLists}
                columns={columns}
                options={options}
            />
            <EditCriteriaListActionsSection
                showSnackbar={showSnackbar}
                setShowSnackbar={setShowSnackbar}
                snackbarType={snackbarType}
                snackbarMessage={snackbarMessage}
                displaySnackbar={displaySnackbar}
                statisticsQueryList={selectedStatisticsQueryList}
                props={props}
            />
        </>
    );
}
export default EditCriteriaListMainView;
