import React from "react";
import { useField } from "formik";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    minWidth: 0,
    maxWidth: "100%",
    padding: "0rem 1rem",
    boxShadow: "none",
    margin: "-.27rem 0",
  },
  message: {
    color:theme.palette.error.main,
    display: "flex",
    alignItems: "center",
    padding: "2px 0",
    textAlign: "left",
    fontSize: ".765rem",
    lineHeight: "1.35rem",
  },
}));

/**
 * Caseform Field With Validation
 */
function CFFieldWithValidation({
  as: Component,
  onChange,
  onBlur,
  errorClassName,
  disableFormikChangeHandler = false,
  ...props
}) {
  const [field, meta] = useField(props.name);
  const classes = useStyles();

  function onChangeHandler(...args) {
    if (onChange) {
      if (disableFormikChangeHandler) {
        return onChange(...args);
      }

      onChange(...args);
    }

    return field.onChange(...args);
  }

  function onBlurHandler(...args) {
    if (onBlur) {
      if (disableFormikChangeHandler) {
        return onBlur(...args);
      }

      onBlur(...args);
    }

    return field.onBlur(...args);
  }

  return (
    <>
      {meta.error ? (
        <div>
          <Component error={true}
            {...field}
            {...props}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
          />
          <SnackbarContent
            classes={{
              root: classes.root,
              message: classes.message,
            }}
            {...(errorClassName && { className: errorClassName })}
            message={
              <span className={classes.message}>
                {meta.error}
              </span>
            }
          />
        </div>
      ) :
        <Component 
          {...field}
          {...props}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
        />
      }
    </>
  );
}

export default CFFieldWithValidation;
