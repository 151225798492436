import React from "react";
import { useField, Field } from "formik";

function DateFieldWithValidation({ 
  as: Component,
  errorClassName,
   ...props
}) {
  
  const [field, meta] = useField(props.name);
  return (
    <>
     {meta.error ? (
           <div>
           <Field {...props} ></Field>
         </div>
        ) :(
          <Field {...props} onBlur={() => null}></Field>
        )
      }
    </>
  );
}

export default DateFieldWithValidation;
