import React from "react";
import { Button as CFButton } from "../../utils/CFButtons";
import ActionsBar from "../../utils/ActionsBar";
import { SecondaryButton } from "../../utils/CFButtons";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { navigateBack } from "../../utils/common";
import { Link } from "react-router-dom";
import { Send } from "@material-ui/icons";
import YesNoOverLay from "../../utils/YesNoOverlay";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import { postDataToSmartDesign as client } from "../../utils/api-client";

export default function ExportStatisticsActionSection({
    showConfirmPopup,
    setShowConfirmPopup,
    selectedStatisticsQueryLists,
    setSelectedStatisticsQueryLists,
    setLoadedCriteriaLists,
    loadedMandants,
    setLoadedMandants,
    sourceSelectedMandant,
    setSourceSelectedMandant,
    targetSelectedMandants,
    setTargetSelectedMandants,
    snackbarType,
    setSnackbarType,
    snackbarMessage,
    setSnackbarMessage,
    setShowSnackbar,
    showSnackbar,
    displaySnackbar,
    step,
    setStep,
    setLoading,
    loading,
    props,
}) {
    function next() {
        if (validate()) {
            let currentStep = step;
            currentStep = currentStep >= 2 ? 3 : currentStep + 1;
            setStep(currentStep);
        }
    }

    function previous() {
        cleanStep();
        let currentStep = step;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        setStep(currentStep);
    }

    function cleanStep() {
        switch (step) {
            case 2:
                setSourceSelectedMandant(null);
                setLoadedMandants([]);
                setLoadedCriteriaLists([]);
                setSelectedStatisticsQueryLists(null);
                break;
            case 3:
                setLoadedCriteriaLists([]);
                setSelectedStatisticsQueryLists(null);
                break;
            default:
                break;
        }
    }

    function validate() {
        switch (step) {
            case 1:
                if (sourceSelectedMandant != null && loadedMandants != null && loadedMandants.length > 0) {
                    return true;
                } else {
                    displaySnackbar("error", "You must select a source mandant");
                    return false;
                }
            case 2:
                if (selectedStatisticsQueryLists != null) {
                    return true;
                } else {
                    displaySnackbar("error", "You must select one or more statistics lists");
                    return false;
                }
            case 3:
                if (targetSelectedMandants != null && loadedMandants != null && targetSelectedMandants !== sourceSelectedMandant) {
                    return true;
                } else {
                    displaySnackbar("error", "You must select one or more target mandants");
                    return false;
                }
            default:
                return false;
        }
    }

    function showConfirm() {
        if (validate()) setShowConfirmPopup(true);
    }

    async function sendExportStatistics() {
        setShowConfirmPopup(false);
        setLoading(true);
        try {
            var statisticsGGUIDSList = selectedStatisticsQueryLists.map((a) => a.GGUID);
            await client("/admin/statisticsquerylists/export", {
                sourceMandant: sourceSelectedMandant,
                statisticsGGUIDs: statisticsGGUIDSList,
                targetMandants: targetSelectedMandants,
            });
            displaySnackbar("success", "The statistics lists were exported");
        } catch {
            displaySnackbar(
                "error",
                "An unexpected error occurred and the operation could not be performed"
            );
        } finally {
            setLoading(false);
            previous();
            previous();
        }
    }
    return (
        <>
            <ActionsBar>
                <div>
                    <SecondaryButton
                        component={Link}
                        to={navigateBack(navigateBack(props.location.pathname))}
                        disabled={loading}
                    >
                        <ArrowBackRoundedIcon />
                        Zurück
                    </SecondaryButton>
                    {step !== 1 ? (
                        <SecondaryButton onClick={previous} disabled={loading}>
                            Previous
                        </SecondaryButton>
                    ) : (
                        " "
                    )}
                </div>
                {step < 3 ? <CFButton onClick={next}>Next</CFButton> : " "}
                {step === 3 ? (
                    <CFButton onClick={showConfirm} disabled={loading}>
                        <Send />
                        Send
                    </CFButton>
                ) : (
                    " "
                )}
            </ActionsBar>
            <>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={10000}
                    onClose={() => setShowSnackbar(false)}
                >
                    <CFSnackbarContent
                        onClose={() => setShowSnackbar(false)}
                        variant={snackbarType}
                        message={snackbarMessage}
                    />
                </Snackbar>
            </>
            <YesNoOverLay
                showDialog={showConfirmPopup}
                toggleDialog={() => setShowConfirmPopup(false)}
                title="Check that the values ​​are correct before continuing"
                bodyText={"Source Mandant: " + sourceSelectedMandant}
                bodyText2={
                    "Statistics lists: " +
                    JSON.stringify(
                        selectedStatisticsQueryLists !== "" &&
                            selectedStatisticsQueryLists !== null &&
                            selectedStatisticsQueryLists !== undefined
                            ? selectedStatisticsQueryLists.map((a) => a.NAME)
                            : null
                    )
                }
                bodyText3={"Target Mandants: " + JSON.stringify(targetSelectedMandants)}
                cancelText="Cancel"
                okText="Send"
                onConfirm={sendExportStatistics}
                disabled={loading}
            />
        </>
    );
}
