import { connect } from "@smartdesign/web-api";
import { OperationCanceledException } from "typescript";
// eslint-disable-next-line no-undef
const defaultAPIUrl = window.config.REACT_APP_SERVER_URL_REST;
const apiVersion = window.config.REACT_APP_API_VERSION || "v8.0";
const MALFORMED_CRITERIA_MESSAGE = "Sie haben eine ung\u00FCltige Ansicht ausgew\u00E4hlt.";

async function getDataFromSmartDesign(path) {
    try {
        const response = await (await connect(defaultAPIUrl)).fetch(`${apiVersion}/${path}`);
        return response.json();
    } catch (error) {
        throw error;
    }
}
async function getDataFromSmartDesignResponse(path) {
    try {
        const response = await (await connect(defaultAPIUrl)).fetch(`${apiVersion}/${path}`);
        return response;
    } catch (error) {
        throw error;
    }
}
async function fetchCheckStatistics(path) {
    try {
        const response = await (await connect(defaultAPIUrl)).fetch(`${apiVersion}/${path}`);
        return response.json();
    } catch (error) {
        throw error;
    }
}

async function checkConnectionTo(path) {
    try {
        return await (await connect(defaultAPIUrl)).fetch(`${apiVersion}/${path}`);
    } catch (error) {
        throw error;
    }
}
async function postDataToSmartDesign(path, data) {
    const response = await await (
        await connect(defaultAPIUrl)
    )
        .fetch(`${apiVersion}/${path}`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(function (response) {
            if (response.status !== 200) {
                if (response.status === 400) {
                    alert(MALFORMED_CRITERIA_MESSAGE);
                } else {
                    throw new Error("Error");
                }
            }
            return response.blob();
        })
        .then(function (myBlob) {
            return myBlob;
        });
    return response;
}

async function postDataToSmartDesignResponse(path, data) {
    const response = await await (
        await connect(defaultAPIUrl)
    )
        .fetch(`${apiVersion}/${path}`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(function (response) {
            return response;
        });
    return response;
}

async function putDataToSmartDesign(path, data) {
    const response = await await (
        await connect(defaultAPIUrl)
    )
        .fetch(`${apiVersion}/${path}`, {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                "If-Match": data.ETAG,
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(function (response) {
            if (response.status !== 200) {
                if (response.status === 400) {
                    alert(MALFORMED_CRITERIA_MESSAGE);
                } else {
                    throw new Error("Error");
                }
            }
            return response.blob();
        })
        .then(function (myBlob) {
            return myBlob;
        });
    return response;
}

async function deleteObject(path) {
    try {
        const response = await await (
            await connect(defaultAPIUrl)
        )
            .fetch(`${apiVersion}/${path}`, {
                method: "DELETE", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            })
            .then(function (response) {
                if (response.status !== 200) throw new OperationCanceledException("Error");
                return response.blob();
            })
            .then(function (myBlob) {
                return myBlob;
            });
        return response;
    } catch (error) {
        throw error;
    }
}
async function getUser() {
    localStorage.setItem("user", "");
    try {
      const { fields: user } = await getDataFromSmartDesign("/user/self");
      return user;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return "";
    }
  }
  
export {
    getDataFromSmartDesign,
    getDataFromSmartDesignResponse,
    postDataToSmartDesign,
    deleteObject,
    checkConnectionTo,
    fetchCheckStatistics,
    putDataToSmartDesign,
    getUser,
    postDataToSmartDesignResponse,
};
