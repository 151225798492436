import React, { useState, useEffect, useCallback } from "react";
import FetchAllCriteriaListsFromMandant from "../../utils/FetchAllCriteriaListsFromMandant";

import ViewTable from "../../utils/ViewTable";
import CustomToolbarSelect from "../../utils/CustomToolbarSelect";
import InfoOverlay from "../../utils/InfoOverlay";
import Button from "@material-ui/core/Button";
import FetchLinkedCriterias from "../../statistics/FetchLinkedCriterias";

function CriteriaListTableComponent({
  props,
  setSelectedCriteriaLists,
  setLoadedCriteriaLists,
  loadedCriteriaLists,
  loadCriteriaLists,
  setLoadCriteriaLists,
  multiselection,
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [displayedCriterialistName, setDisplayedCriterialistName] = useState(
    ""
  );
  const [displayedCriterialist, setDisplayedCriterialist] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (loadCriteriaLists) {
          setLoadCriteriaLists(false);
          setLoadedCriteriaLists(await FetchAllCriteriaListsFromMandant());
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    })();
  }, [
    loadedCriteriaLists.length,
    loadCriteriaLists,
    setLoadedCriteriaLists,
    setLoadCriteriaLists,
  ]);

  const columnsCriteriaList = [
    {
      name: "NAME",
      label: "Kriterienliste Name",
      options: {
        customBodyRender: (name, { rowIndex }) => {
          return (
            <Button
              color="primary"
              onClick={() => displayCriterias(loadedCriteriaLists[rowIndex])}
            >
              {name}
            </Button>
          );
        },
      },
    },
    {
      name: "PERIODICITY",
      label: "Periodicity",
    },
  ];

  const optionsCriteriaList = {
    selectableRows: multiselection ? "multiple" : "single",
    search: true,
    textLabels: {
      body: {
        noMatch: "Leider wurden keine passenden Datensätze gefunden",
        toolTip: "Sortieren",
      },
      pagination: {
        rowsPerPage: "Einträge pro Seite",
        displayRows: "von",
      },
      selectedRows: {
        text: "Zeile(n) markiert",
      },
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
      />
    ),
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      var test = allRowsSelected.map((dataIndex) => {
        return dataIndex.dataIndex;
      });
      setSelectedCriteriaLists(test);
    },
  };
  const displayCriterias = useCallback(
    async (queryList) => {
      setDisplayedCriterialistName(queryList.NAME);
      setDisplayedCriterialist(await FetchLinkedCriterias(queryList.GGUID));
      setShowDialog(true);
      props.history.replace({
        search: `?showCriterias=${"0x" + queryList.GGUID}`,
      });
    },
    [props.history]
  );

  return (
    <div>
      <ViewTable
        title={"Criteria Lists"}
        data={loadedCriteriaLists}
        columns={columnsCriteriaList}
        options={optionsCriteriaList}
      />
      <InfoOverlay
        showDialog={showDialog}
        toggleDialog={() => {
          setShowDialog(false);
          props.history.replace({
            search: "",
          });
        }}
        name={displayedCriterialistName}
        data={displayedCriterialist}
      />
    </div>
  );
}
export default CriteriaListTableComponent;
