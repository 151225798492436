import { getDataFromSmartDesign } from "../utils/api-client";

async function FetchLinkedCriterias(GGUID) {
    var result = [];
    var queries = await getDataFromSmartDesign("/type/statisticsquery/full?linked-to=" + GGUID + "&linked-to-type=STATISTICSQUERYLIST");
    queries.forEach(async (element) => {
        result.push(element.fields);
    });
    result.sort((a, b) => a.SORTINDEX - b.SORTINDEX);
    return result;
}

export default FetchLinkedCriterias;