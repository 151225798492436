import { getDataFromSmartDesign } from "./api-client";

async function FetchLinkedCriteriasOfListFromMandant(mandant, GGUID) {
  var result = [];
  var queries = await getDataFromSmartDesign("admin/" + mandant + "/statisticsquerylists/get/" + GGUID);
  queries.forEach(async (element) => {
    result.push(element.fields);
  });
  result.sort((a, b) => a.SORTINDEX - b.SORTINDEX);
  return result;
}

export default FetchLinkedCriteriasOfListFromMandant;
