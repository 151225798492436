import { getDataFromSmartDesign } from "./api-client";
import Translate from "./Translate";

async function FetchAllCriteriaListsFromMandant(sourceSelectedMandant) {
    let statisticsQueryLists;
    if (sourceSelectedMandant === undefined) {
        statisticsQueryLists = await getDataFromSmartDesign("/type/statisticsquerylist/full");
    } else {
        statisticsQueryLists = await getDataFromSmartDesign(
            "/admin/" + sourceSelectedMandant + "/statisticsquerylists/get"
        );
    }
    let result = [];
    statisticsQueryLists.forEach(async (element) => {
        element.fields.PERIODICITY = Translate(element.fields.PERIODICITY);
        result.push(element.fields);
    });
    return result;
}

export default FetchAllCriteriaListsFromMandant;
