import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
export default function YesNoOverLay({
  showDialog,
  toggleDialog,
  title,
  bodyText,
  bodyText2,
  bodyText3,
  cancelText,
  okText,
  onConfirm,
}) {
  return (
    <Dialog open={showDialog} onBackdropClick={toggleDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
        <DialogContentText>{bodyText2}</DialogContentText>
        <DialogContentText>{bodyText3}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog}>{cancelText || "Nein"}</Button>
        <Button onClick={onConfirm} color="primary">
          {okText || "Ja"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
