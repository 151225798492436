import React, { useState } from 'react';
import { Button as CFButton } from "../../utils/CFButtons";
import ActionsBar from "../../utils/ActionsBar";
import { SecondaryButton } from "../../utils/CFButtons";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { navigateBack } from "../../utils/common";
import { Link } from "react-router-dom";
import { Send } from "@material-ui/icons";
import YesNoOverLay from "../../utils/YesNoOverlay";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import { postDataToSmartDesign as client } from "../../utils/api-client";
import InfoOverlay2 from '../../utils/InfoOverlayAddresses';

export default function MoveAddressActionSection({
  showConfirmPopup,
  setShowConfirmPopup,
  selectedAddresses,
  setSelectedAddresses,
  setLoadedAddresses,
  loadedMandants,
  setLoadedMandants,
  sourceSelectedMandant,
  setSourceSelectedMandant,
  targetSelectedMandants,
  setTargetSelectedMandants,
  snackbarType,
  setSnackbarType,
  snackbarMessage,
  setSnackbarMessage,
  setShowSnackbar,
  showSnackbar,
  displaySnackbar,
  step,
  setStep,
  setLoading,
  loading,
  props
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [failedMovingAddresses, setFailedMovingAddresses] = useState([]);

  function next() {
    if (validate()) {
      let currentStep = step
      currentStep = currentStep >= 2 ? 3 : currentStep + 1
      setStep(currentStep)
    }
  }

  function previous() {
    cleanStep();
    let currentStep = step
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    setStep(currentStep)
  }
  function cleanStep() {
    switch (step) {
      case 2:
        setSourceSelectedMandant(null)
        setLoadedMandants([])
        setLoadedAddresses([])
        setSelectedAddresses([])
        break;
      case 3:
        setLoadedAddresses([])
        setSelectedAddresses([])
        break;
      default:
        break;
    }
  }
  function validate() {
    switch (step) {
      case 1:
        if (sourceSelectedMandant != null && loadedMandants != null && loadedMandants.length > 0) {
          return true;
        } else {
          displaySnackbar(
            "error",
            "You must select a source mandant"
          )
          return false;
        }
      case 2:
        if (selectedAddresses !== []) {
          return true;
        } else {
          displaySnackbar(
            "error",
            "You must select an address"
          )
          return false;
        }
      case 3:
        if (targetSelectedMandants != null && loadedMandants != null && targetSelectedMandants !== sourceSelectedMandant) {
          return true;
        } else {
          displaySnackbar(
            "error",
            "You must select a target mandant"
          )
          return false;
        }
      default:
        return false;
    }
  }
  function showConfirm() {
    if (validate())
      setShowConfirmPopup(true)
  }
  
  async function sendMoveAddress() {  
    setShowConfirmPopup(false)
    setLoading(true);
    let auxFailedMovingAddresses=[];
    if (selectedAddresses.length>0){
      for (let element of selectedAddresses){
        try {       
          await client("/admin/address/move", {
            sourceMandant: sourceSelectedMandant,
            addressGGUID: element["GGUID"],
            targetMandant: targetSelectedMandants[0]
          });            
          displaySnackbar(
            "success",
            "The address " + (element["CHRISTIANNAME"] !== undefined? element["CHRISTIANNAME"]:"") + " " + (element["NAME"]!== undefined? element["NAME"]:"") + " was moved"
          );
        } catch {
          displaySnackbar(
            "error",
            "An unexpected error occurred and the operation could not be performed for " + (element["CHRISTIANNAME"] !== undefined? element["CHRISTIANNAME"]:"") + " " + (element["NAME"] !== undefined? element["NAME"]:"")
          );
          let auxElement=JSON.parse('{ "CHRISTIANNAME": "' + (element["CHRISTIANNAME"] !== undefined? element["CHRISTIANNAME"]:"") + '" , "NAME": "' + (element["NAME"] !== undefined? element["NAME"]:"") +'" }');
          auxFailedMovingAddresses.push(auxElement);
        }
      } 
    }
    setLoading(false);
    setFailedMovingAddresses([]);
    if (auxFailedMovingAddresses.length > 0){
      setFailedMovingAddresses(auxFailedMovingAddresses);
      setShowDialog(true);
      props.history.replace({
        search: `?showTargetMandant=${targetSelectedMandants[0]}`,
      });
    }
    previous();
    previous(); 
  }
  return (
    <>
      <ActionsBar>
        <div>
          <SecondaryButton
            component={Link}
            to={navigateBack(navigateBack(props.location.pathname))}
            disabled={loading}
          >
            <ArrowBackRoundedIcon />
            Zurück
          </SecondaryButton>
          {(step !== 1) ?
            <SecondaryButton
              onClick={previous}
              disabled={loading}>
              Previous
            </SecondaryButton>
            : " "
          }
        </div>
        {(step < 3) ?
          <CFButton
            onClick={next}>
            Next
          </CFButton>
          : " "
        }
        {(step === 3) ?
          <CFButton
            onClick={showConfirm}
            disabled={loading}>
            <Send />
            Send
          </CFButton>
          : " "
        }
      </ActionsBar>
      <>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={10000}
          onClose={() => setShowSnackbar(false)}
        >
          <CFSnackbarContent
            onClose={() => setShowSnackbar(false)}
            variant={snackbarType}
            message={snackbarMessage}
          />
        </Snackbar>
      </>
      <YesNoOverLay
        showDialog={showConfirmPopup}
        toggleDialog={() => setShowConfirmPopup(false)}
        title="Check that the values ​​are correct before continuing"
        bodyText={"Source Mandant: " + sourceSelectedMandant}
        bodyText2={"Address:" +  ((selectedAddresses !== undefined && selectedAddresses !== null) ? selectedAddresses.map(function(i,j){return(" " + (j+1) + ") " + (i.CHRISTIANNAME !== undefined? i.CHRISTIANNAME:"") + " " + (i.NAME !== undefined? i.NAME:""))}).join(" ") : "0") }
        bodyText3={"Target Mandant:" + targetSelectedMandants}
        cancelText="Cancel"
        okText="Send"
        onConfirm={sendMoveAddress}
        disabled={loading}
      />
      <InfoOverlay2
        showDialog={showDialog}
        toggleDialog={() => {
          setShowDialog(false)
          props.history.replace({
            search: '',
          })
        }}
        name={(targetSelectedMandants !== undefined && targetSelectedMandants !== null) ? targetSelectedMandants[0] : 'Mandant'}
        data={failedMovingAddresses}
      />
    </>
  );
}
