import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
    MUIDataTableHeadRow: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#DC001A' },
    text: { primary: '#565757' },
  },
})
