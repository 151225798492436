const dataObjectTypes = [
  {
    label: "Kontakt",
    value: "ADDRESS",
  },
  {
    label: "Vorgang",
    value: "VORGANG",
    pluralDE: "Vorgänge",
  },
  {
    label: "Schnellerfassung",
    value: "QUICKDOCUMENTATION",
    pluralDE: "Schnellerfassungen",
  },
  {
    label: "Information",
    value: "INFORMATION",
    pluralDE: "Informationen",
  },
  {
    label: "Netzwerk",
    value: "NETWORK",
    pluralDE: "Netzwerke",
  },
  {
    label: "Fall",
    value: "FALL",
  },
];

export { dataObjectTypes };
