import {  Snackbar, SnackbarContent } from '@material-ui/core'
import React, { Component} from 'react'
import '../caseform/broadcast/broadcastButton.css'
import {
  postDataToSmartDesignResponse,
  getUser
} from '../utils/api-client'



class AppsTimePolicyWarning extends Component {
  constructor() {
    super()

    this.state = {
      snackbarMessage: '',
      snackbarType: '',
      showSnackbar: false,
      open: false,
    }
  }

  onSubmit = () => {
    this.setState({ open: true })
  }

  displaySnackbar = (snackbarType, snackbarMessage) => {
    this.setState({
      snackbarType: snackbarType,
      snackbarMessage: snackbarMessage,
      showSnackbar: true,
    })
  }
  async componentDidMount() {
    console.log('componentDidMount');
    await this.postData();
  }

  getAutoHideDuration = (snackbarType) => {
    switch (snackbarType) {
      case 'error':
        return 50000;
      case 'warning':
        return 10000;
      default:
        return 6000;
    }
  };

async postData() {

  try {
    const currUser = await getUser();
    let userGGUID = currUser.GGUIDOFADDRESSRECORD;
    const endpointUrl = 'timetrackingtable/check';
    const finalUrl = endpointUrl + '?userGuid=' + userGGUID;
    const response = await postDataToSmartDesignResponse(finalUrl);

      if (response.status !== 200){
        let message = 'Bitte füllen Sie ihre Arbeitszeit sowie ihre Urlaubstage in der Zeiterfassung aus. Den Datensatz finden Sie in Ihrem Benutzer. Bei Fragen wenden Sie sich bitte an kundenservice@nubedian.de';
        this.displaySnackbar('error', message);
      }
    } catch (error) {
      console.error("Error displaying snackbar message -> ", error);
    };
 
  }

render() {
  const { showSnackbar, snackbarType, snackbarMessage } = this.state;
  const duration = this.getAutoHideDuration(snackbarType);
  return (
    <div id='container' className='divButton'>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        autoHideDuration= {duration}
        onClose={() => this.setState({ showSnackbar: false })}
      >
        <SnackbarContent
          style={{
            fontSize: '1.2rem',
            backgroundColor: snackbarType === 'error' ? 'red' : 'orange',
          }}
          message={snackbarMessage}
        />
      </Snackbar>
    </div>
  );
}
}
export default AppsTimePolicyWarning
