import React, { useEffect, useCallback } from "react";
import ViewTable from "../../../utils/ViewTable";
import FetchLinkedCriterias from "../../../statistics/FetchLinkedCriterias";
import Button from "@material-ui/core/Button";
import InfoOverlay from "../../../utils/InfoOverlay";
import FetchAllCriteriaListsFromMandant from "../../../utils/FetchAllCriteriaListsFromMandant";
function SelectSourceList({
  props,
  setSelectedStatisticsQueryList,
  setDisplayedCriterialistName,
  displayedCriterialistName,
  displayedCriterialist,
  setDisplayedCriterialist,
  showDialog,
  setShowDialog,
  loadedCriteriaLists,
  setLoadedCriteriaLists,
}) {
  function parseDate(date){
    return date.replace("T"," ").replace(".000Z", "");
  }
  useEffect(() => {
    (async () => {
      try {
        if (loadedCriteriaLists.length === 0) {
          var criteriaLists = await FetchAllCriteriaListsFromMandant();
          criteriaLists.forEach( (criteriaList) => {
            if(criteriaList["LASTSUCCESSFULSYNC"]!==undefined){
              criteriaList["LASTSUCCESSFULSYNC"]=parseDate(criteriaList["LASTSUCCESSFULSYNC"])
            }
          });
          setLoadedCriteriaLists(criteriaLists);
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    })();
  }, [loadedCriteriaLists.length, setLoadedCriteriaLists]);
  const optionsAddress = {
    selectableRows: "single",
    search: true,
    textLabels: {
      body: {
        noMatch: "Leider wurden keine passenden Datensätze gefunden",
        toolTip: "Sortieren",
      },
      pagination: {
        rowsPerPage: "Einträge pro Seite",
        displayRows: "von",
      },
      selectedRows: {
        text: " markiert",
      },
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      if (allRowsSelected.length > 0) {
        setSelectedStatisticsQueryList(
          loadedCriteriaLists[allRowsSelected[0].dataIndex].GGUID
        );
      } else {
        setSelectedStatisticsQueryList(null);
      }
    },
  };
  const displayCriterias = useCallback(
    async (queryList) => {
      setDisplayedCriterialistName(queryList.NAME);
      setDisplayedCriterialist(await FetchLinkedCriterias(queryList.GGUID));
      setShowDialog(true);
      props.history.replace({
        search: `?showCriterias=${"0x" + queryList.GGUID}`,
      });
    },
    [
      props.history,
      setDisplayedCriterialist,
      setDisplayedCriterialistName,
      setShowDialog,
    ]
  );
  const columnsCriteriaList = [
    {
      name: "NAME",
      label: "Kriterienliste Name",
      options: {
        customBodyRender: (name, { rowIndex }) => {
          return (
            <Button
              color="primary"
              onClick={() => displayCriterias(loadedCriteriaLists[rowIndex])}
            >
              {name}
            </Button>
          );
        },
      },
    },
    {
      name: "PERIODICITY",
      label: "Periodicity",
    },
    {
      name: "LASTSUCCESSFULSYNC",
      label: "Last successful sync",
    },
  ];
  return (
    <>
      <div>
        <h3>Kriterienliste auswählen</h3>
        <ViewTable
          title={"Kriterienlisten"}
          data={loadedCriteriaLists}
          columns={columnsCriteriaList}
          options={optionsAddress}
        />
        <InfoOverlay
          showDialog={showDialog}
          toggleDialog={() => {
            setShowDialog(false);
            props.history.replace({
              search: "",
            });
          }}
          name={displayedCriterialistName}
          data={displayedCriterialist}
        />
      </div>
    </>
  );
}
export default SelectSourceList;
