import React from "react";

function VersionPage() {
  return (
    <div>
    <h1>CareCM 2.0 Nubedian Frontend</h1>
    <ul>
        <li>
            <b>Frontend version: </b>{window.config.VERSION_NUMBER}
        </li>
    </ul>
  </div>
  
  );
}
export default VersionPage;
