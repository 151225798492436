import { Card, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import React, { Component } from "react";
import "../caseform/broadcast/broadcastButton.css";
import { getDataFromSmartDesign, postDataToSmartDesign } from "../utils/api-client";
import { Button as CFButton } from "../utils/CFButtons";
import "./printButton.css";
const ERROR_MESSAGE = "something went wrong";
const OK_MESSAGE = "broadcast succeeded";

class PrintButton extends Component {
    constructor() {
        super();

        this.state = {
            snackbarMessage: "",
            snackbarType: "",
            showSnackbar: false,
            open: false,
        };
    }

    onSubmit = () => {
        this.setState({ open: true });
    };

    displaySnackbar = (snackbarType, snackbarMessage) => {
        this.setState({
            snackbarType: snackbarType,
            snackbarMessage: snackbarMessage,
            showSnackbar: true,
        });
    };

    async postData() {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var gguid = url.searchParams.get("GGUID");
        var loggedUser = url.searchParams.get("loggedUser");
        var currentDate = moment(new Date()).format("DD_MM_YYYY");
        var endpointUrl = "/print/address?gguid=" + gguid + "&loggedUser=" + loggedUser + "&currentDate=" + currentDate;
        var address = await getDataFromSmartDesign("/type/address/" + gguid);
        var filename =
            "Kontakt_" +
            currentDate +
            "_" +
            address.fields.CHRISTIANNAME +
            "_" +
            address.fields.NAME +
            ".pdf";
        await postDataToSmartDesign(endpointUrl).then((response) => {
            var url = undefined;
            var kontaktPDFLink = undefined;
            var firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
            if (firefoxAgent) {
                var kontaktXLSX = new File([response], filename, {
                    type: "application/pdf",
                });
                url = window.URL.createObjectURL(kontaktXLSX);
                kontaktPDFLink = document.createElement("a");
                kontaktPDFLink.href = url;
                window.open(kontaktPDFLink);
            } else {
                url = window.URL.createObjectURL(response);
                kontaktPDFLink = document.createElement("a");
                document.body.appendChild(kontaktPDFLink);
                kontaktPDFLink.href = url;
                kontaktPDFLink.download = filename;
                kontaktPDFLink.click();
            }
        });
    }

    handleClose = (selectedBroadcastOption) => {
        this.setState({ open: false });

        if (selectedBroadcastOption) {
            const url_string = window.location.href;
            const url = new URL(url_string);

            (async () => {
                try {
                    const gguid = url.searchParams.get("GGUID");
                    const endpointUrl =
                        "/caseform/postBroadcast?gguid=" +
                        gguid +
                        "&selectedBroadcastOption=" +
                        selectedBroadcastOption;
                    const response = await postDataToSmartDesign(endpointUrl);
                    if (response.status !== 200) {
                        this.displaySnackbar("success", OK_MESSAGE);
                    }
                } catch (e) {
                    this.displaySnackbar("error", ERROR_MESSAGE);
                }
            })();
        }
    };

    render() {
        return (
            <div id="container" className="divButton">
                <Card style={{ padding: 25 }}>
                    <Typography component="div" variant="h5">
                        Kontakt drucken
                    </Typography>
                    <CFButton
                        variant="contained"
                        style={{ marginTop: 20, minHeight: 55 }}
                        onClick={this.postData}
                    >
                        Drucken
                    </CFButton>
                </Card>
            </div>
        );
    }
}
export default PrintButton;
