import { CircularProgress } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ViewTable from '../utils/ViewTable'
const tableTheme = () =>
  createTheme({
    overrides: {
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: '#DC001A',
          },
        },
      },
    },
  })
const Table = ({
  data,
  isLoading,
  title,
  columns,
  options,
}) => {
  
  return (
    <MuiThemeProvider theme={tableTheme}>
      {isLoading ? (
        <CircularProgress
          style={{ display: 'flex', margin: 'auto', padding: 40 }}
        />
      ) : (
        <ViewTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </MuiThemeProvider>
  )
}

export default Table
