import React from "react";
import ViewTable from "../utils/ViewTable";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import translate from "../utils/Translate";
const tableTheme = () =>
    createTheme({
        overrides: {
            MuiCheckbox: {
                colorPrimary: {
                    "&$checked": {
                        color: "#DC001A",
                    },
                },
            },
        },
    });
export default function StatisticsTable({
    loadedCriterias,
    setSelectedDraftIdx,
    setShowDiscardPopup,
    loading,
}) {
    const columns = [
        {
            name: "NAME",
            label: "Titel",
        },
        {
            name: "TAB",
            label: "Tabelle",
            options: {
                customBodyRender: (type) => translate(type),
            },
        },
    ];
    const options = {
        selectableRows: "multiple",
        search: false,
        textLabels: {
            body: {
                noMatch: "Leider wurden keine passenden Datensätze gefunden",
                toolTip: "Sortieren",
            },
            pagination: {
                rowsPerPage: "Einträge pro Seite",
                displayRows: "von",
            },
            selectedRows: {
                text: "Zeile(n) markiert",
                delete: "Alle markierten Kriterien löschen",
                deleteAria: "Alle markierten Kriterien löschen",
            },
        },
        onRowsDelete: ({ data }) => {
            if (loading === false) {
                setSelectedDraftIdx(data.map(({ dataIndex }) => dataIndex));
                setShowDiscardPopup(true);
                return true;
            }
            return false;
        },
    };
    return (
        <MuiThemeProvider theme={tableTheme}>
            <ViewTable
                title={"Ausgewählte Kriterien"}
                data={loadedCriterias}
                columns={columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
