import DateFieldWithValidation from "../utils/DateFieldWithValidation";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import { DatePicker } from "formik-material-ui-pickers";
import { getUser } from "../utils/api-client";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { getDataFromSmartDesign, postDataToSmartDesign,postDataToSmartDesignResponse } from "../utils/api-client";
import { useFormikContext } from "formik";
import {
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';

export default function TimeTrackingTableInput({
  setFieldValue,
  values,
  loading,
  keepCheckbox,
  setUser,
  user,
  setStartDate,
  setEndDate,
  setLoadedTimeTrackings,
  setBalance,
  setTimeAlreadyWorked,
  setTimeToBePerformed,
  setPrintTable

}) {
  moment.locale("de");
  const useStyles = makeStyles((theme) => ({
    formControlRow: {
      margin: theme.spacing(2),
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      [theme.breakpoints.down(50 + theme.spacing(3) * 2)]: {
        margin: theme.spacing(1),
      },
      marginBottom: 150,
    },
    valueTypography: {
      fontWeight: 700,
      fontSize: 16,
      margin: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        margin: theme.spacing(1),
        fontSize: 16,
      },
    },
    formControl: {
      margin: theme.spacing(2),
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        margin: theme.spacing(1),
      },
    },
    textField: {
      width: 500,
      marginBottom: theme.spacing(0.25),
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        width: "55vw",
        marginRight: 0,
        marginBottom: theme.spacing(1),
      },
    },
    errorMargin: {
      marginRight: 75,
      [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
        width: "82.5vw",
        marginRight: 0,
      },
    },
    actionButton: {
      fontWeight: 700,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      boxShadow: "none",
      textTransform: "none",
      borderRadius: 3,
      fontSize: 14,
      width: 125,
      lineHeight: 1.2,
      color: theme.palette.primary.main,
      padding: "2px 15px",
      border: "2px solid",
      backgroundColor: "transparent",
      borderColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "#FFFFFF",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
      },
    },
    root: {
      backgroundColor: "transparent",
      minWidth: 0,
      maxWidth: "100%",
      padding: "0rem 1rem",
      boxShadow: "none",
      margin: "-.27rem 0",
    },
    message: {
      color: theme.palette.error.main,
      display: "flex",
      alignItems: "center",
      padding: "2px 0",
      textAlign: "left",
      fontSize: ".765rem",
      lineHeight: "1.35rem",
    },
  }));
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [snackbarVacationErrorOpen, setSnackbarVacationErrorOpen] = useState(false);
  const [snackbarVacationErrorMessage, setSnackbarVacationErrorMessage] = useState('');
  const [snackbarVacationWarningOpen, setSnackbarVacationWarningOpen] = useState(false);
  const [snackbarVacationWarningMessage, setSnackbarVacationWarningMessage] = useState('');
  const { handleSubmit } = useFormikContext();
  const [userData, setUserData] = React.useState(null);

  useEffect(() => {
    
    async function fetchUserData() {
      try {
        const currUser = await getUser();
        setUserData(currUser);
        setFieldValue("user", currUser.DISPLAYNAME);
        setUser(currUser.DISPLAYNAME);
        setStartDate(moment(values.startDate));
        setEndDate(moment(values.endDate));
        refreshTable(moment(values.startDate), moment(values.endDate), currUser.DISPLAYNAME);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    async function checkIfFilledWorkingHours() {
      try {
        const { fields: user2 } = await getDataFromSmartDesign("/user/self");
        let userGGUID = user2.GGUIDOFADDRESSRECORD;
        await postDataToSmartDesignResponse("timetrackingtable/check?userGuid=" + userGGUID).then(response => {
          if (response.status == 204) {
            setSnackbarMessage('Bitte füllen Sie ihre Arbeitszeit sowie ihre Urlaubstage in der Zeiterfassung aus. Den Datensatz finden Sie in Ihrem Benutzer. Bei Fragen wenden Sie sich bitte an kundenservice@nubedian.de');
            setSnackbarOpen(true);
          }
        })
      } catch (error) {
        console.error("Error checking if user put their WORKHOURS and DAYSOFF -> ", error);
      }
    }

    checkVacationCount()
    checkIfFilledWorkingHours();
    fetchUserData();
  }, []);

  function processDates(startDate, endDate) {
    const startYear = moment(startDate).year();
    const endYear = moment(endDate).year();

    if (startYear === endYear) {
        return [`${startYear}`];
    } else if (endYear - startYear === 1 && moment(startDate).isBefore(endDate)) {
        return [`${startYear}`, `${endYear}`];
    } else if (endYear - startYear > 1) {
        const yearList = [];
        for (let i = startYear; i <= endYear; i++) {
            yearList.push(`${i}`);
        }
        return yearList;
    }
}
  async function checkVacationCount(start, end) {
    try {
      const { fields: user2 } = await getDataFromSmartDesign("/user/self");
      let userGGUID = user2.GGUIDOFADDRESSRECORD;
      let displayName= user2.DISPLAYNAME
      const yearsList = processDates(start, end);

      const endpointUrl = 'timetracking/vacation';
      const queryParams = [
          `userGuid=${userGGUID}`,
          `userName=${displayName}`,
          `years=${yearsList.join(',')}`
      ];
      
      const finalUrl = endpointUrl + '?' + queryParams.join('&');
      var statusVar;
      await postDataToSmartDesignResponse(finalUrl).then(response => {
        statusVar = response.status;
        return response.json();
      }).then(data => {
        const errorMessages = [];
        const warningMessages = [];
        if (statusVar === 200){
          if (Array.isArray(data) && data.length > 0) {
            data.forEach(dataObj => {
              if (dataObj.isWithinBounds === 'false' && !snackbarOpen) {
                errorMessages.push(
                  `Für das Jahr ${dataObj.year}: Derzeit haben Sie ${dataObj.currentVacationCount} Urlaubstage beantragt, während das Maximum ${dataObj.allowedVacationCount} beträgt.`
                );
              } else if (dataObj.isWithinBounds === 'true' && (dataObj.currentVacationCount === dataObj.allowedVacationCount) && !snackbarOpen) {
                warningMessages.push(`Für das Jahr ${dataObj.year}: Sie haben bereits alle Ihre freien Tage genutzt.`);
              }
            });
            if (errorMessages.length > 0) {
              const errorMessage = errorMessages.join(' ');
              setSnackbarVacationErrorMessage(errorMessage);
              setSnackbarVacationErrorOpen(true);
              setSnackbarVacationWarningOpen(false);
            } else if (warningMessages.length > 0) {
              const warningMessage = warningMessages.join(' ');
              setSnackbarVacationWarningMessage(warningMessage);
              setSnackbarVacationWarningOpen(true);
              setSnackbarVacationErrorOpen(false);
            }
          } else {
            console.log("No valid data found in the array");
          }
        }
      })
      
    } catch (error) {
      console.error("Error checking if user has enough vacations -> ", error);
    }
  }
  async function refreshTable(start, end, user) {
    try {
      const [sdate, edate] = [moment(start).utc(), moment(end).utc()];
      sdate.set({ hour: 23, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'day');
      edate.set({ hour: 22, minute: 59, second: 59, millisecond: 999 });
      const isostart = sdate.toISOString();
      const isoend = edate.toISOString();
  
      if (isostart <= isoend) {
        await fetchTimeTrackings(user, isostart, isoend);
      } else {
        setLoadedTimeTrackings([]);
        setTimeAlreadyWorked("00:00");
      }
    } catch (error) {
      console.error("Error in 'refreshTable' function:", error);
    }
  }
  
  async function fetchTimeTrackings(user, start, end) {
    try {
      const response = await postDataToSmartDesign("timetrackingtable/timetrackings", {
        userName: user,
        startDate: start,
        endDate: end
      });
      const responseText = await response.text();
      const dataArray = JSON.parse(responseText);
      if (Array.isArray(dataArray) && dataArray.length === 0) {
        console.log("No timetrackings in given period");
        setTimeAlreadyWorked("00:00");
        setTimeToBePerformed("00:00");
        setBalance("00:00");
        fillPrint(dataArray);
        setLoadedTimeTrackings(dataArray);
        await fetchTimeTrackingUserHours(user, start, end);
        return;
      }
      await fetchTimeTrackingUserHours(user, start, end);
      fillPrint(dataArray);
      setLoadedTimeTrackings(dataArray);
    } catch (error) {
      console.error("Error while fetching timetrackings");
    } 
  }

  async function fetchTimeTrackingUserHours(user, start, end) {
    try {
      const response = await postDataToSmartDesign("timetrackingtable/timetrackinghours", {
        userName: user,
        startDate: start,
        endDate: end
      });
      const responseText = await response.text();
      const hoursData = JSON.parse(responseText);
      var { timeAlreadyWorked, timeToBePerformed, balance } = hoursData;
      setTimeAlreadyWorked(timeAlreadyWorked);
      setTimeToBePerformed(timeToBePerformed);
      setBalance(balance);
    } catch (error) {
      console.error("Error while fetching time trackings hours");
      return {};
    } 
  }

 

  function fillPrint(printData) {
    const dailyReports = {};
    printData.forEach(entry => {
      const date = entry.date;
      const durationParts = entry.duration.split(":");
      const durationMinutes = parseInt(durationParts[0]) * 60 + parseInt(durationParts[1]);
      if (entry.vacation || entry.illness || entry.maternityLeave || entry.holidays) {
        return;
      }
      let report = dailyReports[date];
      if (!report) {
        report = {
          date: date,
          workhours: "00:00",
          breakhours: "00:00"
        };
        dailyReports[date] = report;
      }
  
      if (entry.break) {
        report.breakhours = addDurations(report.breakhours, durationMinutes);
      } else {
        report.workhours = addDurations(report.workhours, durationMinutes);
      }
    });
    setPrintTable(Object.values(dailyReports));
  }
  
  function addDurations(duration1, duration2) {
    const hours1 = parseInt(duration1.split(":")[0]);
    const minutes1 = parseInt(duration1.split(":")[1]);
  
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = totalMinutes1 + duration2;
  
    const hours2 = Math.floor(totalMinutes2 / 60);
    const minutes2 = totalMinutes2 % 60;
  
    return `${hours2.toString().padStart(2, '0')}:${minutes2.toString().padStart(2, '0')}`;
  }

  return (
    <FormControl component="div">
      <MuiPickersUtilsProvider utils={MomentUtils} locale="de" moment={moment}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ paddingTop: '16px', paddingLeft: '16px', paddingBottom: '16px' }}>
            <TextField
              label="Benutzer"
              value={userData ? userData.DISPLAYNAME : 'Error'}
              style={{ width: 300 }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </div>
          <div className={classes.formControl}>
            <DateFieldWithValidation
              component={DatePicker}
              name="startDate"
              label="von"
              className={classes.textField}
              inputVariant="outlined"
              cancelLabel="Abbrechen"
              format={"DD.MM.YYYY"}
              disabled={loading}
              onChange={(eventOrValue) => {
                if (eventOrValue !== undefined) {
                  if (eventOrValue.target === undefined) {
                    setStartDate(eventOrValue);
                    setFieldValue("startDate", eventOrValue);
                    refreshTable(eventOrValue, moment(values.endDate), user);
                    checkVacationCount(eventOrValue, moment(values.endDate));
                    localStorage.setItem("startDateTT", eventOrValue);
                  } else {
                    setStartDate(eventOrValue.target.value);
                    setFieldValue("startDate", eventOrValue.target.value);
                    localStorage.setItem("startDateTT", eventOrValue.target.value);
                  }
                  handleSubmit();
                }
              }}
            />
          </div>{" "}
          <div className={classes.formControl}>
            <DateFieldWithValidation
              component={DatePicker}
              name="endDate"
              label="bis"
              className={classes.textField}
              inputVariant="outlined"
              cancelLabel="Abbrechen"
              format={"DD.MM.YYYY"}
              disabled={loading}
              onChange={(eventOrValue) => {
                if (eventOrValue !== undefined) {
                  if (eventOrValue.target === undefined) {
                    setEndDate(eventOrValue);
                    setFieldValue("endDate", eventOrValue);
                    localStorage.setItem("endDateTT", eventOrValue);
                    refreshTable(moment(values.startDate), eventOrValue, user);
                    checkVacationCount(moment(values.startDate), eventOrValue);
                  } else {
                    setEndDate(eventOrValue.target.value);
                    setFieldValue("endDate", eventOrValue.target.value);
                    localStorage.setItem("endDateTT", eventOrValue.target.value);
                  }
                  handleSubmit();
                }
              }}
            />
          </div>{" "}
        </div>
      </MuiPickersUtilsProvider>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={50000}
        style={{ width: '2200px', maxWidth: '90vw' }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'orange', fontSize: '18px' }}
          message={snackbarMessage}
        />
      </Snackbar>
      <Snackbar
        open={snackbarVacationErrorOpen}
        autoHideDuration={50000}
        style={{ width: '2200px', maxWidth: '90vw' }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'red', fontSize: '18px' }}
          message={snackbarVacationErrorMessage}
        />
      </Snackbar>
      <Snackbar
        open={snackbarVacationWarningOpen}
        autoHideDuration={10000}
        style={{ width: '2200px', maxWidth: '90vw' }}
      >
        <SnackbarContent
          style={{ backgroundColor: 'orange', fontSize: '18px' }}
          message={snackbarVacationWarningMessage}
        />
      </Snackbar>
    </FormControl>
  );
}
