import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
const filter = createFilterOptions({ stringify: (option) => option.label });
function func(state){
    if(state!==null &&  state!==undefined ){
        return {label: state, value: state}
    } 
    return null;
}
function CFAutocomplete({ label = "", options = [],state, error, errorClassName, ...props }) {
   
    
    return (
        <Autocomplete
        {...props}
        options={options}
        error={error}
        defaultValue={
            func(state)
        }
        errorClassName={errorClassName}
        renderInput={(params) => (
            <TextField {...params} label={label} error={error} errorClassName={errorClassName} margin="normal" variant="outlined" fullWidth />
        )}
        renderOption={
            (option) => option.label
        }
        getOptionLabel={
            (option) => {if(option!==0 && option!=="0" && option.label!==undefined && option.label!==null) {
                return  option.label
            }}
        }
        
        filterOptions={(options, params) => {
            const filtered = filter(options, params);
            return filtered;
        }}
        />
    );
}
export default CFAutocomplete;