import React, { useState } from "react";
import CopyStatisticsActionSection from "./CopyStatisticsActionSection";
import SelectSourceList from "./steps/SelectSourceList";
import LoadingSpinner from "../../utils/LoadingSpinner";
function MasterCopyStatistics(props) {
  const [selectedStatisticsQueryList, setSelectedStatisticsQueryList] = useState("");
  const [displayedCriterialist, setDisplayedCriterialist] = useState();
  const [displayedCriterialistName, setDisplayedCriterialistName] = useState();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [loadedCriteriaLists, setLoadedCriteriaLists] = useState([]);
  function displaySnackbar(snackbarType, snackbarMessage) {
    setSnackbarType(snackbarType);
    setSnackbarMessage(snackbarMessage);
    setShowSnackbar(true);
  }
  return (
    <>
      {loading && <LoadingSpinner />}
        <SelectSourceList
          props={props}
          setSelectedStatisticsQueryList={setSelectedStatisticsQueryList}
          setDisplayedCriterialistName={setDisplayedCriterialistName}
          displayedCriterialistName={displayedCriterialistName}
          displayedCriterialist={displayedCriterialist}
          setDisplayedCriterialist={setDisplayedCriterialist}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          loadedCriteriaLists={loadedCriteriaLists}
          setLoadedCriteriaLists={setLoadedCriteriaLists}
        />
      <CopyStatisticsActionSection
        snackbarType={snackbarType}
        setSnackbarType={setSnackbarType}
        snackbarMessage={snackbarMessage}
        setSnackbarMessage={setSnackbarMessage}
        setShowSnackbar={setShowSnackbar}
        showSnackbar={showSnackbar}
        displaySnackbar={displaySnackbar}
        setLoading={setLoading}
        loading={loading}
        step={step}
        setStep={setStep}
        selectedStatisticsQueryList={selectedStatisticsQueryList}
        props={props}
      />
    </>
  );
}
export default MasterCopyStatistics;
