const missingPropsOps = [
  {
    key: "EIM",
    value: "EIM interface is not being created properly.",
  },
  {
    key: "smtp",
    value: "Invalid SMTP credentials",
  },
  {
    key: "mandant",
    value: "No mandants available. Please, check your global account credentials.",
  },
  {
    key: "logsFolder",
    value: "Directory specified for logs not found.",
  },
  {
    key: "criteriaList",
    value: "Criteria list is corrupted in this mandant.",
  },
  {
    key: "emptyRecipient",
    value: "There is no recipient.",
  },
  {
    key: "templatesFolderIssue",
    value: "Directory specified for templates not found.",
  },
  {
    key: "core.properties",
    value: "Some creds are missing in the core.properties file: ",
  },
  {
    key: "recipients",
    value: "specialRecipients.json not found.",
  },
];

export default missingPropsOps;
