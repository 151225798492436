const userFieldValues = [
  {
    label: "Erzeugt von",
    value: "OWNERNAME",
  },
  {
    label: "Geändert von",
    value: "UPDATEUSER",
  },
  {
    label: "Verantwortlicher",
    value: "RESPONSIBLEUSER",
  },
  {
    label: "CareCM1: Erzeugt von",
    value: "CM1CREATIONUSER",
  },
];

export { userFieldValues };
