import React, { useState, useEffect } from "react";
import moment from "moment";
import ViewTable from "../../utils/ViewTable";
import translate from "../../utils/Translate";
import FetchLinkedCriterias from "../../statistics/FetchLinkedCriterias";
import CFFieldWithValidation from "../../utils/CFFieldWithValidation";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import MenuItem from "@material-ui/core/MenuItem";
import { translations } from "../../../resources/translations";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button as CFButton } from "../../utils/CFButtons";
import ActionsBar from "../../utils/ActionsBar";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { SecondaryButton } from "../../utils/CFButtons";
import { putDataToSmartDesign as client, deleteObject } from "../../utils/api-client";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import LoadingSpinner from "../../utils/LoadingSpinner";
import YesNoOverLay from "../../utils/YesNoOverlay";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { withRouter } from "react-router";
import { getDataFromSmartDesign } from "../../utils/api-client";

function EditSingleCriteriaListView(props) {
    const PERIODICITY_YEARLY_VALUE = "A46C6239CEF14680948335FCAAA39FE3";
    const PERIODICITY_MONTHLY_VALUE = "A46C6239CEF14680948335FCAAA39FE1";
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState("");
    const [linkedQueries, setLinkedQueries] = useState();
    const [savedAsWord, isSavedAsWord] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [queriesToDelete, setQueriesToDelete] = useState([]);
    const [mandant, setMandant] = useState("");

    moment.locale("de");

    useEffect(() => {
        (async () => {
            try {
                const response = await getDataFromSmartDesign("/admin/licences/get");
                setMandant(response.mandant);
                let selectedStatisticsQueryList = props.location.state.selectedStatisticsQueryList;
                setFormikInitialState({
                    name: selectedStatisticsQueryList.NAME,
                    periodicity: selectedStatisticsQueryList.PERIODICITY,
                    isSavedAsWord: selectedStatisticsQueryList.ISSAVEDASWORD,
                });
                isSavedAsWord(selectedStatisticsQueryList.ISSAVEDASWORD);
                await loadCriterias(selectedStatisticsQueryList);
            } catch (error) {
                console.log(error);
                alert("Something went wrong. Please, contact the CareCM 2.0 team");
            } finally {
            }
        })();
    }, []);

    function displaySnackbar(snackbarType, snackbarMessage) {
        setSnackbarType(snackbarType);
        setSnackbarMessage(snackbarMessage);
        setShowSnackbar(true);
    }

    async function saveQueryList(values) {
        if (mandant !== "RLP") {
            values.periodicity = PERIODICITY_MONTHLY_VALUE;
        }
        if (
            formikInitialState.name !== values.name ||
            formikInitialState.periodicity !== values.periodicity ||
            formikInitialState.isSavedAsWord !== savedAsWord
        ) {
            try {
                let selectedStatisticsQueryList = props.location.state.selectedStatisticsQueryList;
                setLoading(true);
                var date = new Date(selectedStatisticsQueryList.UPDATETIMESTAMP);
                if (date.getTimezoneOffset() > 0) {
                    date = new Date(selectedStatisticsQueryList.UPDATETIMESTAMP).addMinutes(
                        date.getTimezoneOffset()
                    );
                }
                var unixTimestamp = moment(date).unix();
                let temp = unixTimestamp + "000;" + selectedStatisticsQueryList.UPDATEUSER;
                let etag = '"' + window.btoa(temp) + '"';
                await client("/type/statisticsquerylist/" + selectedStatisticsQueryList.GGUID, {
                    ETAG: etag,
                    fields: {
                        name: values.name,
                        periodicity: values.periodicity,
                        isSavedAsWord:
                            values.periodicity === PERIODICITY_YEARLY_VALUE ? savedAsWord : false,
                    },
                });
                displaySnackbar(
                    "success",
                    "Die ausgewählte Kriterienliste würde erfolgreich gespeichert"
                );
                setLoading(false);
                setTimeout(() => {
                    props.history.push("/admin/criterialists/edit");
                }, 1000);
            } catch (error) {
                alert(error);
                setLoading(false);
            }
        }
        return;
    }
    async function deleteCriterias() {
        try {
            let selectedStatisticsQueryList = props.location.state.selectedStatisticsQueryList;
            setShowDeletePopup(false);
            setLoading(true);
            for (var queryToDelete of queriesToDelete) {
                await deleteObject(
                    "/admin/delete/link/criteria?listGGUID=" +
                    selectedStatisticsQueryList.GGUID +
                    "&criteriaGGUID=" +
                    queryToDelete.GGUID
                );
            }
            displaySnackbar("success", "Die ausgewählte Kriterien würde erfolgreich gelöscht");
            setLoading(false);
            await loadCriterias(selectedStatisticsQueryList);
        } catch (error) {
            alert(error);
            setLoading(false);
        }
    }
    async function loadCriterias(selectedStatisticsQueryList) {
        let test = await FetchLinkedCriterias(selectedStatisticsQueryList.GGUID);
        setLinkedQueries(test);
    }

    async function addNewCriteria() {
        try {
            props.history.push({
                pathname: "/admin/criterias/create",
                state: {
                    selectedStatisticsQueryList: props.location.state.selectedStatisticsQueryList,
                },
            });
        } catch (error) {
            alert(error);
        }
    }

    const columns = [
        {
            name: "NAME",
            label: "Titel",
        },
        {
            name: "TAB",
            label: "Tabelle",
            options: {
                customBodyRender: (type) => translate(type),
            },
        },
    ];
    const options = {
        selectableRows: "multiple",
        search: true,
        textLabels: {
            body: {
                noMatch: "Leider wurden keine passenden Datensätze gefunden",
                toolTip: "Sortieren",
            },
            pagination: {
                rowsPerPage: "Einträge pro Seite",
                displayRows: "von",
            },
            selectedRows: {
                text: "Zeile(n) markiert",
                delete: "Alle markierten Kriterien löschen",
                deleteAria: "Alle markierten Kriterien löschen",
            },
        },
        onRowsDelete: ({ data }) => {
            setQueriesToDelete([]);
            setQueriesToDelete(
                data.map((dataIndex) => {
                    return linkedQueries[dataIndex.dataIndex];
                })
            );
            setShowDeletePopup(true);
        },
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                paddingLeft: theme.spacing(0),
            },
        },
        textField: {
            marginRight: 75,
            width: 275,
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
                marginRight: 0,
            },
        },
        formControlRow: {
            margin: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        formControlFull: {
            margin: theme.spacing(2),
            width: "100%",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        wideControl: {
            width: 350,
            [theme.breakpoints.down(400 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
            },
        },
        formControl: {
            margin: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
    }));
    const classes = useStyles();
    const [formikInitialState, setFormikInitialState] = useState({
        name: "",
        periodicity: "",
    });

    const statisticsSchema = Yup.object().shape({
        name: Yup.string().required("Dies ist ein Pflichtfeld"),
        periodicity: Yup.string().required("Dies ist ein Pflichtfeld"),
    });
    return (
        <Formik
            validationSchema={statisticsSchema}
            initialValues={formikInitialState}
            enableReinitialize
        >
            {({ values, handleSubmit, isValid }) => (
                <>
                    {loading && <LoadingSpinner />}
                    <FormControl component="fieldset" className={classes.formControlRow}>
                        <CFFieldWithValidation
                            as={TextField}
                            name="name"
                            label={"Name der Kriterienliste"}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            errorClassName={classes.errorMargin}
                        />
                        {mandant === "RLP" && (
                            <CFFieldWithValidation
                                as={TextField}
                                select
                                name="periodicity"
                                label="Regelmäßigkeit"
                                className={classes.textField}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                    IconComponent: KeyboardArrowDownRoundedIcon,
                                }}
                                margin="normal"
                                variant="outlined"
                                errorClassName={classes.errorMargin}
                            >
                                {translations
                                    .filter((element) => element.group === "PERIODICITY")
                                    .map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                            </CFFieldWithValidation>
                        )}
                        {values.periodicity !== undefined &&
                            values.periodicity === PERIODICITY_YEARLY_VALUE && (
                                <FormControlLabel
                                    className={classes.wideControl}
                                    checked={savedAsWord}
                                    onChange={(_, checked) => {
                                        isSavedAsWord(checked);
                                    }}
                                    label="Das Ergebnis als Word Dokument speichern"
                                    control={<Checkbox color="primary" />}
                                />
                            )}
                    </FormControl>

                    {
                        <Snackbar
                            open={showSnackbar}
                            autoHideDuration={10000}
                            onClose={() => setShowSnackbar(false)}
                        >
                            <CFSnackbarContent
                                onClose={() => setShowSnackbar(false)}
                                variant={snackbarType}
                                message={snackbarMessage}
                            />
                        </Snackbar>
                    }
                    <ViewTable
                        title={"Verknüpfte Kriterien"}
                        data={linkedQueries}
                        columns={columns}
                        options={options}
                    />
                    {
                        <ActionsBar>
                            <div>
                                <SecondaryButton
                                    className={classes.actionButtonDanger}
                                    onClick={() => props.history.push("/admin/criterialists/edit")}
                                >
                                    <ArrowBackRoundedIcon />
                                    Zurück
                                </SecondaryButton>
                                <CFButton
                                    variant="contained"
                                    onClick={() => {
                                        addNewCriteria();
                                    }}
                                >
                                    <AddRoundedIcon />
                                    Das neue Kriterium erstellen
                                </CFButton>
                            </div>
                            <div>
                                <CFButton
                                    variant="contained"
                                    onClick={() => {
                                        handleSubmit();
                                        saveQueryList(values);
                                    }}
                                >
                                    <SendRoundedIcon />
                                    Änderungen speichern
                                </CFButton>
                            </div>
                        </ActionsBar>
                    }
                    <YesNoOverLay
                        showDialog={showDeletePopup}
                        toggleDialog={() => setShowDeletePopup(false)}
                        title="Check if the values ​​are correct before continuing"
                        bodyText={JSON.stringify(
                            queriesToDelete !== "" &&
                                queriesToDelete !== null &&
                                queriesToDelete !== undefined
                                ? queriesToDelete.map((a) => a.NAME)
                                : null
                        )}
                        cancelText="Abbrechen"
                        okText="Löschen"
                        onConfirm={deleteCriterias}
                        disabled={loading}
                    />
                </>
            )}
        </Formik>
    );
}
export default withRouter(EditSingleCriteriaListView);
