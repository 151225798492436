import React, { useEffect, useCallback } from "react";
import ViewTable from "../../../utils/ViewTable";
import Button from "@material-ui/core/Button";
import InfoOverlay from "../../../utils/InfoOverlay";
import FetchAllCriteriaListsFromMandant from "../../../utils/FetchAllCriteriaListsFromMandant";
import FetchLinkedCriteriasOfListFromMandant from "../../../utils/FetchLinkedCriteriasOfListFromMandant";

function SelectSourceLists({
    props,
    setSelectedStatisticsQueryLists,
    setDisplayedCriterialistName,
    displayedCriterialistName,
    displayedCriterialist,
    setDisplayedCriterialist,
    showDialog,
    setShowDialog,
    loadedCriteriaLists,
    setLoadedCriteriaLists,
    sourceSelectedMandant
}) {
    function parseDate(date) {
        return date.replace("T", " ").replace(".000Z", "");
    }
    useEffect(() => {
        (async () => {
            try {
                if (loadedCriteriaLists.length === 0) {
                    var criteriaLists = await FetchAllCriteriaListsFromMandant(sourceSelectedMandant);
                    criteriaLists.forEach((criteriaList) => {
                        if (criteriaList["LASTSUCCESSFULSYNC"] !== undefined) {
                            criteriaList["LASTSUCCESSFULSYNC"] = parseDate(
                                criteriaList["LASTSUCCESSFULSYNC"]
                            );
                        }
                    });
                    setLoadedCriteriaLists(criteriaLists);
                }
            } catch (error) {
                console.log(error);
            } finally {
            }
        })();
    }, [loadedCriteriaLists.length, setLoadedCriteriaLists, sourceSelectedMandant]);
    const optionsCriterias = {
        selectableRows: "multiple",
        search: true,
        textLabels: {
            body: {
                noMatch: "No matching records were found",
                toolTip: "Sort by",
            },
            pagination: {
                rowsPerPage: "Entries per page",
                displayRows: "of",
            },
            selectedRows: {
                text: "marked",
            },
        },

        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
            if (allRowsSelected.length > 0) {
                setSelectedStatisticsQueryLists(
                    allRowsSelected.map((i) => loadedCriteriaLists[i.dataIndex])
                );
            } else {
                setSelectedStatisticsQueryLists(null);
            }
        },
    };
    const displayCriterias = useCallback(
        async (queryList) => {
            setDisplayedCriterialistName(queryList.NAME);
            setDisplayedCriterialist(await FetchLinkedCriteriasOfListFromMandant(sourceSelectedMandant, queryList.GGUID));
            setShowDialog(true);
            props.history.replace({
                search: `?showCriterias=${"0x" + queryList.GGUID}`,
            });
        },
        [props.history, setDisplayedCriterialist, setDisplayedCriterialistName, setShowDialog]
    );
    const columnsCriteriaList = [
        {
            name: "NAME",
            label: "Name",
            options: {
                customBodyRender: (name, { rowIndex }) => {
                    return (
                        <Button
                            color="primary"
                            onClick={() => displayCriterias(loadedCriteriaLists[rowIndex])}
                        >
                            {name}
                        </Button>
                    );
                },
            },
        },
        {
            name: "PERIODICITY",
            label: "Periodicity",
        },
        {
            name: "LASTSUCCESSFULSYNC",
            label: "Last successful sync",
        },
    ];
    return (
        <>
            <div>
                <h3>Step 2: select 1 or more statistics lists to export</h3>
                <ViewTable
                    title={"Statistics lists"}
                    data={loadedCriteriaLists}
                    columns={columnsCriteriaList}
                    options={optionsCriterias}
                />
                <InfoOverlay
                    showDialog={showDialog}
                    toggleDialog={() => {
                        setShowDialog(false);
                        props.history.replace({
                            search: "",
                        });
                    }}
                    name={displayedCriterialistName}
                    data={displayedCriterialist}
                />
            </div>
        </>
    );
}
export default SelectSourceLists;
