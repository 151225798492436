import React from "react";
import ViewTable from "../utils/ViewTable";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
const defaultAPIUrl = window.config.REACT_APP_SERVER_URL_REST;

function translateType(type) {
    switch (type) {
        case "Information":
            return "information";
        case "Netzwerk":
            return "network";
        case "Schnellerfassung":
            return "quickdocumentation";
        case "Vorgang":
            return "vorgang";
        default:
            return type;    

    }
}

const tableTheme = () =>
    createTheme({
        overrides: {
            MuiCheckbox: {
                colorPrimary: {
                    "&$checked": {
                        color: "#DC001A",
                    },
                },
            },
        },
    });
export default function TimeTrackingTableTable({
    loadedTimeTrackings,
    setSelectedDraftIdx,
    setShowDiscardPopup,
    loading,
}) {
    const filteredTimeTrackings = loadedTimeTrackings;
    filteredTimeTrackings.forEach((timeTracking) => {
        if (timeTracking.vacation || timeTracking.maternityLeave || timeTracking.holidays) {
            timeTracking.startTime = "~";
            timeTracking.endTime = "~";
        }
    });
    const columns = [
        {
            name: "date",
            label: "Datum",
            sortable: true
        },
        {
            name: "kindOfDoc",
            label: "Art der Dokumentation",
            sortable: true,
        },
        {
            name: "keyword",
            label: "Betreff",
            sortable: true,
        },
        {
            name: "startTime",
            label: "Beginn",
            sortable: true,
        },
        {
            name: "endTime",
            label: "Ende",
            sortable: true,
        },
        {
            name: "duration",
            label: "Dauer",
        },
        {
            name: "isConflict",
            label: "Konflikt",
            options: {
                display: "excluded",
              },
          },
    ];
    const options = {
        search: false,
        setRowProps: (row) => ({
            style: {
              backgroundColor: row[columns.findIndex((col) => col.name === "isConflict")] ? "#ec942c" : "",
              cursor: "pointer",
            } ,
            title: row[columns.findIndex((col) => col.name === "isConflict")] ? "Orange Markierungen zeigen Konflikte an. Bitte passen Sie Ihre Dokumentation der orangenen Einträge an, um eine korrekte Berechnung zu erhalten." : "",
            className: row[columns.findIndex((col) => col.name === "isConflict")] ? "custom-tooltip-row" : "",
          }),
        customSort: (data, colIndex, order) => {
            const colName = columns[colIndex].name;
            return data.sort((a, b) => {
                const aValue = a.data[colIndex];
                const bValue = b.data[colIndex];
                switch (colName) {
                    case "date":
                        const aDateParts = aValue.split('.');
                        const bDateParts = bValue.split('.');
                        const aDate = new Date(
                            `20${aDateParts[2]}`,
                            aDateParts[1] - 1,
                            aDateParts[0]
                        );
                        const bDate = new Date(
                            `20${bDateParts[2]}`,
                            bDateParts[1] - 1,
                            bDateParts[0]
                        );
                        return order === "asc" ? aDate - bDate : bDate - aDate;
                    case "kindOfDoc":
                    case "keyword":
                        if (!aValue && !bValue) return 0;
                        if (!aValue) return 1;
                        if (!bValue) return -1;
                        return order === "asc"
                            ? aValue.localeCompare(bValue)
                            : bValue.localeCompare(aValue);
                    case "start":
                    case "end":
                        const aTime = aValue.split(":");
                        const bTime = bValue.split(":");
                        const aHours = parseInt(aTime[0], 10);
                        const bHours = parseInt(bTime[0], 10);
                        const aMinutes = parseInt(aTime[1], 10);
                        const bMinutes = parseInt(bTime[1], 10);
    
                        if (aHours === bHours) {
                            return order === "asc"
                                ? aMinutes - bMinutes
                                : bMinutes - aMinutes;
                        } else {
                            return order === "asc" ? aHours - bHours : bHours - aHours;
                        }
                    case "duration":
                        const aDuration = parseInt(aValue, 10);
                        const bDuration = parseInt(bValue, 10);
                        return order === "asc" ? aDuration - bDuration : bDuration - aDuration;
                    default:
                        return order === "asc" ? aValue - bValue : bValue - aValue;
                }
            });
        },
        onRowClick: (_, { dataIndex }) => {
            let DOtype = loadedTimeTrackings[dataIndex].kindOfDoc;
            let DOgguid = loadedTimeTrackings[dataIndex].gguidDO !== null ? loadedTimeTrackings[dataIndex].gguidDO : loadedTimeTrackings[dataIndex].gguidTT;
            let DOtypeMapped = translateType(DOtype);
            let automatic = DOtype !== DOtypeMapped;

            if (automatic) {                
                window.open(
                    defaultAPIUrl + "CareCM/#!type/" + DOtypeMapped + "/0x" + DOgguid
                );
            }
            else {
                window.open(
                    defaultAPIUrl + "CareCM/#!type/timetracking/0x" + DOgguid
                );
            } 
        },
        textLabels: {
            body: {
                noMatch: "Leider wurden keine passenden Datensätze gefunden",
                toolTip: "Sortieren",
            },
            pagination: {
                rowsPerPage: "Einträge pro Seite",
                displayRows: "von",
            },
            selectedRows: {
                text: "Zeile(n) markiert",
                delete: "Alle markierten Kriterien löschen",
                deleteAria: "Alle markierten Kriterien löschen",
            },
        },
        onRowsDelete: ({ data }) => {
            if (loading === false) {
                setSelectedDraftIdx(data.map(({ dataIndex }) => dataIndex));
                setShowDiscardPopup(true);
                return true;
            }
            return false;
        },
    };
    return (
        <MuiThemeProvider theme={tableTheme}>
            <ViewTable
                title={"Vorhandene Zeiterfassung für den ausgewählten Zeitraum"}
                data={filteredTimeTrackings}
                columns={columns}
                options={options}
            />
        </MuiThemeProvider>
    );
}
