import React from "react";
import YesNoOverLay from "../../utils/YesNoOverlay";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import ActionsBar from "../../utils/ActionsBar";
import moment from "moment-timezone";
import "moment/locale/de";
import { SecondaryButton } from "../../utils/CFButtons";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { Link } from "react-router-dom";
import { Button as CFButton } from "../../utils/CFButtons";
import DeleteIcon from "@material-ui/icons/Delete";
moment.locale("de");

export default function DeleteCriteriaListActionsSection({
    showDeletePopup,
    setShowDeletePopup,
    deleteCriteriaList,
    showSnackbar,
    setShowSnackbar,
    snackbarType,
    snackbarMessage,
    validateAndSend,
    props,
    goBack,
}) {
    return (
        <>
            <ActionsBar>
                <div>
                    <SecondaryButton component={Link} to={goBack(props.location.pathname)}>
                        <ArrowBackRoundedIcon />
                        Zurück
                    </SecondaryButton>
                </div>
                <div>
                    <CFButton variant="contained" onClick={() => validateAndSend(true)}>
                        <DeleteIcon />
                        Remove
                    </CFButton>
                </div>
            </ActionsBar>
            <YesNoOverLay
                showDialog={showDeletePopup}
                toggleDialog={() => setShowDeletePopup(false)}
                title={"Do you want to remove the selected list from all the mandants?"}
                cancelText="Verwerfen"
                okText="Löschen"
                onConfirm={deleteCriteriaList}
            />
            <Snackbar
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={() => setShowSnackbar(false)}
            >
                <CFSnackbarContent
                    onClose={() => setShowSnackbar(false)}
                    variant={snackbarType}
                    message={snackbarMessage}
                />
            </Snackbar>
        </>
    );
}
