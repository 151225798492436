import React, { useEffect } from "react";
import ViewTable from "../../../utils/ViewTable";
import FetchAllMandant from "../../../utils/FetchAllMandant";

function SelectSourceMandant({
  loadedMandants,
  setLoadedMandants,
  setLoadedMandantsWithoutSource,
  setSourceSelectedMandant,
  props,
}) {
  useEffect(() => {
    (async () => {
      try {
        if (loadedMandants.length === 0) {
          setLoadedMandants(await FetchAllMandant());
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    })();
  }, [loadedMandants.length, setLoadedMandants, props.location]);
  const optionsMandant = {
    selectableRows: "single",
    search: true,
    textLabels: {
      body: {
        noMatch: "Leider wurden keine passenden Datensätze gefunden",
        toolTip: "Sortieren",
      },
      pagination: {
        rowsPerPage: "Einträge pro Seite",
        displayRows: "von",
      },
      selectedRows: {
        text: "Mandants markiert",
      },
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      if (allRowsSelected.length > 0) {
        setSourceSelectedMandant(loadedMandants[allRowsSelected[0].dataIndex].clientName);
      } else {
        setSourceSelectedMandant(null);
      }
    },
  };
  const columnsMandant = [
    {
      name: "clientName",
      selector: "clientName",
      sortable: true,
    },
  ];
  return (
    <>
      <h3>Step 1: select a source mandant</h3>
      <ViewTable
        title={"Mandants"}
        data={loadedMandants}
        columns={columnsMandant}
        options={optionsMandant}
      />
    </>
  );
}
export default SelectSourceMandant;
