import React, { useState } from "react";
import ExportStatisticsActionSection from "./ExportStatisticsActionSection";
import SelectSourceMandant from "./steps/SelectSourceMandant";
import SelectTargetMandant from "../../utils/SelectTargetMandant";
import SelectSourceLists from "./steps/SelectSourceLists";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingSpinner from "../../utils/LoadingSpinner";

function ExportStatistics(props) {
    const [selectedStatisticsQueryLists, setSelectedStatisticsQueryLists] = useState("");
    const [displayedCriterialist, setDisplayedCriterialist] = useState();
    const [displayedCriterialistName, setDisplayedCriterialistName] = useState();
    const [loadedCriteriaLists, setLoadedCriteriaLists] = useState([]);
    const [loadedMandants, setLoadedMandants] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [sourceSelectedMandant, setSourceSelectedMandant] = useState(null);
    const [targetSelectedMandants, setTargetSelectedMandants] = useState(null);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState("");
    const [loadedMandantsWithoutSource, setLoadedMandantsWithoutSource] = useState([]);

    function displaySnackbar(snackbarType, snackbarMessage) {
        setSnackbarType(snackbarType);
        setSnackbarMessage(snackbarMessage);
        setShowSnackbar(true);
    }
    return (
        <>
            {loading && <LoadingSpinner />}
            <LinearProgress variant="determinate" value={(step * 100) / 3} />
            {step === 1 ? (
                <SelectSourceMandant
                    loadedMandants={loadedMandants}
                    setLoadedMandants={setLoadedMandants}
                    setLoadedMandantsWithoutSource={setLoadedMandantsWithoutSource}
                    sourceSelectedMandant={sourceSelectedMandant}
                    setSourceSelectedMandant={setSourceSelectedMandant}
                    props={props}
                />
            ) : (
                " "
            )}
            {step === 2 ? (
                <SelectSourceLists
                    props={props}
                    setSelectedStatisticsQueryLists={setSelectedStatisticsQueryLists}
                    setDisplayedCriterialistName={setDisplayedCriterialistName}
                    displayedCriterialistName={displayedCriterialistName}
                    displayedCriterialist={displayedCriterialist}
                    setDisplayedCriterialist={setDisplayedCriterialist}
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                    loadedCriteriaLists={loadedCriteriaLists}
                    setLoadedCriteriaLists={setLoadedCriteriaLists}
                    sourceSelectedMandant={sourceSelectedMandant}
                />
            ) : (
                " "
            )}
            {step === 3 ? (
                <SelectTargetMandant
                    loadedMandantsWithoutSource={loadedMandantsWithoutSource}
                    setLoadedMandantsWithoutSource={setLoadedMandantsWithoutSource}
                    sourceSelectedMandant={sourceSelectedMandant}
                    targetSelectedMandants={targetSelectedMandants}
                    setTargetSelectedMandants={setTargetSelectedMandants}
                    multiselection="multi"
                />
            ) : (
                " "
            )}
            <ExportStatisticsActionSection
                showConfirmPopup={showConfirmPopup}
                setShowConfirmPopup={setShowConfirmPopup}
                selectedStatisticsQueryLists={selectedStatisticsQueryLists}
                setSelectedStatisticsQueryLists={setSelectedStatisticsQueryLists}
                setLoadedCriteriaLists={setLoadedCriteriaLists}
                loadedMandants={loadedMandants}
                setLoadedMandants={setLoadedMandants}
                sourceSelectedMandant={sourceSelectedMandant}
                setSourceSelectedMandant={setSourceSelectedMandant}
                targetSelectedMandants={targetSelectedMandants}
                setTargetSelectedMandants={setTargetSelectedMandants}
                snackbarType={snackbarType}
                setSnackbarType={setSnackbarType}
                snackbarMessage={snackbarMessage}
                setSnackbarMessage={setSnackbarMessage}
                setShowSnackbar={setShowSnackbar}
                showSnackbar={showSnackbar}
                displaySnackbar={displaySnackbar}
                setLoading={setLoading}
                loading={loading}
                step={step}
                setStep={setStep}
                props={props}
            />
        </>
    );
}
export default ExportStatistics;
