import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const CFButton = withStyles(theme => ({
  root: {
    fontWeight: 600,
    boxShadow: 'none',
    color: '#fff',
    textTransform: 'none',
    padding: '14px 30px',
    backgroundColor: theme.palette.primary.main,
    letterSpacing: '.5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '& svg': {
      marginRight: 10,
    },
  },
}))(Button)

const SecondaryButton = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: '#269fac21',
    '&:hover': {
      backgroundColor: '#269fac42',
    },
  },
}))(CFButton)
const CaseformButton = withStyles(theme => ({
  root: {
    backgroundColor: ' #74C5DA',
    color: '#fff',
    minHeight: 55,
    '&:hover': {
      backgroundColor: '#74C5DA',
    },
  },
}))(CFButton)

const SecondaryCaseformButton = withStyles(theme => ({
  root: {
    color: ' #74C5DA',
    backgroundColor: '#269fac21',
    '&:hover': {
      backgroundColor: '#269fac42',
    },
  },
}))(CFButton)
const DangerButton = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    backgroundColor: '#f5005717',
    '&:hover': {
      backgroundColor: '#f5005729',
    },
  },
}))(SecondaryButton)

export { CFButton as Button, SecondaryButton, DangerButton, CaseformButton, SecondaryCaseformButton }
