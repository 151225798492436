import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
function LoadingSpinner({ noText, size = 40, text, ...props }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      marginTop={14}
      marginBottom={14}
      {...props}
    >
      <Box display="flex" alignItems="center">
        <Backdrop open={true}>
          <CircularProgress
            color="primary"
            style={{ height: size, width: size }}
          />
          <span
            style={{
              marginLeft: 14,
              fontSize: 16,
              color: "#4A5568",
            }}
          ></span>
        </Backdrop>
      </Box>
    </Box>
  );
}

export default LoadingSpinner;
