import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ViewTable from '../utils/ViewTable'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Column } from 'simple-flexbox'

const styles = theme => ({
  valueTypography: {
    fontWeight: 700,
    fontSize: 16,
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
      margin: theme.spacing(1),
      fontSize: 16,
    },
  },
  textField: {
    width: 125,
    marginBottom: theme.spacing(0.25),
    marginTop: theme.spacing(1),
  },
})
const tableTheme = () =>
  createTheme({
    overrides: {
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: '#DC001A',
          },
        },
      },
    },
  })
const columns = [
  {
    name: 'CHRISTIANNAME',
    label: 'Namen',
  },
  {
    name: 'NAME',
    label: 'Nachnamen',
/*    options: {
      customBodyRender: type => translate(type),
    },*/
  },
]
const options = {
  search: false,
  textLabels: {
    body: {
      noMatch: 'Leider wurden keine passenden Datensätze gefunden',
      toolTip: 'Sortieren',
    },
    pagination: {
      rowsPerPage: 'Einträge pro Seite',
      displayRows: 'von',
    },
    selectedRows: {
      text: 'Zeile(n) markiert',
      delete: 'Alle markierten Kriterien löschen',
      deleteAria: 'Alle markierten Kriterien löschen',
    },
  },
}
const InfoOverlay2 = ({ name, data, showDialog, toggleDialog }) => (
  <Dialog open={showDialog} onBackdropClick={toggleDialog}>
    <DialogTitle>nicht Migrierte Addresses nach {name}</DialogTitle>
    <DialogContent>
      <Column>
        <MuiThemeProvider theme={tableTheme}>
          <ViewTable
            title={'Addresses'}
            data={data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Column>
    </DialogContent>
  </Dialog>
)

export default withStyles(styles, { withTheme: false })(InfoOverlay2)
