import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import DeleteIcon from "@material-ui/icons/Delete";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Assessment from "@material-ui/icons/Assessment";
import moment from "moment-timezone";
import "moment/locale/de";
import React, { useEffect, useState } from "react";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { Button as CFButton } from "../utils/CFButtons";
import { getDataFromSmartDesign } from "../utils/api-client";
import {ArrowUpward} from "@material-ui/icons";
moment.locale("de");

export default function AdminMenu(props) {
    const [mandant, setMandant] = useState("");
    const [wohnberatung, setWohnberatung] = useState("");
    const useStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                paddingLeft: theme.spacing(0),
            },
        },
        buttonCriterias: {
            marginRight: 75,
            width: 275,
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
                marginRight: 0,
            },
        },
        buttonAdminApp: {
            width: theme.spacing(2),
        },
        formControlRow: {
            margin: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        formControlFull: {
            margin: theme.spacing(2),
            width: "100%",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        formControl: {
            margin: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
    }));

    useEffect(() => {
        (async () => {
            try {
                const response = await getDataFromSmartDesign("/admin/licences/get");
                setMandant(response.mandant);
                setWohnberatung(response.wohnberatung);
            } catch (error) {
                alert("something went wrong, please contact to the CareCM 2.0 team");
                return;
            }
        })();
    }, [mandant, setMandant, wohnberatung, setWohnberatung]);

    const classes = useStyles();
    return (
        mandant !== "" && (
            <>
                <FormControl
                    component="fieldset"
                    title="Criterias"
                    className={classes.formControlRow}
                >
                    <FormGroup row title="Criterias">
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() => props.history.push({pathname: "/admin/criterias/create", mandant, wohnberatung})}
                        >
                            <AddIcon />
                            Neues Kriterium erstellen
                        </CFButton>
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() => props.history.push("/admin/criterialists/edit")}
                        >
                            <EditRoundedIcon />
                            Kriterienliste anpassen
                        </CFButton>
                        {mandant === "RLP" && (
                            <CFButton
                                variant="contained"
                                className={classes.buttonCriterias}
                                onClick={() => props.history.push("admin/address/move")}
                            >
                                <SwapHorizIcon />
                                Klient umziehen
                            </CFButton>
                        )}
                    </FormGroup>
                </FormControl>
                <FormControl
                    component="fieldset"
                    title="Criteria lists"
                    className={classes.formControlRow}
                >
                    <FormGroup row title="Criteria lists">
                        {mandant === "RLP" && (
                            <>
                                <CFButton
                                    variant="contained"
                                    className={classes.buttonCriterias}
                                    onClick={() =>
                                        props.history.push("/admin/statisticsquerylist/move")
                                    }
                                >
                                    <AlternateEmailIcon />
                                    Kriterienliste aktualisieren
                                </CFButton>
                                <CFButton
                                    variant="contained"
                                    className={classes.buttonCriterias}
                                    onClick={() =>
                                        props.history.push("/admin/criterias/list/delete")
                                    }
                                >
                                    <DeleteIcon />
                                    Delete list from All Mandants
                                </CFButton>
                            </>
                        )}
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() => props.history.push("/admin/statisticsquerylists/export")}
                        >
                            <SwapHorizIcon />
                            Kriterienlisten in andere Mandanten exportieren
                        </CFButton>
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControlRow}>
                    <FormGroup row>
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() =>
                                props.history.push("/admin/criterias/list/delete/single")
                            }
                        >
                            <DeleteIcon />
                            Vorhandene Kriterienliste löschen
                        </CFButton>
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControlRow}>
                    <FormGroup row>
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() => props.history.push("/admin/statistics/check")}
                        >
                            <Assessment />
                            Check statistics
                        </CFButton>
                    </FormGroup>
                </FormControl>
                
                <FormControl component="fieldset" className={classes.formControlRow}>
                    <FormGroup row>
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() => props.history.push("/admin/import/organization")}
                        >
                            <ArrowUpward />
                            Import Organisations from CSV (Berlin only)
                        </CFButton>
                    </FormGroup>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControlRow}>
                    <FormGroup row>
                        <CFButton
                            variant="contained"
                            className={classes.buttonCriterias}
                            onClick={() => props.history.push("/admin/VerifyProfilesJson/VerifyProfilesJson")}
                        >
                            <ArrowUpward />
                            VerifyProfilesJson
                        </CFButton>
                    </FormGroup>
                </FormControl>
            </>
        )
    );
}
