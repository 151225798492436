import React from "react";
import { Button as CFButton } from "../../utils/CFButtons";
import ActionsBar from "../../utils/ActionsBar";
import { SecondaryButton } from "../../utils/CFButtons";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { navigateBack } from "../../utils/common";
import { Link } from "react-router-dom";
import { Send } from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import { postDataToSmartDesign as client } from "../../utils/api-client";

export default function CopyStatisticsActionSection({
    snackbarType,
    snackbarMessage,
    setShowSnackbar,
    showSnackbar,
    displaySnackbar,
    setLoading,
    loading,
    selectedStatisticsQueryList,
    props,
}) {
    async function sendSyncList() {
        if (selectedStatisticsQueryList != null && selectedStatisticsQueryList !== "") {
            setLoading(true);
            try {
                await client(
                    "/admin/statistics/move?gguid=" + selectedStatisticsQueryList,
                    {}
                ).then((response) => {
                    response.text().then((value) => {
                        var obj = JSON.parse(value);
                        if (obj.notSuccesfulProcessedMandant.length === 0) {
                            displaySnackbar(
                                "success",
                                "The list was synchronized for all mandants"
                            );
                        } else {
                            displaySnackbar(
                                "warning",
                                "The list was synchronized for: " +
                                    obj.succesfulProcessedMandant.length +
                                    " mandants, and the synchronization failed for: " +
                                    obj.notSuccesfulProcessedMandant.length +
                                    " mandants"
                            );
                        }
                    });
                });
            } catch {
                displaySnackbar(
                    "error",
                    "An unexpected error occurred and not all the mandants were synchronized"
                );
            } finally {
                setLoading(false);
            }
        } else {
            displaySnackbar("error", "Bitte eine Kriterienliste auswählen");
            return false;
        }
    }
    return (
        <>
            <ActionsBar>
                <div>
                    <SecondaryButton
                        component={Link}
                        to={navigateBack(navigateBack(props.location.pathname))}
                        disabled={loading}
                    >
                        <ArrowBackRoundedIcon />
                        Zurück
                    </SecondaryButton>
                </div>
                <CFButton onClick={sendSyncList} disabled={loading}>
                    <Send />
                    Send
                </CFButton>
            </ActionsBar>
            <>
                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={10000}
                    onClose={() => setShowSnackbar(false)}
                >
                    <CFSnackbarContent
                        onClose={() => setShowSnackbar(false)}
                        variant={snackbarType}
                        message={snackbarMessage}
                    />
                </Snackbar>
            </>
        </>
    );
}
