import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    actionsBar: ({ style, justify }) => ({
        display: "flex",
        justifyContent: !justify ? "space-between" : justify,
        marginTop: theme.spacing(6),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        ...style,
        "& div > button:not(:last-child), & div > a:not(:last-child)": {
            marginRight: theme.spacing(3),
        },
    }),
}));

function ActionsBar({ children, style, justify }) {
    const classes = useStyles({ style, justify });
    return <div className={classes.actionsBar}>{children}</div>;
}

export default ActionsBar;
