import { getDataFromSmartDesignResponse, postDataToSmartDesignResponse, getDataFromSmartDesign } from "../../utils/api-client";
import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Autocomplete } from "@material-ui/lab";
import { Chip, TextField, Tooltip, Zoom, Typography, CircularProgress } from "@material-ui/core";
export default function ICDCodeWidget({
    name,
    label,
    state,
    error,
    errorClassName,
    ...props
}) {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [addressGGUID, setAddressGGUID] = useState("");
    const [isMain] = useState(name === 'mainDiagnoses');
    const [caseformAvailable, setCaseformAvailable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchInitialValues = async () => {
            var url_string = window.location.href
            var url = new URL(url_string)
            setAddressGGUID(url.searchParams.get('addressgguid'))
            const endpointUrl = '/address/icd/values/';
            const queryParams = [
                `addressGGUID=${url.searchParams.get('addressgguid')}`,
                `isMain=${isMain}`
            ];
            const finalUrl = endpointUrl + '?' + queryParams.join('&');
            const response = await getDataFromSmartDesignResponse(finalUrl);
            const responseJson = await response.json();
            if (response.status === 200) {
                var diagnoses = isMain ? responseJson.mainDiagnoses : responseJson.secondaryDiagnoses;
                if (diagnoses) {
                    fillInitialValues(diagnoses);
                }
            } else {
                setIsLoading(false);
                setCaseformAvailable(false);
            }
        }
        fetchInitialValues();

    }, [name]);

function fillInitialValues (diagnoses){
    const newItems = diagnoses.reduce((acc, element) => {
        var value = element.value;
        var description = element.description;
        if (value && description) {
            const newItem = { value: value, description: description };
            acc.push(newItem);
        }
        return acc;
    }, []);
    setValue(newItems);
    setCaseformAvailable(true);
    setIsLoading(false);
}

    const debouncedFetchIcdCodes = useDebouncedCallback(() => {
        if (inputValue.trim() === "") {
            return;
        }

        (async () => {
            try {
                const endpointUrl = "/address/icd/get?input=" + inputValue;
                const { results } = await getDataFromSmartDesign(endpointUrl);

                if (results) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [...value];
                    }

                    newOptions = [
                        ...newOptions,
                        ...results.map((r) => ({
                            value: r.code,
                            description: r.description,
                        })),
                    ];

                    setOptions(newOptions);
                }
            } catch (error) {
                setCaseformAvailable(false);
            }
        })();
    }, 1000);
    useEffect(() => {
        if (inputValue === "") {
            setOptions(inputValue ? [...inputValue] : []);
            return undefined;
        }

        debouncedFetchIcdCodes();
    }, [inputValue, debouncedFetchIcdCodes]);

    const updateICD = async (item, isSave) => {
        const endpointUrl = 'address/icd/';
        const queryParams = [
            `addressGGUID=${addressGGUID}`,
            `isSave=${isSave}`,
            `icdCode=${item.value}`,
            `isMain=${isMain}`
        ];
        const finalUrl = endpointUrl + '?' + queryParams.join('&');
        console.log("addd " + finalUrl);
        const response = await postDataToSmartDesignResponse(finalUrl);
        return response.status;
    }

    return (
        <> 
            {isLoading && (
                <div>
                <CircularProgress
                    style={{ margin: 'auto', marginTop: 70, display: 'flex' }}
                />
                </div>
            )}
            {!caseformAvailable && !isLoading &&(
                <Typography component="div" variant="h5">
                    {label} derzeit nicht lieferbar. Versuche es später
                </Typography>
            )}
            {caseformAvailable && !isLoading &&(
                <Autocomplete
                    value={value}
                    multiple
                    options={options}
                    filterOptions={(opts) => opts.filter((opt) => !!opt.value)}
                    getOptionLabel={(option) =>
                        Array.isArray(option)
                            ? option
                            : option.description !== ""
                                ? `${option.value} - ${option.description}`
                                : `${option.value}`
                    }
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            name={name}
                            label={label}
                            placeholder={"Bitte was eingeben"}
                            inputProps={{ ...params.inputProps }}
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Tooltip
                                key={index + option.value}
                                title={`${option.value} - ${option.description}`}
                                placement="top"
                                TransitionComponent={Zoom}
                            >
                                <Chip
                                    color="primary"
                                    label={
                                        option.value +
                                        (option.description ? ` - ${option.description}` : "")
                                    }
                                    {...getTagProps({ index })}
                                />
                            </Tooltip>
                        ))
                    }
                    ChipProps={{
                        color: "primary",
                    }}
                    filterSelectedOptions
                    onChange={(_, newValue) => {
                        const addedItems = newValue.filter(item => !value.includes(item));
                        const addedItemsPromises = addedItems.map(async addedItem => {
                            const status = await updateICD(addedItem, true);
                            if (status === 200) {
                                setValue(newValue);
                            }
                        });

                        const removedItems = value.filter(item => !newValue.includes(item));
                        const removedItemsPromises = removedItems.map(async removedItem => {
                            const status = await updateICD(removedItem, false);
                            if (status === 200) {
                                setValue(newValue);
                            }
                        });

                        console.log(name + " has " + value.length);

                        Promise.all([...addedItemsPromises, ...removedItemsPromises])
                            .catch(error => console.error('Error in onChange:', error));
                    
                    }}
                    onClose={() => console.log(name)}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue.replace(/[/\\?#><]|^\./g, ""));
                    }}
                    noOptionsText={"nichts angegeben"}
                />
            )}
        </>
    );
}
