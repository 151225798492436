import React from "react";
import clsx from "clsx";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const variantIcon = {
  success: CheckCircleRoundedIcon,
  warning: WarningRoundedIcon,
  error: ErrorRoundedIcon,
  info: InfoRoundedIcon,
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: ' #74C5DA',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function CFSnackbarContent({ className, message, onClose, variant, ...props }) {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  return (
    <div>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseRoundedIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...props}
      />
    </div>
  );
}

export default CFSnackbarContent;
