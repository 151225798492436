import React, { useEffect, useState } from "react";
import CFFieldWithValidation from "../../utils/CFFieldWithValidation";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Button as CFButton, SecondaryButton } from "../../utils/CFButtons";
import ActionsBar from "../../utils/ActionsBar";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { useHistory } from "react-router-dom";
import { postDataToSmartDesign } from "../../utils/api-client";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../../utils/CFSnackbarContent";
import { translations } from "../../../resources/translations";
import { getDataFromSmartDesign } from "../../utils/api-client";

const PERIODICITY_YEARLY_VALUE = "A46C6239CEF14680948335FCAAA39FE3";
const PERIODICITY_MONTHLY_VALUE = "A46C6239CEF14680948335FCAAA39FE1";
function CreateNewStatisticsQueryList(props) {
    const history = useHistory();
    const criterias = props.location.state !== undefined ? props.location.state.criterias : null;
    const isUserRequest =
        props.location.state !== undefined ? props.location.state.isUserRequest : false;
    const selectedStatisticsQueryList = 
        props.location.state !== undefined ? props.location.state.selectedStatisticsQueryList : null;
    const [savedAsWord, isSavedAsWord] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarType, setSnackbarType] = useState("");
    const [formikInitialState] = useState({
        name: "",
        periodicity: "",
        isUserRequest: isUserRequest,
    });
    const [mandant, setMandant] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const response = await getDataFromSmartDesign("/admin/licences/get");
                setMandant(response.mandant);
            } catch (error) {
                alert("something went wrong, please contact to the CareCM 2.0 team");
                return;
            }
        })();
    }, [mandant, setMandant]);

    function displaySnackbar(snackbarType, snackbarMessage) {
        setSnackbarType(snackbarType);
        setSnackbarMessage(snackbarMessage);
        setShowSnackbar(true);
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                paddingLeft: theme.spacing(0),
            },
        },
        textField: {
            marginRight: 75,
            width: 275,
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
                marginRight: 0,
            },
        },
        formControlRow: {
            margin: theme.spacing(2),
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        formControlFull: {
            margin: theme.spacing(2),
            width: "100%",
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
        wideControl: {
            width: 350,
            [theme.breakpoints.down(400 + theme.spacing(3) * 2)]: {
                width: "82.5vw",
            },
        },
        formControl: {
            margin: theme.spacing(2),
            [theme.breakpoints.down(450 + theme.spacing(3) * 2)]: {
                margin: theme.spacing(1),
            },
        },
    }));

    const statisticsSchema = Yup.object().shape({
        name: Yup.string().required("Dies ist ein Pflichtfeld"),
        periodicity: Yup.mixed().when("isUserRequest", {
            is: false,
            then: Yup.string().required("Dies ist ein Pflichtfeld"),
            otherwise: Yup.string(),
        }),
    });
    function timeout(delay: number) {
        return new Promise((res) => setTimeout(res, delay));
    }
    const classes = useStyles();
    async function saveAndLink(values) {
        if (mandant !== "RLP"){
            values.periodicity = PERIODICITY_MONTHLY_VALUE;
        }
        if (values.name !== "" && (values.periodicity !== "" || isUserRequest)) {
            try {
                const periodicity_value = values.periodicity;
                await postDataToSmartDesign("/admin/criterias/list/create", {
                    name: values.name,
                    periodicity: periodicity_value,
                    savedAsWord:
                        periodicity_value === PERIODICITY_YEARLY_VALUE ? savedAsWord : false,
                    criterias:
                        criterias !== null ? criterias.map((criteria) => {
                            return criteria.GGUID;
                        })
                            : [],
                    selectedStatisticsQueryList:
                        selectedStatisticsQueryList !== null ? selectedStatisticsQueryList : "",
                });
                displaySnackbar(
                    "success",
                    "Die ausgewählte Kriterienliste würde erfolgreich gespeichert"
                );
                await timeout(3000);

                history.push(isUserRequest ? "/statistics" : "/admin/criterias/create");
            } catch (error) {
                displaySnackbar(
                    "error",
                    "Die ausgewählte Kriterienliste konnte nicht gespeichert werden"
                );
            } finally {
            }
        }
    }
    return (
        <Formik
            validationSchema={statisticsSchema}
            initialValues={formikInitialState}
            enableReinitialize
        >
            {({ values, handleSubmit, isValid }) => (
                <>
                    <FormControl component="fieldset" className={classes.formControlRow}>
                        <CFFieldWithValidation
                            as={TextField}
                            name="name"
                            label={"Name der Kriterienliste"}
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            errorClassName={classes.errorMargin}
                        />
                        {!isUserRequest && mandant === "RLP" && (
                            <CFFieldWithValidation
                                as={TextField}
                                select
                                name="periodicity"
                                label="Regelmäßigkeit"
                                className={classes.textField}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                    IconComponent: KeyboardArrowDownRoundedIcon,
                                }}
                                margin="normal"
                                variant="outlined"
                                errorClassName={classes.errorMargin}
                            >
                                {translations
                                    .filter((element) => element.group === "PERIODICITY")
                                    .map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                            </CFFieldWithValidation>
                        )}
                        {!isUserRequest && values.periodicity === PERIODICITY_YEARLY_VALUE && (
                            <FormControlLabel
                                className={classes.wideControl}
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={(_, checked) => {
                                            isSavedAsWord(checked);
                                        }}
                                    />
                                }
                                label={"Das Ergebnis als Word Dokument speichern"}
                            />
                        )}
                    </FormControl>
                    <ActionsBar>
                        <div>
                            <SecondaryButton
                                className={classes.actionButtonDanger}
                                onClick={() =>
                                    history.push(
                                        isUserRequest ? "/statistics" : "/admin/criterias/create"
                                    )
                                }
                            >
                                <ArrowBackRoundedIcon />
                                Abbrechen
                            </SecondaryButton>
                        </div>
                        <div>
                            <CFButton
                                variant="contained"
                                onClick={() => {
                                    handleSubmit();
                                    saveAndLink(values);
                                }}
                            >
                                <SendRoundedIcon />
                                Speichern und mit ausgewählten Kriterien verbinden
                            </CFButton>
                        </div>
                    </ActionsBar>
                    <Snackbar
                        open={showSnackbar}
                        autoHideDuration={10000}
                        onClose={() => setShowSnackbar(false)}
                    >
                        <CFSnackbarContent
                            onClose={() => setShowSnackbar(false)}
                            variant={snackbarType}
                            message={snackbarMessage}
                        />
                    </Snackbar>
                </>
            )}
        </Formik>
    );
}
export default CreateNewStatisticsQueryList;
