import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import YesNoOverLay from "../utils/YesNoOverlay";
import Snackbar from "@material-ui/core/Snackbar";
import CFSnackbarContent from "../utils/CFSnackbarContent";
import { Button as CFButton, DangerButton } from "../utils/CFButtons";
import ActionsBar from "../utils/ActionsBar";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment-timezone";
import { postDataToSmartDesign } from "../utils/api-client";
import "moment/locale/de";
moment.locale("de");
const styles = (theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  spacer: {
    height: theme.spacing(8),
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tooltipPositionFix: {
    display: "inline-block",
    margin: "0 24px 0 0 ",
  },
});
export default function StatisticActionsSection({
  showDiscardPopup,
  setShowDiscardPopup,
  discardCriterias,
  showSnackbar,
  setShowSnackbar,
  snackbarType,
  snackbarMessage,
  props,
  handleSubmit,
  values,
  users,
  statisticsQueryListName,
  loadedCriterias,
  setSnackbarType,
  setSnackbarMessage,
  statisticsQueryList,
  setLoading,
  loading,
  mandant,
}) {
  function displaySnackbar(snackbarType, snackbarMessage) {
    setSnackbarType(snackbarType);
    setSnackbarMessage(snackbarMessage);
    setShowSnackbar(true);
  }
  async function printStatistics() {
    handleSubmit();
    var selectedUser = users.find(
      ({ DISPLAYNAME }) => DISPLAYNAME === values.user
    );
    if (loadedCriterias.length === 0) {
      displaySnackbar("error", "Bitte mindestens ein Kriterium auswählen");
      return;
    }
    var startDate = moment(values.startDate);
    var endDate = moment(values.endDate);
    if (selectedUser !== undefined  && startDate.isValid() && endDate.isValid() && startDate.isBefore(endDate)) {
      setLoading(true);
      var formattedStartDate = startDate.format("DD.MM.YYYY");
      var formattedEndDate = endDate.format("DD.MM.YYYY");
      try {
        const result = await postDataToSmartDesign("print/statistics", {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          userGGUID: selectedUser.GGUID,
          userDisplayName: selectedUser.DISPLAYNAME,
          statisticsListName: statisticsQueryListName,
          useIntervalMonths: ((mandant === "BAYERN" || mandant === "BAWUE" || mandant === "NDS" )? localStorage.getItem("showMonthColumns"):"false"),
          userID:
            selectedUser.GID !== undefined
              ? selectedUser.GID
              : selectedUser.OID,
          selectedCriterias: loadedCriterias
            .sort((a, b) => a.SORTINDEX - b.SORTINDEX)
            .map((criteria) => {
              return criteria.GGUID;
            }),
        });
        var filename =
          "Statistik_von_" +
          formattedStartDate +
          "_bis_" +
          formattedEndDate +
          "_fuer_" +
          selectedUser.DISPLAYNAME +
          ".xlsx";
        var blob = result;
        var url = undefined;
        var statisticsXLSXLink = undefined;
        var firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
        if (firefoxAgent) {
          var statisticsXLSX = new File([blob], filename, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          url = window.URL.createObjectURL(statisticsXLSX);
          statisticsXLSXLink = document.createElement("a");
          statisticsXLSXLink.href = url;
          window.open(statisticsXLSXLink);
        } else {
          url = window.URL.createObjectURL(blob);
          statisticsXLSXLink = document.createElement("a");
          document.body.appendChild(statisticsXLSXLink);
          statisticsXLSXLink.href = url;
          statisticsXLSXLink.download = filename;
          statisticsXLSXLink.click();
        }
      } catch (e) {
        alert(e.message);
      } finally {
        setLoading(false);
      }
    }
  }
  async function saveNewList() {
    if (loadedCriterias.length === 0) {
      displaySnackbar("error", "Bitte mindestens ein Kriterium auswählen");
      return;
    }
    props.history.push({
      pathname: "/admin/criterias/list/create",
      state: {
        criterias: loadedCriterias,
        isUserRequest: true,
        selectedStatisticsQueryList: statisticsQueryList,
      },
    });
  }
  return (
    <>
      <ActionsBar>
        <div>
          <DangerButton
            className={styles.actionButtonDanger}
            onClick={() => saveNewList()}
            disabled={loading}
          >
            <SaveIcon />
            Kriterienliste speichern
          </DangerButton>
          <CFButton
            variant="contained"
            onClick={() => props.history.push("statistics/criterias")}
            disabled={loading}
          >
            <FilterListIcon />
            Kriterienliste laden
          </CFButton>
        </div>
        <div>
          <CFButton
            variant="contained"
            onClick={() => printStatistics()}
            disabled={loading}
          >
            <SendRoundedIcon />
            Drucken
          </CFButton>
        </div>
      </ActionsBar>
      <YesNoOverLay
        showDialog={showDiscardPopup}
        toggleDialog={() => setShowDiscardPopup(false)}
        title="Möchten Sie die Kriterien wirklich endgültig löschen?"
        cancelText="Verwerfen"
        okText="Löschen"
        onConfirm={discardCriterias}
      />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={10000}
        onClose={() => setShowSnackbar(false)}
      >
        <CFSnackbarContent
          onClose={() => setShowSnackbar(false)}
          variant={snackbarType}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
}
