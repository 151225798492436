import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { ArrowBackRounded, Clear } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ActionBar from '../utils/ActionsBar'
import { fetchCheckStatistics } from '../utils/api-client'
import { SecondaryButton } from '../utils/CFButtons'
import { navigateBack } from '../utils/common'
import missingCoreProps from './missingCorePropsOps'
import missingPropsOps from './missingPropsOps'

const CheckStatistics = props => {
  const [numberOfRecipients, setNumberOfRecipients] = useState(0)
  const [status, setStatus] = useState(0)
  const [missingProps, setmissingProps] = useState([])
  const [missingCorePropsFiltered, setmissingCoreProps] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await fetchCheckStatistics('/statistics/check').then(res => {
        setLoading(false)
        const missingPropsFromServer = missingPropsOps.filter(item => {
          return res.missingProps.includes(item.key)
        })
        const missingCorePropsFromServer = missingCoreProps.filter(item => {
          return res.missingCoreProps.includes(item.key)
        })
        setNumberOfRecipients(res.numberOfRecipients)
        setStatus(res.status)
        setmissingProps(missingPropsFromServer)
        setmissingCoreProps(missingCorePropsFromServer)
      })
    })()
  }, [])

  return (
    <>
      {loading && (
        <div>
          {/* <Typography variant="h6" component="div">
            Loading &nbsp; &nbsp;
          </Typography> */}
          <CircularProgress
            style={{ margin: 'auto', marginTop: 70, display: 'flex' }}
          ></CircularProgress>
        </div>
      )}
      {status === 200 && !loading && (
        <>
          <div style={{ display: 'flex' }}>
            <Typography
              variant='h5'
              component='div'
              style={{ marginLeft: 40, marginTop: 40 }}
            >
              Statistics Status:
            </Typography>
            <Typography
              variant='h5'
              component='div'
              style={{ marginLeft: 40, marginTop: 40, color: 'rgb(0,128,0)' }}
            >
              Online
            </Typography>
          </div>

          <Typography
            variant='h6'
            component='div'
            style={{ marginLeft: 40, marginTop: 40 }}
          >
            Number of Recipients: {numberOfRecipients}
          </Typography>
        </>
      )}

      {status === 403 && !loading && (
        <>
          <Typography
            variant='h5'
            component='div'
            style={{ marginLeft: 40, marginTop: 40 }}
          >
            Sorry, You Are Not Allowed to Access This Page.
          </Typography>
        </>
      )}
      {status === 503 && missingProps && !loading && (
        <>
          <div style={{ display: 'flex' }}>
            <Typography
              variant='h5'
              component='div'
              style={{ marginLeft: 40, marginTop: 40 }}
            >
              Statistics Status:
            </Typography>
            <Typography
              variant='h5'
              component='div'
              style={{ marginLeft: 40, marginTop: 40, color: 'rgb(255,0,0)' }}
            >
              Offline
            </Typography>
          </div>
          <Typography
            variant='h6'
            component='div'
            style={{ marginLeft: 40, marginTop: 40 }}
          >
            There are some issues with the configuration:
          </Typography>
          <List
            style={{
              marginLeft: 70,
              marginTop: 40,
            }}
          >
            {missingProps.map((option, index) => (
              <>
                <ListItem>
                  <ListItemIcon>
                    <Clear
                      style={{ color: 'rgb(255,0,0)', fontSize: 'large' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography>{option.value}</Typography>}
                  />
                </ListItem>
                {option.key === 'core.properties' && missingCorePropsFiltered && (
                  <>
                    {missingCorePropsFiltered.map((optionCoreProps, index) => (
                      <div style={{ display: 'flex', marginLeft: 50 }}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography>
                                {' '}
                                - {optionCoreProps.value}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </div>
                    ))}
                  </>
                )}
              </>
            ))}
          </List>
        </>
      )}
      <>
        <ActionBar>
          <SecondaryButton
            style={{ marginLeft: 20, marginTop: 10 }}
            component={Link}
            to={navigateBack(navigateBack(props.location.pathname))}
          >
            <ArrowBackRounded />
            Zurück
          </SecondaryButton>
        </ActionBar>
      </>
    </>
  )
}

export default CheckStatistics
